import { FC } from "react";

import hardIcon from "../../assets/images/icons/hard_icon1.png";
import { OfferPriceProps } from "../../types/uikit";
import styles from "./OfferPrice.module.sass";
type Props = {
  offerProps: OfferPriceProps | undefined;
  isBonus?: boolean;
  className?: string;
};
const OfferPrice: FC<Props> = ({ offerProps, isBonus, className }) => {
  const {
    newPriceValue,
    oldPriceValue,
    isDiscountOfferPrice,
    productNameForPrice,
  } = offerProps || {};
  return (
    <div className={`${styles.prices} ${className ? className : ""}`}>
      {isDiscountOfferPrice && (
        <div className={styles.priceWithoutDiscount}>
          <img src={hardIcon} alt="hard icon" className={styles.hardIcon} />
          <span className={"ml5 " + styles.priceSpan}>{oldPriceValue}</span>
        </div>
      )}
      <div className={styles.price + `${!isBonus ? " m0" : ""}`}>
        <img src={hardIcon} alt="hard icon" className={styles.hardIcon} />
        <span className="ml5">
          {oldPriceValue ? newPriceValue : productNameForPrice}
        </span>
      </div>
    </div>
  );
};
export default OfferPrice;
