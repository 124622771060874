import { useRuHost } from "../hooks/useRuHost";

export const ruHosts = ["onestateshop.ru", "dev.onestateshop.ru"];

const isRuHost = useRuHost();

export const appConfig = {
  api_url: process.env.REACT_APP_API_URL,
  api_user_url: process.env.REACT_APP_API_USER_URL,
  api_auth_url: process.env.REACT_APP_API_AUTH_URL,
  api_analytics_url: process.env.REACT_APP_ANALYTICS_URL,
  application_id: window.location.hostname.includes("localhost")
    ? process.env.REACT_APP_APPLICATION_ID_LOCAL
    : isRuHost
    ? process.env.REACT_APP_APPLICATION_ID_RU
    : process.env.REACT_APP_APPLICATION_ID,
  sentry_dsn: process.env.REACT_APP_SENTRY_DSN,
  sentry_hosts: process.env.REACT_APP_SENTRY_HOSTS?.split(","),
};
// api_url: "https://api.onestate-rp.com/payments",
// api_user_url: "https://api.onestate-rp.com/client",
// api_auth_url: "https://api.onestate-rp.com/auth0",
// api_analytics_url: "https://api.onestate-rp.com/wa",
