export const en = {
  $WEB_AUTHMENU_DISCRD_BTN: "Log in with Discord",
  $WEB_AUTHMENU_GOOGLE_BTN: "Log in with Google Play",
  $WEB_AUTHMENU_VK_BTN: "Log in with VKontakte",
  $WEB_AUTHMENU_FB_BTN: "Log in with Facebook",
  $WEB_AUTHMENU_APPLE_BTN: "Log in with Apple",
  $WEB_AUTHMENU_EMAIL_BTN: "Log in with Email",
  $WEB_PRIVACY_ID_12: "Log in with ClientID",
  $WEB_PRIVACY_ID_14:
    "You can find both your ClientID and PrivacyID in the game settings",
  $WEB_AUTHMENU_SEPARATOR: "OR",
  $WEB_SIGNIN_PAGENAME: "Authorization",
  $WEB_SIGNUP_EMAIL_HOLDER: "Enter your email address",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "Enter your password",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "Remember me",
  $WEB_SIGNUP_ENTER_BTN: "Log in",
  $WEB_SALE_HEADER: "PLAYER FAVORITE",
  $WEB_SALE_HEADER_DESCR: "The best set for new players",
  $WEB_SALE_GET: "You'll get:",
  $WEB_EXIT_ACCOUNT: "Log out",
  $WEB_AUTHMENU_INFO: "Log in to access purchases",
  $WEB_SALE_NOTIF:
    "To avoid problems getting what you buy, please log out of your account in the game before making a deposit. Thank you!",
  $CONFIRM_WEB_BUY_HEADER: "Select deposit server",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "Select server",
  $CONFIRM_WEB_BUY_CHARACTER: "Character on the server",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "Character not selected",
  $NOTIF_WEB_BUY_SUCCESS: "Payment successful!",
  $NOTIF_WEB_BUY_FAIL: "Something went wrong :( Payment failed.",
  $WEB_BTN_OK: "OK",
  $WEB_ERROR_HEADER: "Page not found :(",
  $WEB_ERROR_BACK_BTN: "Back to main page",
  $WEB_PROMOCODE_BTN: "PROMO CODES",
  $WEB_PROMOCODE_HEADER: "Enter the promo code and select the server",
  $WEB_PROMOCODE_HEADER_DESCR: "Enter the promo code",
  $WEB_PROMOCODE_ACTIVATE_BTN: "Activate",
  $NOTIF_WEB_PROMO_SUCCESS: "You got a promo code, come play!",
  $WEB_PROMO_FAIL_NOTIF: "Invalid promo code, please try again.",
  $WEB_PROMO_RECEIVED_NOTIF:
    "You got a promo code. Come play and get your reward!",
  $WEB_PROMO_BAD_NOTIF: "This group of promo codes can't be activated.",
  $WEB_PROMOCODE_HEADER_INFO: "Promo code",

  $WEB_EVENT_AUTHORIZATION_NOTIF: "Log in and check for personal promos",
  $WEB_EVENT_ACCESS_NOTIF: "Top-up event available now",
  $WEB_BUY_EVENT_HEADER: "No bonuses for topping up this character's account",
  $WEB_BUY_EVENT_DESCR: "You get:",
  $WEB_BUY_EVENT_INFO: "Top-up bonuses available for some of your characters",
  $WEB_BUY_EVENT_CHOICE_BTN: "Select other character",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "Top-up without bonus",
  $WEB_BUY_EVENT_TIMER: "Promo ends in:",
  $WEB_SUCCESS_PAYMENT:
    "Payment successful. Purchase information has been sent to your inbox.",
  $WEB_FAILED_PAYMENT: "Operation rejected.",
  $WEB_NO_GOODS_IN_SECTION: "No available offers",
  $WEB_GOOGLEPLAY: "Available in Google Play",
  $WEB_APPLESTORE: "Download in App Store",
  $WEB_EVENT_END: "Event ends in: ",
  $WEB_STATEGOLD_HEADER1: "GET UP TO",
  $WEB_STATEGOLD_HEADER2: "MORE VALUE",
  $WEB_EVENT_SUBTITLE1: "Get ",
  $WEB_EVENT_SUBTITLE2: " State Coins ",
  $WEB_EVENT_SUBTITLE3: "right now, along with ",
  $WEB_EVENT_SUBTITLE4: " State Coins ",
  $WEB_EVENT_SUBTITLE5: "and ",
  $WEB_EVENT_SUBTITLE6: " after logging in",
  $WEB_EVENT_SUBTITLE7: "additional days",
  $WEB_EVENT_DAY: "Day",
  $WEB_PRIVACY_ID_15: "Delete Account",
  $WEB_PRIVACY_ID_17: "DELETE MY PERSONAL DATA",
  $WEB_PRIVACY_ID_28:
    "Using this page, you can request the deletion of your game account and all associated personal information and data.",
  $WEB_PRIVACY_ID_29:
    "You can submit a request to delete your account and all associated personal information by following the instructions below.",
  $WEB_PRIVACY_ID_30_1:
    "The privacy policy of CHILLGAMING company is available ",
  $WEB_PRIVACY_ID_30_2: "through the link",
  $WEB_PRIVACY_ID_30_3:
    ". Please review the document before making any requests.",
  $WEB_PRIVACY_ID_31:
    "Please note: Your request will permanently delete all personal data and information associated with the account(s) you used to log in on this site. This includes the deletion of all personal data, in-app and website purchases, all game progress, and any other game-related data. This action cannot be undone.",
  $WEB_PRIVACY_ID_32:
    "Please be aware that we may retain some data. For example, if we cannot identify your account due to a lack of the identification details provided below. We may also retain information that we are required or have the right to keep in accordance with applicable laws.",
  $WEB_PRIVACY_ID_33:
    "In order for us to accurately identify your account, please enter your gaming account data in the respective fields: ClientID, PrivacyID. To enter this information and submit a request for account deletion, use the button below.",
  $WEB_PRIVACY_ID_34:
    "ARE YOU SURE YOU WANT TO PERMANENTLY DELETE YOUR ACCOUNT AND ASSOCIATED DATA?",
  $WEB_PRIVACY_ID_35_1: "ATTENTION:",
  $WEB_PRIVACY_ID_35_2:
    "By submitting a request on this page, your gaming account and all personal information associated with the account(s) you used to log in on this site will be permanently and irreversibly deleted.",
  $WEB_PRIVACY_ID_36:
    "THIS INCLUDES: gaming account, all personal data and information associated with the account(s), and all game progress, including in-game items, virtual currency, and other gaming data.",
  $WEB_PRIVACY_ID_37:
    "THIS ACTION IS IRREVERSIBLE. ONCE THE DELETION PROCESS IS COMPLETE, WE WILL NOT BE ABLE TO RECOVER ANY INFORMATION.",
  $WEB_PRIVACY_ID_38:
    "If you request the deletion of personal information and decide to play OneState RolePlay again, you won't be able to recover your game progress, in-game items, and virtual currency.",
  $WEB_PRIVACY_ID_39:
    "AFTER CONFIRMATION OF THE REQUEST, THE GAME ACCOUNT AND PERSONAL INFORMATION CANNOT BE RECOVERED UNDER ANY CIRCUMSTANCES.",
  $WEB_PRIVACY_ID_40:
    "You can cancel the deletion of your gaming account only within 7 days of submitting the deletion request. To cancel the deletion, log in to the website and click the 'Cancel' button located on the red panel at the top of the main page. After the specified period, CHILLGAMING will not be able to recover any information.",
  $WEB_PRIVACY_ID_41:
    "I am aware that I am submitting a request to delete my gaming account in OneState RolePlay and all associated personal information and data. I UNDERSTAND AND AGREE THAT NEITHER CHILLGAMING NOR I WILL BE ABLE TO RECOVER THIS INFORMATION.",
  $WEB_PRIVACY_ID_42_1: "I accept Chillgaming’s ",
  $WEB_PRIVACY_ID_42_2: "EULA",
  $WEB_PRIVACY_ID_42_3: " and ",
  $WEB_PRIVACY_ID_42_4: "Privacy Policy",
  $WEB_PRIVACY_ID_42_5: "",
  $WEB_PRIVACY_ID_43: "Your request is being processed.",
  $WEB_PRIVACY_ID_44_1:
    "Your gaming account and associated personal data will be automatically deleted within ",
  $WEB_PRIVACY_ID_44_2: " days.",
  $WEB_PRIVACY_ID_45:
    "If you want to cancel the request, you can use the 'Cancel' button below. This option is also available on the main page of the website. Log in to your account and click the 'Cancel' button located on the red panel at the top of the page. You can cancel the account deletion only within 7 days of submitting the request. After the specified period, CHILLGAMING WILL NOT BE ABLE TO RECOVER ANY INFORMATION.",
  $WEB_PRIVACY_ID_47: "View status",
  $WEB_PRIVACY_ID_48: "Cancel",
  $WEB_PRIVACY_POLICY: "Privacy Policy",
  $WEB_FORMAT_DAYS: "d",
  $WEB_FORMAT_HOURS: "h",
  $WEB_FORMAT_MINUTES: "min",
  $WEB_COOKIE_BTN_ACCEPT: "Accept",
  $WEB_COOKIE_BTN_CANCEL: "No, essential cookies only",
  $WEB_COOKIE_BTN_RETURN: "Return",
  $WEB_COOKIE_BTN_RETURN_TO_CHOICE: "Return to choice",
  $WEB_COOKIE_STEP1_TITLE:
    "How does Сhillgaming LTD use cookies and similar technologies?",
  $WEB_COOKIE_STEP1_TEXT_1:
    "When you visit our Website, with your prior permission, we’d like to collect ",
  $WEB_COOKIE_STEP1_TEXT_2:
    "some information about interaction with our Website and use this information to analyze user activity",
  $WEB_COOKIE_STEP1_TEXT_3:
    ". This will allow us to provide an enhanced user experience and improve the functionality of our Website. If you don't want this, we will only use essential cookies.",
  $WEB_COOKIE_STEP1_TEXT_4:
    "By clicking “Accept”, you consent to the collection of ",
  $WEB_COOKIE_STEP1_TEXT_5:
    "your data and its subsequent usage for analytics purposes",
  $WEB_COOKIE_STEP1_TEXT_6: ". You always may ",
  $WEB_COOKIE_STEP1_TEXT_7: "refuse (or withdraw) your consent",
  $WEB_COOKIE_STEP1_TEXT_8: ". More information in the ",
  $WEB_COOKIE_STEP1_TEXT_9: "Cookie Section",
  $WEB_COOKIE_STEP1_TEXT_10: " in our ",
  $WEB_COOKIE_STEP1_TEXT_11: "Privacy Policy",
  $WEB_COOKIE_STEP2_TEXT:
    "Analytics purposes. We’d like to collect some information about your interaction with our Website by running our internally developed automated scripts solution. We do not use third-party tracking and analytics tools. We also want to use this information to prepare statistics reports of user activity, to understand user behavior and improve the functionality and user experience of our Website. This information may contain auth. and payment methods, currency, item purchased, transaction records, used promo codes, unique user/player identifiers, internet protocol address, device (type, model) and browser type.",
  $WEB_COOKIE_STEP3_TEXT_1:
    "You can refuse (or withdraw) your consent at any time through the ",
  $WEB_COOKIE_STEP3_TEXT_2: "cookie settings",
  $WEB_COOKIE_STEP3_TEXT_3:
    " at the bottom of the Website by clicking the “No, essential cookies only” button. Declining will not impact the main functionality of the Website, but may limit certain features. If you refuse (or withdraw) your consent, we will respect your choice and experience on our Website will not be tracked. If you don't select any of the two options, no cookies will be deployed, but the banner will re-appear every time you access our Website.",
  $WEB_TERMS_OF_SERVICE: "Terms of Service",
  $WEB_FOOTER_PRIVACY_POLICY: "Privacy Policy",
  $WEB_INFO_TITLE: "Top-up Information",
  $WEB_INFO_1:
    "The game One State RP supports making purchases through a website (Web-Shop).",
  $WEB_INFO_2:
    "Most often, purchases on the website are more profitable than in-game purchases.",
  $WEB_INFO_3:
    "State Сoins and all virtual items* purchased on this site are received by the character you selected during the purchase. (State Сoins are an in-game currency that can be exchanged for virtual items in the game).",
  $WEB_INFO_4:
    "The in-game currency and virtual items purchased on the website will appear in your game account immediately after payment. You will receive a notification upon entering the game.",
  $WEB_INFO_5:
    "In the case of technical work or issues on the server, the purchased in-game currency and virtual items may be sent to your game account with a delay. If you do not receive your purchase, try logging out and then back into the game. If this does not help, please contact Support, providing the time of the transaction, the type of purchase, and the account name.",
  $WEB_FOOTER_TAB_POLICY: "Privacy Policy",
  $WEB_FOOTER_TAB_TERMS: "Terms of Service",
  $WEB_FOOTER_TAB_DELETE_ACC: "Delete Account",
  $WEB_FOOTER_TAB_COOKIE: "Cookie Settings",
  $WEB_FOOTER_TAB_SUPPORT: "Support",
  $WEB_SOCIALS_TITLE: "Subscribe to us:",
  $WEB_SOCIALS_US: "Our ",
  $WEB_SOCIALS_DESCR_1: "We have several ",
  $WEB_SOCIALS_DESCR_2:
    " accounts for different countries. Please choose the appropriate one.",
  $WEB_BANNER1_1: "Gifts for your first",
  $WEB_BANNER1_2: "purchase on the website!",
  $WEB_BANNER1_BONUS: "+ bonus",
  $WEB_BANNER2_1: "your gift is already in the game on the server - ",
  $WEB_BANNER2_2: "!",
};
