import { FC, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

import { setPlayer, setSkipAddPlayer } from "../../features/player/playerSlice";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useValidate } from "../../hooks/useValidate";
import { SelectorOfferData } from "../../types/uikit";
import { UserPlayer } from "../../types/user";
import { useValidationContext } from "../Form/Form";
import { Select } from "../Select";
import { SelectInfo } from "../SelectInfo";
import styles from "./SelectPlayer.module.sass";

type Props = {
  name: string;
  isReturnServerConfigId?: boolean;
  offerData?: Record<string, SelectorOfferData[]>;
  isMiniVersion?: boolean;
  players?: UserPlayer[];
};

const SelectPlayer: FC<Props> = ({
  name,
  isReturnServerConfigId = false,
  isMiniVersion,
  players,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { schema } = useValidationContext();
  const { onChange, onBlur, ref } = register(name);
  const { isError } = useValidate({ name, errors, schema });

  const { player, skipAddPlayer } = useTypedSelector((state) => state.player);

  useEffect(() => {
    if (players && players.length > 0 && !skipAddPlayer) {
      dispatch(
        setPlayer(
          players?.reduce(
            (prev, current) =>
              new Date(prev.updated_at) > new Date(current.updated_at)
                ? prev
                : current,
            players[0],
          ),
        ),
      );
      dispatch(setSkipAddPlayer(true));
    }
  }, [players]);

  useEffect(() => {
    if (player) {
      setValue(
        name,
        isReturnServerConfigId ? player?.server_id : player?.player_id,
      );
    }
  }, [player]);

  const handleChangePlayer = useCallback(
    (value?: UserPlayer) => {
      setValue(
        name,
        isReturnServerConfigId ? value?.server_id : value?.player_id,
      );
      dispatch(setPlayer(value));
    },
    [setValue, setPlayer],
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={`${isMiniVersion ? styles.isMiniVersion : ""} ${
          styles.item
        }`}
      >
        {!isMiniVersion && (
          <span className={styles.label}>
            {translate("$CONFIRM_WEB_BUY_HEADER_DESCR")}
          </span>
        )}
        <Select
          value={player}
          isError={isError}
          onChange={handleChangePlayer}
          isMiniVersion={isMiniVersion}
        />
      </div>
      {!isMiniVersion && (
        <div className={styles.item}>
          <span className={styles.label}>
            {translate("$CONFIRM_WEB_BUY_CHARACTER")}
          </span>
          <SelectInfo value={player?.nickname} />
        </div>
      )}
      <input
        ref={ref}
        id={name}
        name={name}
        type="hidden"
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};
export default SelectPlayer;
