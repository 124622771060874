import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import appStoreImgEN from "../../assets/images/icons/appstore-en.svg";
import googleStoreImgEN from "../../assets/images/icons/google-store-en.svg";
import { storeImgs } from "../../constants/stores";
import { useRuHost } from "../../hooks/useRuHost";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { tracker } from "../../utils/tracker";
import styles from "./WelcomeBlock.module.sass";

const WelcomeBlock = () => {
  const clientId = useTypedSelector((state) => state.auth.client_id);
  const { i18n } = useTranslate();
  const isRuHost = useRuHost();

  const currentAppStoreImg = useMemo(
    () => storeImgs.appStore[i18n.language] || appStoreImgEN,
    [i18n.language],
  );
  const currentGoogleStoreImg = useMemo(
    () => storeImgs.googleStore[i18n.language] || googleStoreImgEN,
    [i18n.language],
  );

  const linkAppleStore = useMemo(() => {
    if (isRuHost) {
      return "https://apps.apple.com/ua/app/one-state-rp-%D1%81%D0%B8%D0%BC%D1%83%D0%BB%D1%8F%D1%82%D0%BE%D1%80-%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8/id1597760047?l=ru";
    }

    return "https://apps.apple.com/ua/app/one-state-rp-online/id1597760047";
  }, [window.location.host]);

  const linkGoogleStore = useMemo(() => {
    if (isRuHost) {
      return "https://play.google.com/store/apps/details?id=com.Chillgaming.oneState&hl=ru";
    }

    return "https://play.google.com/store/apps/details?id=com.Chillgaming.oneState";
  }, [window.location.host]);

  const onClickStoreBtn = useCallback(
    (store: string) => () => {
      tracker(clientId).track("web_click_store_link", { params: store });
    },
    [tracker, clientId],
  );

  return (
    <section className={isRuHost ? styles.wrapperForRu : styles.wrapper}>
      <div className="container relative">
        <div className={styles.btns}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            to={linkAppleStore}
            className={styles.btn}
            onClick={onClickStoreBtn("apple")}
          >
            <img
              src={currentAppStoreImg}
              alt="app store"
              width="auto"
              height="100%"
            />
          </Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            to={linkGoogleStore}
            className={styles.btn + " ml30 " + styles.btnRight}
            onClick={onClickStoreBtn("google")}
          >
            <img
              src={currentGoogleStoreImg}
              alt="google store"
              width="auto"
              height="100%"
            />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default WelcomeBlock;
