import { useMemo } from "react";

import { useTranslate } from "../../hooks/useTranslate";
import i18n from "../../utils/i18n";
import styles from "./InfoBlock.module.sass";

const InfoBlock = () => {
  const { translate } = useTranslate();
  const infoBlockContent = useMemo(
    () => [
      translate("$WEB_INFO_1"),
      translate("$WEB_INFO_2"),
      translate("$WEB_INFO_3"),
      translate("$WEB_INFO_4"),
      translate("$WEB_INFO_5"),
    ],
    [i18n.language],
  );
  const isArLang = useMemo(() => i18n.language === "ar", [i18n.language]);

  return (
    <div className={styles.wrapper}>
      <p className={`${styles.title} ${isArLang ? "text-right rtl" : ""}`}>
        {translate("$WEB_INFO_TITLE")}
      </p>
      <ul className={styles.list}>
        {infoBlockContent.map((item, idx) => (
          <li
            className={`${styles.listItem} ${
              isArLang ? "text-right rtl " + styles.listItemArab : ""
            }`}
            key={idx}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InfoBlock;
