import Cookies from "js-cookie";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setCookieBlock } from "../../features/cookie/cookieSlice";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useTranslate } from "../../hooks/useTranslate";
import i18n from "../../utils/i18n";
import Button from "../Button";
import styles from "./CookiesBlock.module.sass";

const CookiesBlock = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [tab, setTab] = useState<number>(0);
  const [isChoiced, setIsChoiced] = useState<boolean>(false);
  const [isHiding, setIsHiding] = useState<boolean>(true);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const returnToChoice = () => {
    setIsChoiced(false);
    setTab(0);
  };

  useEffect(() => {
    setIsChoiced(Cookies.get("isAnalytics") !== undefined ? true : false);
  }, [Cookies.get("isAnalytics")]);

  useEffect(() => {
    setTimeout(() => setIsHiding(false), 500);
  }, []);

  const closeCookiesBlock = () => {
    setIsHiding(true);
    setTimeout(() => dispatch(setCookieBlock(false)), 500);
  };

  const handleCookieSolution = (bool: "true" | "false") => () => {
    Cookies.set("isAnalytics", bool, { expires: 180 });
    setIsChoiced(true);
    closeCookiesBlock();
  };

  const changeTab = (num: number) => () => setTab(num);

  const content = useMemo(
    () => [
      <>
        <p className={styles.title}>{translate("$WEB_COOKIE_STEP1_TITLE")}</p>
        <p className={styles.text}>
          {translate("$WEB_COOKIE_STEP1_TEXT_1")}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <span className={styles.link} onClick={changeTab(1)}>
            {translate("$WEB_COOKIE_STEP1_TEXT_2")}
          </span>
          {translate("$WEB_COOKIE_STEP1_TEXT_3")}
          <br /> {translate("$WEB_COOKIE_STEP1_TEXT_4")}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <span className={styles.link} onClick={changeTab(1)}>
            {translate("$WEB_COOKIE_STEP1_TEXT_5")}
          </span>
          {translate("$WEB_COOKIE_STEP1_TEXT_6")}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <span className={styles.link} onClick={changeTab(2)}>
            {translate("$WEB_COOKIE_STEP1_TEXT_7")}
          </span>
          {translate("$WEB_COOKIE_STEP1_TEXT_8")}
          <a
            className={styles.link}
            href="/static/onestate-webshop-privacy-policy.html#cookie"
            target="_blank"
            rel="noreferrer"
          >
            {translate("$WEB_COOKIE_STEP1_TEXT_9")}
          </a>
          {translate("$WEB_COOKIE_STEP1_TEXT_10")}
          <a
            className={styles.link}
            href="/static/onestate-webshop-privacy-policy.html"
            target="_blank"
            rel="noreferrer"
          >
            {translate("$WEB_COOKIE_STEP1_TEXT_11")}
          </a>
          .
        </p>
      </>,
      <>
        <p className={styles.text}>{translate("$WEB_COOKIE_STEP2_TEXT")}</p>
      </>,
      <>
        <p className={styles.text}>
          {translate("$WEB_COOKIE_STEP3_TEXT_1")}
          <span className={styles.underline}>
            {translate("$WEB_COOKIE_STEP3_TEXT_2")}
          </span>
          {translate("$WEB_COOKIE_STEP3_TEXT_3")}
        </p>
      </>,
    ],
    [i18n.language],
  );

  useOnClickOutside(wrapperRef, closeCookiesBlock);

  return (
    <>
      <div
        ref={wrapperRef}
        className={`${styles.wrapper} ${isHiding ? styles.hidden : ""}`}
      >
        <div>{content[tab]}</div>
        <div className={styles.btns}>
          {!isChoiced && tab === 0 ? (
            <>
              <Button
                className={`${styles.btn} ${styles.btnAccept}`}
                onClick={handleCookieSolution("true")}
              >
                {translate("$WEB_COOKIE_BTN_ACCEPT")}
              </Button>
              <Button
                className={`${styles.btn} ${styles.btnCancel}`}
                onClick={handleCookieSolution("false")}
              >
                {translate("$WEB_COOKIE_BTN_CANCEL")}
              </Button>
            </>
          ) : (
            <Button
              className={`${styles.btn} ${styles.btnCancel}`}
              onClick={returnToChoice}
            >
              {tab === 0
                ? translate("$WEB_COOKIE_BTN_RETURN_TO_CHOICE")
                : translate("$WEB_COOKIE_BTN_RETURN")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CookiesBlock;
