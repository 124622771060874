import { createSlice } from "@reduxjs/toolkit";

import { UserPlayer } from "../../types/user";

type PlayerState = {
  player: UserPlayer | undefined;
  playersSelect: UserPlayer[] | [];
  skipAddPlayer: boolean;
};

const initialState: PlayerState = {
  player: undefined,
  playersSelect: [],
  skipAddPlayer: false,
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlayer: (state, { payload }) => {
      state.player = payload;
    },
    setPlayersSelect: (state, { payload }) => {
      state.playersSelect = payload;
    },
    setSkipAddPlayer: (state, { payload }) => {
      state.skipAddPlayer = payload;
    },
  },
});

export const { setPlayer, setSkipAddPlayer, setPlayersSelect } =
  playerSlice.actions;

export default playerSlice.reducer;
