import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import { appConfig } from "../config";
import { CustomObject } from "../types/common";
import { getFingerprint } from "./fingerprint";
import { getDeviceType } from "./getDeviceType";

export const tracker = (clientId: string) => {
  const { location, document } = window;

  const _data = "data-";
  const endpoint = appConfig.api_analytics_url as string;
  const eventRegex = /data-event-([\w-_]+)/;
  const eventNameAttribute = _data + "event";

  /* Helper functions */
  const handleClicks = (): void => {
    document.addEventListener(
      "click",
      async (e: Event) => {
        if (e instanceof MouseEvent) {
          const mouseEvent = e as MouseEvent;

          const isSpecialTag = (tagName: string): boolean =>
            ["BUTTON", "A"].includes(tagName);

          const trackElement = async (el: Element): Promise<void> => {
            const attr = el.getAttribute.bind(el);
            const eventName = attr(eventNameAttribute);

            if (eventName) {
              const eventData: CustomObject = {};

              el.getAttributeNames().forEach((name) => {
                const match = name.match(eventRegex);

                if (match) {
                  eventData[match[1]] = attr(name);
                }
              });
              return track(eventName, eventData);
            }
          };

          const findParentTag = (
            rootElem: Element,
            maxSearchDepth: number,
          ): Element | null => {
            let currentElement: Element | null = rootElem;
            for (let i = 0; i < maxSearchDepth; i++) {
              if (isSpecialTag(currentElement.tagName)) {
                return currentElement;
              }
              currentElement = currentElement.parentElement;
              if (!currentElement) {
                return null;
              }
            }
            return null;
          };

          const el = mouseEvent.target as Element;
          const parentElement = isSpecialTag(el.tagName)
            ? el
            : findParentTag(el, 10);

          if (parentElement) {
            const eventName = parentElement.getAttribute(eventNameAttribute);

            if (eventName) {
              if (parentElement.tagName === "A") {
                const anchorElement = parentElement as HTMLAnchorElement;
                const external =
                  anchorElement.target === "_blank" ||
                  mouseEvent.ctrlKey ||
                  mouseEvent.shiftKey ||
                  mouseEvent.metaKey ||
                  (mouseEvent.button && mouseEvent.button === 1);

                if (eventName && anchorElement.href) {
                  if (!external) {
                    e.preventDefault();
                  }
                  return trackElement(parentElement).then(() => {
                    if (!external) location.href = anchorElement.href;
                  });
                }
              } else if (parentElement.tagName === "BUTTON") {
                return trackElement(parentElement);
              }
            }
          } else {
            return trackElement(el);
          }
        }
      },
      true,
    );
  };

  /* Tracking functions */

  const trackingDisabled = (): boolean => Cookies.get("isAnalytics") !== "true";

  const send = async (data?: any): Promise<void> => {
    const headers = {
      "Content-Type": "application/json",
    };
    if (data) {
      try {
        await fetch(endpoint, {
          method: "POST",
          body: JSON.stringify(data),
          headers,
        });
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  const createAndSendData = (
    eventName: string,
    eventData: object | undefined,
  ) => {
    const newData: CustomObject[] = [];
    const eventInfo = {
      event_name: eventName,
      event_id: uuidv4(),
      event_datetime: new Date().toISOString(),
      fingerprint_id: getFingerprint(),
      client_id: clientId || null,
      device_type: getDeviceType(),
      user_agent: navigator.userAgent,
      data: eventData,
    };
    newData.push(eventInfo);
    if (newData.length > 0) {
      send(newData);
    }
  };

  const track = (obj?: string, data?: object) => {
    if (trackingDisabled()) return;
    if (typeof obj === "string") {
      return createAndSendData(
        obj,
        typeof data === "object" ? data : undefined,
      );
    }
    return;
  };

  /* Start */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(window as any).umami) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).umami = {
      track,
    };
  }

  // startTimer();

  if (!trackingDisabled()) {
    handleClicks();

    const init = (): void => {
      if (document.readyState === "complete") {
        track();
      }
    };

    document.addEventListener("readystatechange", init, true);

    init();
  }
  return {
    track,
    send,
  };
};
