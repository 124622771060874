import { createApi } from "@reduxjs/toolkit/query/react";

import { useRequest } from "../../hooks/useRequest";
import { RTKQuery } from "../../types/auth";

const { baseQueryWithRefresh } = useRequest();

type PromocodeActivateResponse = {
  status: 0 | 1;
  type: string;
};

type PromocodeActivateDto = {
  code: string;
  server_config_id: string;
};

export const promocodeApi = createApi({
  reducerPath: "promocodeApi",
  baseQuery: baseQueryWithRefresh("user"),
  endpoints: (build) => ({
    promocodeActivate: build.mutation<
      RTKQuery<PromocodeActivateResponse>,
      PromocodeActivateDto
    >({
      query: (body) => ({
        url: `/promocodes/enter`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePromocodeActivateMutation } = promocodeApi;
