import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { setCookieBlock } from "../../features/cookie/cookieSlice";
import { useRuHost } from "../../hooks/useRuHost";
import { useTranslate } from "../../hooks/useTranslate";
import Button from "../Button";
import styles from "./FooterTabs.module.sass";

const FooterTabs = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const isRuHost = useRuHost();
  const { i18n } = useTranslate();

  const showCookieBlock = () => {
    dispatch(setCookieBlock(true));
  };

  const langForSupportLink = useMemo(() => {
    if (i18n.language === "jp") return "ja";
    else return i18n.language;
  }, [i18n.language]);

  return (
    <>
      {" "}
      <Button className={styles.btnTab}>
        <Link
          to={
            isRuHost
              ? "/static/onestateru-privacy-policy.html"
              : "/static/onestate-webshop-privacy-policy.html"
          }
          target="_blank"
          className={styles.link}
        >
          {translate("$WEB_FOOTER_TAB_POLICY")}
        </Link>
      </Button>
      {isRuHost && (
        <Button className={styles.btnTab}>
          <Link
            className={styles.link}
            to="/static/onestateru-terms-of-use.html"
            target="_blank"
          >
            Пользовательское соглашение
          </Link>
        </Button>
      )}
      <Button className={styles.btnTab}>
        <Link
          to={"/static/onestate-webshop-terms-of-service.html"}
          target="_blank"
        >
          {translate("$WEB_FOOTER_TAB_TERMS")}
        </Link>
      </Button>
      <Button
        className={styles.btnTab}
        onClick={() => navigate("/delete-my-data")}
      >
        {translate("$WEB_FOOTER_TAB_DELETE_ACC")}
      </Button>
      <Button className={styles.btnTab}>
        <Link
          to={`https://chillbase.helpshift.com/hc/${langForSupportLink}`}
          target="_blank"
        >
          {translate("$WEB_FOOTER_TAB_SUPPORT")}
        </Link>
      </Button>
      <Button className={styles.btnTab} onClick={showCookieBlock}>
        {translate("$WEB_FOOTER_TAB_COOKIE")}
      </Button>
    </>
  );
};

export default FooterTabs;
