import { useMemo } from "react";

import { useValidateProps } from "../types/hooks";

export const useValidate = ({ name, errors, schema }: useValidateProps) => {
  const isError = useMemo(() => !!errors[name], [errors, name]);
  const isRequired: boolean = useMemo(
    () =>
      (schema &&
        schema.fields[name] &&
        schema.fields[name].exclusiveTests?.required) ||
      false,
    [schema, name],
  );
  const errorMessage = useMemo(
    () => (isError ? errors[name]?.message : null),
    [isError, errors, name],
  );

  return { isError, isRequired, errorMessage };
};
