import { FC } from "react";

import { ButtonProps } from "../../types/uikit";

export const Button: FC<ButtonProps> = ({
  children,
  type,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <button type={type} onClick={onClick} disabled={disabled} {...props}>
      {children}
    </button>
  );
};
