import { configureStore } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import authReducer from "../features/auth/authSlice";
import cookieReducer from "../features/cookie/cookieSlice";
import playerReducer from "../features/player/playerSlice";
import productsReducer from "../features/products/productsSlice";
import promocodeReducer from "../features/promocode/promocodeSlice";
import promptsReducer from "../features/prompts/promptsSlice";
import tabsReducer from "../features/tabs/tabsSlice";
import userReducer from "../features/user/userSlice";
import { authApi } from "../services/api/apiAuthSlice";
import { offerApi } from "../services/api/apiOfferSlice";
import { productsApi } from "../services/api/apiProductsSlice";
import { promocodeApi } from "../services/api/apiPromocodeSlice";
import { userApi } from "../services/api/apiUserSlice";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [promocodeApi.reducerPath]: promocodeApi.reducer,
    [offerApi.reducerPath]: offerApi.reducer,
    auth: authReducer,
    products: productsReducer,
    user: userReducer,
    promocode: promocodeReducer,
    prompts: promptsReducer,
    player: playerReducer,
    tabs: tabsReducer,
    cookie: cookieReducer,
  },
  preloadedState: {
    auth: {
      token: "",
      refresh_token: localStorage.getItem("refresh_token") || "",
      expires_at: Number(localStorage.getItem("expires_at")) || 0,
      client_id: "",
    },
    cookie: {
      isCookieBlock: Cookies.get("isAnalytics") == undefined ? true : false,
    },
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      productsApi.middleware,
      userApi.middleware,
      promocodeApi.middleware,
      offerApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
