import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setPlayersSelect } from "../../features/player/playerSlice";
import { useTranslate } from "../../hooks/useTranslate";
import { useGetPlayersUserQuery } from "../../services/api/apiUserSlice";
import { AuthAndNavigateProps } from "../../types/pages";
import Button from "../Button";
import Players from "../Players";
import Profile from "../Profile";
import styles from "./AuthBlock.module.sass";

type AuthBlockProps = AuthAndNavigateProps;

const AuthBlock: FC<AuthBlockProps> = ({ navigateHandler, isAuth }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { data } = useGetPlayersUserQuery("");
  const players = data?.data || [];
  const refetchPlayersQuery: () => void = useGetPlayersUserQuery("").refetch;

  useEffect(() => {
    if (players) {
      dispatch(setPlayersSelect(players));
    }
  }, [players]);

  useEffect(() => {
    if (isAuth) {
      refetchPlayersQuery();
    }
  }, [isAuth]);

  return (
    <section className={styles.wrapper}>
      {isAuth ? (
        <>
          <Profile isAuth={isAuth} />
          <Players />
        </>
      ) : (
        <Button className={styles.btn} onClick={navigateHandler}>
          {translate("$WEB_SIGNIN_PAGENAME")}
        </Button>
      )}
    </section>
  );
};

export default AuthBlock;
