import { FC } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CloseIcon } from "../../assets/images/icons/x-icon.svg";
import { ModalTemplateProps } from "../../types/modals";
import Button from "../Button";

Modal.setAppElement("#wrapper");

export const ModalSingleTemplate: FC<ModalTemplateProps> = ({
  children,
  title,
  isOpen,
  onClose,
  className,
}) => {
  const navigate = useNavigate();

  const goBackHandler = () => {
    onClose ? onClose() : navigate(`../`);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={goBackHandler}
      className={`modal ${className ? className : ""}`}
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "888" },
      }}
    >
      <div className="modal-wrapper">
        <Button className="modal-btn" onClick={goBackHandler}>
          <CloseIcon className="modal-close-icon" />
        </Button>
        <h2 className="modal-title">{title}</h2>
        {children}
      </div>
    </Modal>
  );
};
