import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  token: string;
  refresh_token: string;
  expires_at: number;
  client_id: string;
};

const initialState: AuthState = {
  token: "",
  refresh_token: "",
  expires_at: 0,
  client_id: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { token, refresh_token, expires_at, client_id },
      }: PayloadAction<AuthState>,
    ) => {
      state.refresh_token = refresh_token;
      state.token = token;
      state.client_id = client_id;
      state.expires_at = expires_at;

      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_at", String(expires_at));
    },
    logOut: (state) => {
      state.token = "";
      state.expires_at = 0;
      state.refresh_token = "";
      state.client_id = "";
      state.token = "";

      localStorage.setItem("refresh_token", "");
      localStorage.setItem("expires_at", "");

      if (sessionStorage.getItem("tracked-oauth")) {
        sessionStorage.removeItem("tracked-oauth");
      }
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;
