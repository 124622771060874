import { useTypedSelector } from "../../hooks/useTypedSelector";
import BannerFirstBuyAfter from "../BannerFirstBuyAfter";
import BannerFirstBuyBefore from "../BannerFirstBuyBefore";

interface Props {
  isAuth: boolean;
}
const BannerFirstBuy = ({ isAuth }: Props) => {
  const { player } = useTypedSelector((state) => state.player);
  const isGiftForFirstPurchaseAvailable =
    player?.web_first_purchase_reward?.status === "available";
  const isGiftForFirstPurchaseSent =
    player?.web_first_purchase_reward?.status === "sent";

  return (
    <>
      {isGiftForFirstPurchaseSent ? (
        <BannerFirstBuyAfter />
      ) : isGiftForFirstPurchaseAvailable || !isAuth ? (
        <BannerFirstBuyBefore />
      ) : null}
    </>
  );
};

export default BannerFirstBuy;
