import { ar } from "./ar";
import { de } from "./de";
import { en } from "./en";
import { es } from "./es";
import { fr } from "./fr";
import { it } from "./it";
import { jp } from "./jp";
import { pt } from "./pt";
import { ru } from "./ru";

export const resources = {
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
  jp: {
    translation: jp,
  },
  it: {
    translation: it,
  },
  es: {
    translation: es,
  },
  ar: {
    translation: ar,
  },
} as const;
