import { useMemo } from "react";

import CaseImg1 from "../../assets/images/case-1.png";
import CaseImg2 from "../../assets/images/case-2.png";
import CaseImg3 from "../../assets/images/case-3.png";
import FirstPurchaseBannerBeforeImg from "../../assets/images/first-purchase-banner-before.jpg";
import { ReactComponent as SoftIcon } from "../../assets/images/icons/soft-icon.svg";
import { useTranslate } from "../../hooks/useTranslate";
import styles from "./BannerFirstBuyBefore.module.sass";

const BannerFirstBuyBefore = () => {
  const { translate, i18n } = useTranslate();
  const isArLang = useMemo(() => i18n.language === "ar", [i18n.language]);
  return (
    <div className={styles.wrapper}>
      <img
        src={FirstPurchaseBannerBeforeImg}
        alt="banner before"
        className={styles.imageMain}
      />
      <div className={styles.titleWrap}>
        <p className={styles.title}>{translate("$WEB_BANNER1_1")}</p>
        <p className={styles.title}>{translate("$WEB_BANNER1_2")}</p>
      </div>
      <div className={styles.cases}>
        <img src={CaseImg1} alt="case 1" />
        <img src={CaseImg2} alt="case 2" />
        <img src={CaseImg3} alt="case 3" />
      </div>
      <div className={styles.pieces}>
        <p>x3</p>
        <p>x1</p>
        <p>x1</p>
      </div>
      <div className={`${styles.bonus} ${isArLang ? styles.isArLang : ""}`}>
        <p className={styles.text}>{translate("$WEB_BANNER1_BONUS")}</p>
        <SoftIcon className={styles.icon} />
        <p className={styles.number}>25.000</p>
      </div>
    </div>
  );
};

export default BannerFirstBuyBefore;
