import { createApi } from "@reduxjs/toolkit/query/react";
import { min } from "lodash";

import { useRequest } from "../../hooks/useRequest";
import { RTKQuery } from "../../types/auth";
import { dayJsInstance } from "../../utils/dayJsInstance";
const { baseQueryWithRefresh } = useRequest();
type OfferDataResultResponse = {
  isHaveWelcomeOffer?: boolean;
  minEndTimer?: number | null;
  items: OfferDataResponse[];
};

export type OfferDataResponse = {
  server_id: string;
  data: {
    request_id: string;
    client_id: string;
    offer_id: string;
    status: boolean;
    packs: // | [
    //     {
    //       id: string;
    //       available: number;
    //       old_value: number;
    //       new_value: number;
    //     },
    //   ]
    | {
          id: string;
          available: number;
          old_value: number;
          new_value: number;
        }[]
      | null;
    end_time: number | null;
    event: string;
  };
};

export const offerApi = createApi({
  reducerPath: "offerApi",
  baseQuery: baseQueryWithRefresh("base"),
  endpoints: (build) => ({
    getOffers: build.query<RTKQuery<OfferDataResultResponse>, unknown>({
      query: () => ({
        url: `/xsolla/offers`,
      }),
      transformResponse: (response: RTKQuery<OfferDataResponse[]>) => {
        // const customResponse: RTKQuery<OfferDataResultResponse> = {
        //   data: {
        //     minEndTimer: null,
        //     isHaveWelcomeOffer: false,
        //     items: [
        //       {
        //         server_id: "b624876d-ae1b-4e97-88ed-800420aa8c88",
        //         data: {
        //           request_id: "29af1e0b-583e-4d92-860d-0e931ffdf1d1",
        //           client_id: "6a95343e-3286-4189-86fb-6ba7fae90fc9",
        //           offer_id: "welcome_offer_x2",
        //           status: true,
        //           packs: [
        //             {
        //               id: "shop_hard_6_v34",
        //               available: 1,
        //               old_value: 8000,
        //               new_value: 16000,
        //             },
        //             {
        //               id: "shop_hard_4_v34",
        //               available: 1,
        //               old_value: 1875,
        //               new_value: 3750,
        //             },
        //             {
        //               id: "shop_hard_3_v34",
        //               available: 1,
        //               old_value: 750,
        //               new_value: 1500,
        //             },
        //             {
        //               id: "shop_hard_1_v34",
        //               available: 0,
        //               old_value: 75,
        //               new_value: 150,
        //             },
        //             {
        //               id: "shop_hard_5",
        //               available: 1,
        //               old_value: 375,
        //               new_value: 750,
        //             },
        //           ],
        //           end_time: 1697337485,
        //           event: "offer_response_status",
        //         },
        //       },
        //     ],
        //   },
        //   error: false,
        //   errors: [],
        //   message: "",
        // };

        // return customResponse;

        const activeOffers = response?.data
          ? response.data?.filter((offer) => {
              return (
                offer.data.status &&
                offer.data.packs &&
                !dayJsInstance
                  .utc()
                  .isAfter(dayJsInstance.unix(offer.data.end_time || 0))
              );
            })
          : [];
        const minOfferEndTime = min(
          activeOffers.map((activeOffer) => activeOffer.data.end_time),
        );

        const result: RTKQuery<OfferDataResultResponse> = {
          data: {
            minEndTimer: minOfferEndTime,
            isHaveWelcomeOffer: activeOffers.length > 0,
            items: activeOffers,
          },
          error: false,
          errors: [],
          message: "",
        };

        return result;
      },
    }),
  }),
});

export const { useGetOffersQuery } = offerApi;
