import { ChangeEvent, FC, SVGProps, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useValidate } from "../../hooks/useValidate";
import { useValidationContext } from "../Form/Form";
import PromptAuth from "../PromptAuth";
import styles from "./Input.module.sass";
type InputProps = {
  name: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  customIconColor?: string;
  customPlaceholderColor?: string;
  errorMessage?: string;
  promptImgContent?: string;
  classNameTopPosition?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isValidUuid?: boolean | undefined;
  isPrompt?: boolean;
  onMouseEnterForPrompt?: () => void;
  onMouseLeaveForPrompt?: () => void;
} & Partial<HTMLInputElement>;

export const Input: FC<InputProps> = ({
  name,
  type,
  placeholder,
  icon: Icon,
  customIconColor,
  errorMessage,
  onChange,
  isPrompt,
  isValidUuid = undefined,
  onMouseEnterForPrompt,
  onMouseLeaveForPrompt,
  defaultValue,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { schema } = useValidationContext();
  const { onBlur, onChange: onChangeRegister, ref } = register(name);
  const { isError } = useValidate({ name, errors, schema });
  const [isFocus, setFocus] = useState<boolean>(false);

  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onBlur(e);
    setFocus(false);
  }, []);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
    },
    [onChange],
  );

  const iconColor = isError
    ? "#F15A5A"
    : customIconColor
    ? customIconColor
    : isFocus
    ? "#fff"
    : "rgba(255, 255, 255, 0.5)";

  const errorStyles =
    isError || errorMessage || (isValidUuid !== undefined && !isValidUuid)
      ? "error-color error-border-color "
      : "";

  return (
    <div className={`input ${styles.wrapper}`}>
      {Icon && <Icon width={22} height={22} style={{ color: iconColor }} />}
      <div className="relative">
        <input
          className={errorStyles + styles.input}
          ref={ref}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          onBlur={handleBlur}
          onChange={onChange ? handleChange : onChangeRegister}
          onFocus={handleFocus}
          defaultValue={defaultValue}
        />
        {isPrompt && (
          <PromptAuth
            onMouseEnterForPrompt={onMouseEnterForPrompt}
            onMouseLeaveForPrompt={onMouseLeaveForPrompt}
          />
        )}
      </div>
      <input
        name={name}
        type={type}
        id="current-password"
        autoComplete="current-password"
        style={{ display: "none" }}
      ></input>
    </div>
  );
};
