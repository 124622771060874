import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as PromoIcon } from "../../assets/images/icons/promo_icon.svg";
import { selectedProductType } from "../../features/products/productsSlice";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AuthAndNavigateProps } from "../../types/pages";
import Button from "../Button";
import styles from "./FilterButtons.module.sass";

const filterButtons = [
  {
    label: "ONESTATE COINS",
    type: "coins",
  },
  {
    label: "PACKS",
    type: "packs",
  },
  {
    label: "ALL",
    type: "all",
  },
  {
    label: "EVENT PASS",
    type: "event",
  },
];
type FilterButtonsProps = AuthAndNavigateProps;

const FilterButtons: FC<FilterButtonsProps> = ({ navigateHandler, isAuth }) => {
  const { productType } = useTypedSelector((state) => state.products);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSelectedProductType = useCallback(
    (type: string) => {
      dispatch(selectedProductType(type));
    },
    [dispatch, selectedProductType],
  );
  const handleOpenPromoModal = () => {
    if (isAuth) {
      return navigateHandler();
    } else {
      return navigate("./auth");
    }
  };

  return (
    <section className={styles.wrapper}>
      {filterButtons.map(({ label, type }) => (
        <Button
          key={label}
          type="button"
          className={`${styles.btn} ${
            productType === type ? styles.isActive : ""
          }`}
          onClick={() => onSelectedProductType(type)}
        >
          {label}
        </Button>
      ))}
      <Button
        onClick={handleOpenPromoModal}
        type="button"
        className={styles.btn + " " + styles.btnPromo}
      >
        <PromoIcon className="mr10" /> {translate("$WEB_PROMOCODE_BTN")}
      </Button>
    </section>
  );
};

export default FilterButtons;
