import * as yup from "yup";

export const schemaPlayer = yup.object({
  player_id: yup.string().required(),
});

export const schemaAuth = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
});

export const schemaAuthClientId = yup.object({
  client_id: yup.string().required(),
  privacy_id: yup.string().required(),
});

export const schemaPromocode = yup.object({
  server_config_id: yup.string().required(),
  code: yup.string().required(),
});

export const schemaDelAcc = yup.object({
  del_acc_checkbox1: yup.boolean().required(),
  del_acc_checkbox2: yup.boolean().required(),
});
