export const ar = {
  $WEB_AUTHMENU_DISCRD_BTN: "Discord تسجيل الدخول عبر",
  $WEB_AUTHMENU_GOOGLE_BTN: "Google Play Games تسجيل الدخول عبر",
  $WEB_AUTHMENU_VK_BTN: "VKontakte تسجيل الدخول عبر",
  $WEB_AUTHMENU_FB_BTN: "Facebook تسجيل الدخول عبر",
  $WEB_AUTHMENU_APPLE_BTN: "Apple تسجيل الدخول عبر",
  $WEB_AUTHMENU_EMAIL_BTN: "تسجيل الدخول بالبريد الإلكتروني",
  $WEB_PRIVACY_ID_12: "ClientID تسجيل الدخول عبر",
  $WEB_PRIVACY_ID_14:
    "يمكنك العثور على كل من ClientID ومعرّف الخصوصية في إعدادات اللعبة",
  $WEB_AUTHMENU_SEPARATOR: "أو",
  $WEB_SIGNIN_PAGENAME: "جارٍ التصديق",
  $WEB_SIGNUP_EMAIL_HOLDER: "أدخل بريدك الإلكتروني",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "أدخل كلمة السر الخاصة بك",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "تذكرني",
  $WEB_SIGNUP_ENTER_BTN: "تسجيل الدخول",
  $WEB_SALE_HEADER: "اللاعب المفضل",
  $WEB_SALE_HEADER_DESCR: "أفضل مجموعة للاعبين الجدد",
  $WEB_SALE_GET: ":ستحصل على",
  $WEB_EXIT_ACCOUNT: "تسجيل الخروج",
  $WEB_AUTHMENU_INFO: "تسجيل الدخول للوصول إلى المشتريات",
  $WEB_SALE_NOTIF:
    "لتجنب مشاكل الحصول على ما تشتريه، يرجى تسجيل الخروج من حسابك في اللعبة قبل الإيداع. شكرًا لك!",
  $CONFIRM_WEB_BUY_HEADER: "اختر خادم الإيداع",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "اختر الخادم",
  $CONFIRM_WEB_BUY_CHARACTER: "الشخصية على الخادم",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "لم يتم تحديد الشخصية",
  $NOTIF_WEB_BUY_SUCCESS: "تم الدفع بنجاح",
  $NOTIF_WEB_BUY_FAIL: "حدث خطأ ما: (فشل الدفع.",
  $WEB_BTN_OK: "حسنًا",
  $WEB_ERROR_HEADER: "تعذّر العثور على الصفحة",
  $WEB_ERROR_BACK_BTN: "العودة إلى الصفحة الرئيسية",
  $WEB_PROMOCODE_BTN: "الرموز الترويجية",
  $WEB_PROMOCODE_HEADER: "أدخل الرمز الترويجي وحدد الخادم",
  $WEB_PROMOCODE_HEADER_DESCR: "أدخل الرمز الترويجي",
  $WEB_PROMOCODE_ACTIVATE_BTN: "تنشيط",
  $NOTIF_WEB_PROMO_SUCCESS: "لديك رمز ترويجي، تعال العب!",
  $WEB_PROMO_FAIL_NOTIF: "رمز ترويجي غير صالح، من فضلك حاول مرة أخرى.",
  $WEB_PROMO_RECEIVED_NOTIF: "لديك رمز ترويجي. تعال العب واحصل على مكافأتك!",
  $WEB_PROMO_BAD_NOTIF: "لا يمكن تنشيط هذه المجموعة من الرموز الترويجية.",
  $WEB_PROMOCODE_HEADER_INFO: "رمز ترويجي",

  $WEB_EVENT_AUTHORIZATION_NOTIF:
    "قم بتسجيل الدخول والتحقق من العروض الترويجية الشخصية",
  $WEB_EVENT_ACCESS_NOTIF: "فعالية الشحن الإضافي متاحة الآن",
  $WEB_BUY_EVENT_HEADER: "لا توجد مكافآت للشحن الإضافي لهذه الشخصية",
  $WEB_BUY_EVENT_DESCR: ":حصلت على",
  $WEB_BUY_EVENT_INFO: "مكافآت الشحن الإضافي متوفرة لبعض شخصياتك",
  $WEB_BUY_EVENT_CHOICE_BTN: "اختر شخصية أخرى",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "الشحن من دون إضافة",
  $WEB_BUY_EVENT_TIMER: "ينتهي الترويجي في:",
  $WEB_SUCCESS_PAYMENT:
    "تم الدفع بنجاح. تم إرسال معلومات الشراء إلى بريدك الوارد.",
  $WEB_FAILED_PAYMENT: "العملية مرفوضة",
  $WEB_NO_GOODS_IN_SECTION: "لا توجد عروض متاحة الآن،",
  $WEB_GOOGLEPLAY: "متاح في Google Play",
  $WEB_APPLESTORE: "تنزيل في AppStore",
  $WEB_EVENT_END: " :تنتهي الفعالية في",
  $WEB_STATEGOLD_HEADER1: "الحصول على ما يصل إلى",
  $WEB_STATEGOLD_HEADER2: "قيمة أكبر",
  $WEB_EVENT_SUBTITLE1: " الحصول عليه ",
  $WEB_EVENT_SUBTITLE2: " STATE COINS ",
  $WEB_EVENT_SUBTITLE3: " الآن، جنبا إلى جنب مع ",
  $WEB_EVENT_SUBTITLE4: " STATE COINS ",
  $WEB_EVENT_SUBTITLE5: " و",
  $WEB_EVENT_SUBTITLE6: " بعد تسجيل الدخول ",
  $WEB_EVENT_SUBTITLE7: " أيام إضافية ",
  $WEB_EVENT_DAY: "من الأيام",
  $WEB_PRIVACY_ID_15: "حذف الحساب",
  $WEB_PRIVACY_ID_17: "حذف بياناتي الشخصية",
  $WEB_PRIVACY_ID_28:
    "باستخدام هذه الصفحة، يمكنك طلب حذف حساب اللعبة الخاص بك وجميع المعلومات والبيانات الشخصية المرتبطة به.",
  $WEB_PRIVACY_ID_29:
    "يمكنك إرسال طلب لحذف حسابك وجميع المعلومات الشخصية المرتبطة به باتباع التعليمات أدناه.",
  $WEB_PRIVACY_ID_30_1: "تتوفر سياسة الخصوصية لشركة CHILLGAMING ",
  $WEB_PRIVACY_ID_30_2: "من خلال الرابط.",
  $WEB_PRIVACY_ID_30_3: " يرجى مراجعة الوثيقة قبل تقديم أي طلبات.",
  $WEB_PRIVACY_ID_31:
    "يرجى ملاحظة: سيحذف طلبك بشكل دائم جميع البيانات والمعلومات الشخصية المرتبطة بالحساب (الحسابات) التي استخدمتها لتسجيل الدخول إلى هذا الموقع. يتضمن ذلك حذف جميع البيانات الشخصية والمشتريات داخل التطبيق والموقع وجميع تقدم اللعبة وأي بيانات أخرى متعلقة باللعبة. لا يمكن التراجع عن هذا الإجراء.",
  $WEB_PRIVACY_ID_32:
    "يرجى العلم أننا قد نحتفظ ببعض البيانات. على سبيل المثال، إذا لم نتمكن من التحقق من حسابك بسبب عدم وجود تفاصيل الهوية الواردة أدناه. ويمكننا أيضا الاحتفاظ بالمعلومات المطلوبة أو التي يحق لنا الاحتفاظ بها وفقًا للقوانين المعمول بها.",
  $WEB_PRIVACY_ID_33:
    "من أجل تحديد حسابك بدقة، يرجى إدخال بيانات حساب الألعاب الخاصة بك في الحقول الخاصة بها: ClientID, معرّف الخصوصية. لإدخال هذه المعلومات وتقديم طلب لحذف الحساب، استخدم الزر أدناه.",
  $WEB_PRIVACY_ID_34:
    "هل متأكد من أنك تريد حذف حسابك والبيانات المرتبطة به بشكل دائم؟",
  $WEB_PRIVACY_ID_35_1: "تنبيه: ",
  $WEB_PRIVACY_ID_35_2:
    "من خلال تقديم طلب على هذه الصفحة، سيتم حذف حساب الألعاب الخاص بك وجميع المعلومات الشخصية المرتبطة بالحساب (الحسابات) التي استخدمتها لتسجيل الدخول على هذا الموقع بشكل دائم ولا يمكن التراجع عنه.",
  $WEB_PRIVACY_ID_36:
    "يتضمن ذلك: حساب الألعاب وجميع البيانات والمعلومات الشخصية المرتبطة بالحساب (الحسابات) وجميع تقدم اللعبة، بما في ذلك العناصر داخل اللعبة والعملة الافتراضية وبيانات الألعاب الأخرى.",
  $WEB_PRIVACY_ID_37:
    "هذا الأمر لا يمكن التراجع عنه. بمجرد اكتمال عملية الحذف، لن نتمكن من استعادة أي معلومات.",
  $WEB_PRIVACY_ID_38:
    "إذا طلبت حذف المعلومات الشخصية وقررت لعب OneState RolePlay مرة أخرى، فلن تتمكن من استعادة تقدم لعبتك وعناصر اللعبة والعملة الافتراضية.",
  $WEB_PRIVACY_ID_39:
    "بعد تأكيد الطلب، لا يمكن استرداد حساب اللعبة والمعلومات الشخصية تحت أي ظرف من الظروف.",
  $WEB_PRIVACY_ID_40: `يمكنك إلغاء حذف حساب الألعاب الخاص بك فقط في غضون 7 أيام من تقديم طلب الحذف. لإلغاء الحذف، قم بتسجيل الدخول إلى موقع الويب وانقر فوق زر "إلغاء" الموجود على اللوحة الحمراء أعلى الصفحة الرئيسية. بعد الفترة المحددة، لن تتمكن CHILLGAMING من استعادة أي معلومات.`,
  $WEB_PRIVACY_ID_41:
    "أدرك أنني أقدم طلبًا لحذف حساب الألعاب الخاص بي في OneState لعب الأدوار وجميع المعلومات والبيانات الشخصية المرتبطة به. أفهم وأوافق على أنه لن أتمكن من استعادة هذه المعلومات.",
  $WEB_PRIVACY_ID_42_1: " أنا أقبل سياسة ",
  $WEB_PRIVACY_ID_42_2: "EULA",
  $WEB_PRIVACY_ID_42_3: " و ",
  $WEB_PRIVACY_ID_42_4: "الخصوصية في",
  $WEB_PRIVACY_ID_42_5: " Chillgaming ",
  $WEB_PRIVACY_ID_43: ".جارٍ معالجة طلبك",
  $WEB_PRIVACY_ID_44_1:
    " سيتم حذف حساب الألعاب الخاص بك والبيانات الشخصية المرتبطة به تلقائيًا في غضون",
  $WEB_PRIVACY_ID_44_2: "أيام ",
  $WEB_PRIVACY_ID_45: `إذا كنت ترغب في إلغاء الطلب، فيمكنك استخدام زر "إلغاء" أدناه. وهذا الخيار متاح أيضا على الصفحة الرئيسية للموقع. قم بتسجيل الدخول إلى حسابك وانقر فوق زر "إلغاء" الموجود على اللوحة الحمراء في الجزء العلوي من الصفحة. يمكنك إلغاء حذف الحساب فقط في غضون 7 أيام من تقديم طلب الحذف. بعد الفترة المحددة، لن تتمكن CHILLGAMING من استرداد أي معلومات.`,
  $WEB_PRIVACY_ID_47: "عرض الحالة",
  $WEB_PRIVACY_ID_48: "إلغاء",
  $WEB_PRIVACY_POLICY: "سياسة الخصوصية",
  $WEB_FORMAT_DAYS: "يومًا",
  $WEB_FORMAT_HOURS: "ساعة",
  $WEB_FORMAT_MINUTES: "دقيقة",
  $WEB_INFO_TITLE: "معلومات الشحن*",
  $WEB_INFO_1:
    "تدعم لعبة One State RP إجراء عمليات الشراء من خلال موقع ويب (متجر ويب).",
  $WEB_INFO_2:
    "في أغلب الأحيان، تكون عمليات الشراء على موقع الويب أكثر ربحية من عمليات الشراء داخل اللعبة.",
  $WEB_INFO_3:
    "يتم استلام State Сoins وجميع العناصر الافتراضية* التي تم شراؤها من هذا الموقع بواسطة الشخصية التي حددتها في أثناء عملية الشراء. (State Сoins هي عملة داخل اللعبة يمكن استبدالها بعناصر افتراضية في اللعبة).",
  $WEB_INFO_4:
    "ستظهر العملة داخل اللعبة والعناصر الافتراضية التي تم شراؤها من موقع الويب في حساب اللعبة الخاص بك فور الدفع. ستتلقى إشعارًا عند دخول اللعبة.",
  $WEB_INFO_5:
    "في حالة وجود عمل فني أو مشكلات في الخادم، قد يتم إرسال العملة داخل اللعبة والعناصر الافتراضية التي تم شراؤها إلى حساب اللعبة الخاص بك مع تأخير. إذا لم تتلق عملية الشراء الخاصة بك، فحاول تسجيل الخروج ثم العودة إلى اللعبة. إذا لم يساعد ذلك، فيُرجى التواصل مع الدعم، مع تقديم وقت المعاملة ونوع الشراء واسم الحساب.",
  $WEB_FOOTER_TAB_POLICY: "سياسة الخصوصية",
  $WEB_FOOTER_TAB_TERMS: "شروط الخدمة",
  $WEB_FOOTER_TAB_DELETE_ACC: "حذف الحساب",
  $WEB_FOOTER_TAB_COOKIE: "إعدادات ملفات تعريف الارتباط",
  $WEB_FOOTER_TAB_SUPPORT: "الدعم",
  $WEB_SOCIALS_TITLE: "اشترك معنا:",
  $WEB_SOCIALS_US: "حسابنا على ",
  $WEB_SOCIALS_DESCR_1: "لدينا عدة حسابات على ",
  $WEB_SOCIALS_DESCR_2: " لبلدان مختلفة. يُرجى اختيار الحساب المناسب.",
  $WEB_BANNER1_1: "هدايا عند شرائك",
  $WEB_BANNER1_2: "!لأول مرة على الموقع",
  $WEB_BANNER1_BONUS: "مكافأة+",
  $WEB_BANNER2_1: "",
  $WEB_BANNER2_2: "!،هديتك موجودة بالفعل في اللعبة بخادم ",
};
