import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

export type CustomErrorType = {
  data: null;
  error: true;
  errors: { message: string; code: number }[];
  message: string;
};

import { RootState } from "../app/store";
import { appConfig } from "../config";
import { logOut, setCredentials } from "../features/auth/authSlice";
import { RefreshTokenResponse } from "../types/auth";

const mutex = new Mutex();

export const useRequest = () => {
  const getApiUrl = (url: string | undefined): string => {
    if (!url) {
      throw new Error("API URL is not defined");
    }
    return url;
  };
  const baseQuery = (apiUrl: string) =>
    fetchBaseQuery({
      baseUrl: apiUrl,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) {
          headers.set("authorization", `${token}`);
        }
        return headers;
      },
    });

  const mockAuthData = {
    data: {
      data: { refresh_token: "", access_token: "", expires_at: "" },
    },
  };

  const baseQueryRefresh = fetchBaseQuery({
    baseUrl: appConfig.api_auth_url,
  });

  const baseQueryWithCamelize =
    (
      baseQuery: ReturnType<typeof fetchBaseQuery>,
    ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
    async (args, api, extraOptions = {}) => {
      const result = await baseQuery(args, api, extraOptions);

      return result;
    };

  const baseQueryWithRefresh =
    (
      baseQueryType: "user" | "base" = "base",
    ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
    async (args, api, extraOptions = {}) => {
      await mutex.waitForUnlock();

      const currentBaseQuery = baseQuery(
        baseQueryType === "user"
          ? getApiUrl(appConfig.api_user_url)
          : getApiUrl(appConfig.api_url),
      );

      let result = await baseQueryWithCamelize(currentBaseQuery)(
        args,
        api,
        extraOptions,
      );
      const refreshTokenStorage = (api.getState() as RootState).auth
        .refresh_token;

      if (
        result.error &&
        (result.error.status === 401 || result.error.status === 403) &&
        refreshTokenStorage
      ) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();

          try {
            const response = (await baseQueryRefresh(
              {
                url: "/refresh-token",
                method: "POST",
                body: {
                  refresh_token: refreshTokenStorage,
                  fingerprint: localStorage.getItem("fingerprint"),
                },
              },
              api,
              extraOptions,
            )) as unknown as RefreshTokenResponse;

            if ("data" in response) {
              const {
                data: {
                  data: {
                    refresh_token,
                    access_token: token,
                    expires_at,
                    client_id,
                  },
                },
              } = response || mockAuthData;

              if (token && expires_at && refresh_token) {
                api.dispatch(
                  setCredentials({
                    token,
                    expires_at,
                    refresh_token,
                    client_id,
                  }),
                );
                result = await baseQueryWithCamelize(currentBaseQuery)(
                  args,
                  api,
                  extraOptions,
                );
              }
            } else {
              if (!sessionStorage.getItem("oauth-for-del-page")) {
                api.dispatch(logOut());
              }
            }
          } catch (e) {
            console.error(e);
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          result = await baseQueryWithCamelize(currentBaseQuery)(
            args,
            api,
            extraOptions,
          );
        }
      }
      return result;
    };

  return {
    baseQuery,
    mockAuthData,
    baseQueryRefresh,
    baseQueryWithCamelize,
    baseQueryWithRefresh,
  };
};
