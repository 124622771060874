import { FC, useCallback } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import hardIcon from "../../assets/images/icons/hard_icon1.png";
import { selectProduct } from "../../features/products/productsSlice";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useBuyProductMutation } from "../../services/api/apiProductsSlice";
import { ProductData } from "../../types/products";
import { Alert } from "../Alert";
import Button from "../Button";
import { ModalFullScreenTemplate } from "../ModalTemplate/ModalFullScreenTemplate";
import styles from "./ConfirmModal.module.sass";

Modal.setAppElement("#wrapper");

type Props = {
  product?: ProductData;
  playerId: string | undefined;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appendProperties?: any;
  onClose: () => void;
  modalTypePayment: "sbp" | "card" | null;
  onClickBuyBtn: (type: "sbp" | "card" | null) => void;
};

export const ConfirmModal: FC<Props> = ({
  product,
  playerId,
  isOpen,
  appendProperties,
  onClose,
  onClickBuyBtn,
  modalTypePayment,
}) => {
  const { translate } = useTranslate();
  const { client_id: clientId } = useTypedSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const { data: user } = useUserQuery("", {
  //   refetchOnMountOrArgChange: true,
  // });

  const [buyProduct] = useBuyProductMutation();
  const handleBackPlayer = useCallback(() => {
    onClose();
    dispatch(selectProduct({ product }));
  }, []);

  // TODO: передавать сюда уже готовый объект response из BuyModal

  const handleSubmitForm = async () => {
    const response = await buyProduct({
      player_id: playerId || "",
      product_id: product?.product_id || "",
      // locale: i18n.language,
      // country: user?.data.country || "",
      // currence: product?.price?.currency || "USD",
      client_id: clientId,
      payment_method: modalTypePayment,
      ...((typeof appendProperties === "object" && appendProperties) || {}),
    });
    if ("data" in response) {
      onClickBuyBtn(modalTypePayment);
      setTimeout(
        () => (window.location.href = response.data.data.paystation_url),
        1000,
      );
    }
  };

  return (
    <ModalFullScreenTemplate isOpen={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <img
            src={product?.image_url}
            className={styles.cardImage}
            alt="card"
          />
        </div>
        <div className={styles.description}>
          {translate("$WEB_BUY_EVENT_HEADER")}
        </div>
        <div className={styles.getWrapper}>
          <span className={styles.get}>{translate("$WEB_SALE_GET")}</span>
          <img src={hardIcon} alt="hardicon" className={styles.hardIcon} />
          <span className={styles.price}>{product?.name}</span>
        </div>
        <div className={styles.actions}>
          <Alert type="warning" withoutIcon>
            {translate("$WEB_BUY_EVENT_INFO")}
          </Alert>
          <div className={styles.buttons}>
            <Button
              className={styles.btnWithountBonus}
              onClick={handleSubmitForm}
            >
              {translate("$WEB_BUY_EVENT_DEPOSIT_BTN")}
            </Button>
            <Button className={styles.btnSelect} onClick={handleBackPlayer}>
              {translate("$WEB_BUY_EVENT_CHOICE_BTN")}
            </Button>
          </div>
        </div>
      </div>
    </ModalFullScreenTemplate>
  );
};
