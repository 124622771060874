import { createSlice } from "@reduxjs/toolkit";

type State = {
  isCookieBlock: boolean;
};

const initialState: State = {
  isCookieBlock: true,
};

export const cookieSlice = createSlice({
  name: "cookie",
  initialState,
  reducers: {
    setCookieBlock: (state, { payload }) => {
      state.isCookieBlock = payload;
    },
  },
});

export const { setCookieBlock } = cookieSlice.actions;

export default cookieSlice.reducer;
