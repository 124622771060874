import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as ExitIcon } from "../../assets/images/icons/exit-btn.svg";
import { logOut } from "../../features/auth/authSlice";
import {
  setPlayer,
  setPlayersSelect,
  setSkipAddPlayer,
} from "../../features/player/playerSlice";
import { useUserQuery } from "../../services/api/apiUserSlice";
import Button from "../Button";
import Loader from "../Loader";
import styles from "./Profile.module.sass";

const Profile = ({ isAuth }: { isAuth: boolean }) => {
  const { data: userData, refetch } = useUserQuery("", {
    skip: !isAuth,
  });

  useEffect(() => {
    if (isAuth) {
      refetch();
    }
  }, [isAuth, refetch]);
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logOut());
    dispatch(setPlayer(""));
    dispatch(setPlayersSelect([]));
    dispatch(setSkipAddPlayer(false));
  }, [dispatch, logOut]);

  const { name } = userData?.data || {};

  return (
    <div className={styles.wrapper}>
      <div className={`${!name ? styles.centerLoader : ""} ${styles.user}`}>
        {!name ? <Loader size="small" /> : name}
      </div>
      <Button className={styles.btn} onClick={handleLogout}>
        <ExitIcon width={25} height={25} fill="#fff" />
      </Button>
    </div>
  );
};

export default Profile;
