/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import { ReactComponent as SupportIcon } from "../../assets/images/icons/support.svg";
import styles from "./HelpshiftWidget.module.sass";

declare global {
  interface HelpshiftWidgetConfig {
    platformId: string;
    domain: string;
    appId: string;
    widgetType: string;
  }

  interface Window {
    helpshiftWidgetConfig?: HelpshiftWidgetConfig;
    HelpshiftWidget?: any;
  }
}
const HelpshiftWidget = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  useEffect(() => {
    const PLATFORM_ID = "chillbase_platform_20240816132555627-19cf425cb360169";
    const DOMAIN = "chillbase";
    const APP_ID = "3";
    const WIDGET_TYPE = "webchat";

    window.helpshiftWidgetConfig = {
      platformId: PLATFORM_ID,
      domain: DOMAIN,
      appId: APP_ID,
      widgetType: WIDGET_TYPE,
    };

    (function (document, scriptId) {
      if (typeof window.HelpshiftWidget !== "function") {
        const i: {
          (...args: any[]): void;
          q: any[];
        } = function (...args: any[]) {
          i.q.push(args);
        };

        i.q = [];
        window.HelpshiftWidget = i;

        const scriptTag = document.createElement("script");
        scriptTag.async = true;
        scriptTag.id = scriptId;
        scriptTag.src = "https://hs-widget.helpshift.com/init.js";

        scriptTag.onload = function () {
          window.HelpshiftWidget("init");
        };

        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag?.parentNode?.insertBefore(scriptTag, firstScriptTag);
        window.HelpshiftWidget("hide");
      } else {
        window.HelpshiftWidget("update");
      }
    })(document, "hs-widget");
  }, []);

  const widgetOpenHandler = () => {
    setOpen(true);
    window.HelpshiftWidget("show");
    window.HelpshiftWidget("open");
  };
  const widgetCloseHandler = () => {
    setOpen(false);
    window.HelpshiftWidget("hide");
  };

  useEffect(() => {
    // Находим элемент с id="hs-widget-iframe"
    const widgetIframe = document.getElementById("hs-widget-iframe");

    if (widgetIframe) {
      // Создаем MutationObserver для отслеживания изменения атрибутов
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "class"
          ) {
            const currentClassList = (mutation.target as HTMLElement).classList;
            if (currentClassList.contains("hide")) {
              widgetCloseHandler();
            }
          }
        });
      });

      // Настраиваем Observer для отслеживания изменения атрибута class
      observer.observe(widgetIframe, {
        attributes: true, // Отслеживаем изменения атрибутов
      });

      // Очищаем Observer при размонтировании компонента
      return () => observer.disconnect();
    }
  }, [widgetOpenHandler]);

  return (
    <>
      {!isOpen ? (
        <button className={styles.wrapper} onClick={widgetOpenHandler}>
          <SupportIcon />
        </button>
      ) : null}
    </>
  );
};

export default HelpshiftWidget;
