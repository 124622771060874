import { createSlice } from "@reduxjs/toolkit";

type ProductState = {
  isShowPromocodeModal?: boolean;
};

const initialState: ProductState = {
  isShowPromocodeModal: false,
};

export const promocodeSlice = createSlice({
  name: "promocode",
  initialState,
  reducers: {
    showPromoModal: (state) => {
      state.isShowPromocodeModal = true;
    },
    hidePromoModal: (state) => {
      state.isShowPromocodeModal = false;
    },
  },
});

export const { hidePromoModal, showPromoModal } = promocodeSlice.actions;

export default promocodeSlice.reducer;
