import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import { appConfig } from "../../config";
import {
  AuthWithClientIdDto,
  AuthWithEmailDto,
  AuthWithEmailResponse,
  AuthWithOAuthDto,
  AuthWithOAuthResponse,
  IdentifyOAuthDto,
  IdentifyOAuthResponse,
  RTKQuery,
} from "../../types/auth";
import { getFingerprint } from "../../utils/fingerprint";

const baseQuery = fetchBaseQuery({
  baseUrl: appConfig.api_auth_url,
}) as BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
>;

export const baseQueryWithCamelize: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions = {}) => {
  const result = await baseQuery(args, api, extraOptions);

  return result;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithCamelize,
  endpoints: (build) => ({
    authByEmail: build.mutation<AuthWithEmailResponse, AuthWithEmailDto>({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body: { ...body, application_id: appConfig.application_id },
      }),
    }),
    authByClientId: build.mutation<AuthWithEmailResponse, AuthWithClientIdDto>({
      query: (body) => ({
        url: "/privacy-login",
        method: "POST",
        body: { ...body, application_id: appConfig.application_id },
      }),
    }),
    authByOAuth: build.mutation<
      RTKQuery<AuthWithOAuthResponse>,
      AuthWithOAuthDto
    >({
      query: (body) => ({
        url: "/oauth/token",
        method: "POST",
        body: {
          ...body,
          fingerprint: getFingerprint(),
          application_id: appConfig.application_id,
        },
      }),
    }),
    identifyOAuth: build.mutation<
      RTKQuery<IdentifyOAuthResponse>,
      IdentifyOAuthDto
    >({
      query: (body) => ({
        url: "/oauth/identify",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAuthByEmailMutation,
  useAuthByClientIdMutation,
  useAuthByOAuthMutation,
  useIdentifyOAuthMutation,
} = authApi;
