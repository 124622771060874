export const es = {
  $WEB_AUTHMENU_DISCRD_BTN: "Iniciar con Discord",
  $WEB_AUTHMENU_GOOGLE_BTN: "Iniciar con Google Play Games",
  $WEB_AUTHMENU_VK_BTN: "Iniciar con VKontakte",
  $WEB_AUTHMENU_FB_BTN: "Iniciar con Facebook",
  $WEB_AUTHMENU_APPLE_BTN: "Iniciar sesión con Apple",
  $WEB_AUTHMENU_EMAIL_BTN: "Iniciar con e-mail",
  $WEB_PRIVACY_ID_12: "Iniciar sesión con ClientID",
  $WEB_PRIVACY_ID_14:
    "Puedes encontrar tanto tu ClientID como tu PrivacyID en los ajustes del juego",
  $WEB_AUTHMENU_SEPARATOR: "O",
  $WEB_SIGNIN_PAGENAME: "Autorización...",
  $WEB_SIGNUP_EMAIL_HOLDER: "Ingresa tu e-mail",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "Ingresa tu contraseña",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "Recordarme",
  $WEB_SIGNUP_ENTER_BTN: "Iniciar sesión",
  $WEB_SALE_HEADER: "FAVORITO",
  $WEB_SALE_HEADER_DESCR: "El mejor set para nuevos jugadores",
  $WEB_SALE_GET: "Conseguirás:",
  $WEB_EXIT_ACCOUNT: "Cerrar sesión",
  $WEB_AUTHMENU_INFO: "Inicia sesión para acceder a las compras",
  $WEB_SALE_NOTIF:
    "Para evitar problemas a la hora de conseguir lo que compras, cierra la sesión de tu cuenta en el juego antes de hacer un depósito. ¡Gracias!",
  $CONFIRM_WEB_BUY_HEADER: "Selecciona el servidor de depósito",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "Seleccionar servidor",
  $CONFIRM_WEB_BUY_CHARACTER: "Personaje en el servidor",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "Personaje no seleccionado",
  $NOTIF_WEB_BUY_SUCCESS: "¡Pago efectuado!",
  $NOTIF_WEB_BUY_FAIL: "Algo salió mal :( El pago falló.",
  $WEB_BTN_OK: "SÍ",
  $WEB_ERROR_HEADER: "Página no encontrada :(",
  $WEB_ERROR_BACK_BTN: "Volver a la página principal",
  $WEB_PROMOCODE_BTN: "CÓDIGOS PROMOCIONALES",
  $WEB_PROMOCODE_HEADER:
    "Introduce el código promocional y selecciona el servidor",
  $WEB_PROMOCODE_HEADER_DESCR: "Introduce el código promocional",
  $WEB_PROMOCODE_ACTIVATE_BTN: "Activar",
  $NOTIF_WEB_PROMO_SUCCESS: "Tienes un código promocional, ¡ven a jugar!",
  $WEB_PROMO_FAIL_NOTIF: "Código promocional no válido, inténtalo de nuevo.",
  $WEB_PROMO_RECEIVED_NOTIF:
    "Tienes un código promocional. ¡Ven a jugar y obtén tu recompensa!",
  $WEB_PROMO_BAD_NOTIF:
    "Este grupo de códigos promocionales no se puede activar.",
  $WEB_PROMOCODE_HEADER_INFO: "Código promocional",
  $WEB_EVENT_AUTHORIZATION_NOTIF:
    "Inicia sesión y comprueba las promociones personales",
  $WEB_EVENT_ACCESS_NOTIF: "Evento de recarga ya disponible",
  $WEB_BUY_EVENT_HEADER:
    "No hay bonus por recargar la cuenta de este personaje",
  $WEB_BUY_EVENT_DESCR: "Conseguirás:",
  $WEB_BUY_EVENT_INFO:
    "Bonus de recarga disponibles para algunos de tus personajes",
  $WEB_BUY_EVENT_CHOICE_BTN: "Selecciona otro personaje",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "Recarga sin bonus",
  $WEB_BUY_EVENT_TIMER: "La promoción termina en:",
  $WEB_SUCCESS_PAYMENT:
    "Pago realizado con éxito. La información de compra se ha enviado a tu bandeja de entrada.",
  $WEB_FAILED_PAYMENT: "Operación rechazada",
  $WEB_NO_GOODS_IN_SECTION: "No hay ofertas disponibles",
  $WEB_GOOGLEPLAY: "Disponible en Google Play",
  $WEB_APPLESTORE: "Descargar en App Store",
  $WEB_EVENT_END: "El evento termina en: ",
  $WEB_STATEGOLD_HEADER1: "CONSIGUE HASTA",
  $WEB_STATEGOLD_HEADER2: "MÁS VALOR",
  $WEB_EVENT_SUBTITLE1: "Consigue ",
  $WEB_EVENT_SUBTITLE2: " State Coins ",
  $WEB_EVENT_SUBTITLE3: "ahora mismo, junto con ",
  $WEB_EVENT_SUBTITLE4: " State Coins ",
  $WEB_EVENT_SUBTITLE5: "y ",
  $WEB_EVENT_SUBTITLE6: " después de jugar",
  $WEB_EVENT_SUBTITLE7: "días adiconales",
  $WEB_EVENT_DAY: "Día",
  $WEB_PRIVACY_ID_15: "Eliminar cuenta",
  $WEB_PRIVACY_ID_17: "BORRAR MIS DATOS PERSONALES",
  $WEB_PRIVACY_ID_28:
    "A través de esta página, puedes solicitar la eliminación de tu cuenta de juego y de toda la información y datos personales asociados.",
  $WEB_PRIVACY_ID_29:
    "Puedes enviar una solicitud para eliminar tu cuenta y toda la información personal asociada siguiendo las instrucciones que se indican a continuación.",
  $WEB_PRIVACY_ID_30_1:
    "La política de privacidad de la compañía CHILLGAMING está disponible ",
  $WEB_PRIVACY_ID_30_2: "a través del enlace",
  $WEB_PRIVACY_ID_30_3:
    ". Revisa el documento antes de realizar cualquier consulta.",
  $WEB_PRIVACY_ID_31:
    "Aviso: tu solicitud eliminará permanentemente todos los datos personales y la información asociada a la(s) cuenta(s) que utilizaste para iniciar sesión en este sitio. Esto incluye la eliminación de todos los datos personales, compras dentro de la aplicación y en el sitio web, todo el progreso del juego y cualquier otro dato relacionado con el juego. Esta acción no puede deshacerse.",
  $WEB_PRIVACY_ID_32:
    "Ten en cuenta que podemos conservar algunos datos. Por ejemplo, si no podemos identificar tu cuenta por falta de los detalles de identificación que se indican a continuación. También podemos conservar la información que estamos obligados o tenemos derecho a conservar de acuerdo con las leyes aplicables.",
  $WEB_PRIVACY_ID_33:
    "Para que podamos identificar con precisión tu cuenta, introduce los datos de tu cuenta de juego en los campos correspondientes: ClientID, PrivacyID. Para introducir esta información y enviar una solicitud de eliminación de cuenta, utiliza el botón que aparece a continuación.",
  $WEB_PRIVACY_ID_34:
    "¿ESTÁS SEGURO DE QUE QUIERES ELIMINAR PERMANENTEMENTE TU CUENTA Y LOS DATOS ASOCIADOS A ELLA?",
  $WEB_PRIVACY_ID_35_1: "ATENCIÓN:",
  $WEB_PRIVACY_ID_35_2:
    "al enviar una solicitud en esta página, tu cuenta de juego y toda la información personal asociada a la(s) cuenta(s) que utilizaste para iniciar sesión en este sitio se eliminarán de forma permanente e irreversible.",
  $WEB_PRIVACY_ID_36:
    "ESTO INCLUYE: la cuenta de juego, todos los datos personales y la información asociada a la(s) cuenta(s), y todo el progreso del juego, incluidos los objetos del juego, la divisa virtual y otros datos del juego.",
  $WEB_PRIVACY_ID_37:
    "ESTA ACCIÓN ES IRREVERSIBLE. UNA VEZ FINALIZADO EL PROCESO DE ELIMINACIÓN, NO PODREMOS RECUPERAR NINGUNA INFORMACIÓN.",
  $WEB_PRIVACY_ID_38:
    "Si solicitas la eliminación de información personal y decides volver a jugar a OneState RolePlay, no podrás recuperar tu progreso en el juego, los artículos del juego y la divisa virtual.",
  $WEB_PRIVACY_ID_39:
    "TRAS LA CONFIRMACIÓN DE LA SOLICITUD, LA CUENTA DE JUEGO Y LA INFORMACIÓN PERSONAL NO PODRÁN RECUPERARSE BAJO NINGUNA CIRCUNSTANCIA.",
  $WEB_PRIVACY_ID_40: `Solo puedes cancelar la eliminación de tu cuenta de juego en un plazo de 7 días desde el envío de la solicitud de eliminación. Para cancelar la eliminación, Inicia sesión en el sitio web y haz clic en el botón "Cancelar" situado en el panel rojo de la parte superior de la página principal. Transcurrido el plazo indicado, CHILLGAMING no podrá recuperar ninguna información.`,
  $WEB_PRIVACY_ID_41:
    "Soy consciente de que estoy enviando una solicitud para eliminar mi cuenta de juego en OneState RolePlay y toda la información y datos personales asociados. ENTIENDO Y ACEPTO QUE NI CHILLGAMING NI YO PODREMOS RECUPERAR ESTA INFORMACIÓN.",
  $WEB_PRIVACY_ID_42_1: "Acepto el ",
  $WEB_PRIVACY_ID_42_2: "CLUF",
  $WEB_PRIVACY_ID_42_3: " y la ",
  $WEB_PRIVACY_ID_42_4: "Política de privacidad",
  $WEB_PRIVACY_ID_42_5: "de Chillgaming",
  $WEB_PRIVACY_ID_43: "Tu solicitud está siendo procesada.",
  $WEB_PRIVACY_ID_44_1:
    "Tu cuenta de juego y los datos personales asociados se eliminarán automáticamente en un plazo de ",
  $WEB_PRIVACY_ID_44_2: " días.",
  $WEB_PRIVACY_ID_45: `Si quieres cancelar la solicitud, puedes utilizar el botón "Cancelar" que aparece a continuación. Esta opción también está disponible en la página principal del sitio web. Inicia sesión en tu cuenta y haz clic en el botón "Cancelar" situado en el panel rojo de la parte superior de la página. Solo puedes cancelar la eliminación de la cuenta en un plazo de 7 días a partir del envío de la solicitud. Transcurrido el plazo indicado, CHILLGAMING NO PODRÁ RECUPERAR NINGUNA INFORMACIÓN.`,
  $WEB_PRIVACY_ID_47: "Ver estado",
  $WEB_PRIVACY_ID_48: "Cancelar",
  $WEB_PRIVACY_POLICY: "Política de privacidad",
  $WEB_FORMAT_DAYS: "d",
  $WEB_FORMAT_HOURS: "h",
  $WEB_FORMAT_MINUTES: "min",
  $WEB_INFO_TITLE: "*Información sobre la recarga",
  $WEB_INFO_1:
    "El juego One State RP permite realizar compras a través de un sitio web (Web-Shop).",
  $WEB_INFO_2:
    "Generalmente, las compras en el sitio web son más rentables que las compras dentro del juego.",
  $WEB_INFO_3:
    "Las State Coins y todos los artículos virtuales* comprados en este sitio son recibidos por el personaje que seleccionaste durante la compra. (Las State Coins son una moneda dentro del juego que se puede intercambiar por artículos virtuales en el juego).",
  $WEB_INFO_4:
    "La moneda del juego y los artículos virtuales comprados en el sitio web aparecerán en tu cuenta de juego inmediatamente después del pago. Recibirás una notificación al ingresar al juego.",
  $WEB_INFO_5:
    "En caso de trabajos técnicos o problemas en el servidor, la moneda del juego comprada y los artículos virtuales pueden enviarse a tu cuenta de juego con cierto retraso. Si no recibes tu compra, intenta cerrar sesión y luego volver a ingresar al juego. Si esto no ayuda, por favor contacta al Soporte, proporcionando la hora de la transacción, el tipo de compra y el nombre de la cuenta.",
  $WEB_FOOTER_TAB_POLICY: "Política de Privacidad",
  $WEB_FOOTER_TAB_TERMS: "Términos de Servicio",
  $WEB_FOOTER_TAB_DELETE_ACC: "Eliminar cuenta",
  $WEB_FOOTER_TAB_COOKIE: "Configuración de Cookies",
  $WEB_FOOTER_TAB_SUPPORT: "Soporte",
  $WEB_SOCIALS_TITLE: "Suscríbete a nosotros:",
  $WEB_SOCIALS_US: "Nuestro ",
  $WEB_SOCIALS_DESCR_1: "Tenemos varias cuentas de ",
  $WEB_SOCIALS_DESCR_2:
    " para diferentes regiones. Por favor, elige la adecuada.",
  $WEB_BANNER1_1: "Regalos por tu primera",
  $WEB_BANNER1_2: "compra en el sitio web!",
  $WEB_BANNER1_BONUS: "+ bono",
  $WEB_BANNER2_1: "¡tu regalo ya está en el juego en el servidor ",
  $WEB_BANNER2_2: "!",
};
