import { useState } from "react";
import { Link } from "react-router-dom";

import { socialsData } from "../../constants/socials";
import { useTranslate } from "../../hooks/useTranslate";
import { SocialsData } from "../../types/socials";
import SocialsModal from "../SocialsModal";
import styles from "./Socials.module.sass";

const Socials = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [socialItem, setSocialItem] = useState<SocialsData | null>(null);
  const { translate } = useTranslate();

  const handleOpen = (social: SocialsData) => () => {
    setSocialItem(social);
    setIsOpen(true);
  };
  const handleClose = () => setIsOpen(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{translate("$WEB_SOCIALS_TITLE")}</div>
      <div className={styles.icons}>
        {socialsData.map((social, idx) => (
          <div className={styles.icon} key={idx}>
            {social.links.length > 1 ? (
              <social.icon onClick={handleOpen(social)} />
            ) : (
              <Link to={social.links[0].link} target="_blank">
                <social.icon />
              </Link>
            )}
          </div>
        ))}
      </div>
      {socialItem && (
        <SocialsModal
          isOpen={isOpen}
          onClose={handleClose}
          social={socialItem}
        />
      )}
    </div>
  );
};

export default Socials;
