import * as dayJS from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

import { interopDefault } from "./interopDefault";

dayJS.extend(duration);
dayJS.extend(utc);

export const dayJsInstance = interopDefault(dayJS);
