import { FC, Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";

import CookiesBlock from "../components/CookiesBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { tracker } from "../utils/tracker";

const DefaultLayout: FC = () => {
  const { isCookieBlock } = useTypedSelector((state) => state.cookie);

  const clientId = useTypedSelector((state) => state.auth.client_id);

  useEffect(() => {
    const isFirstSession = sessionStorage.getItem("firstSession");
    if (!isFirstSession) {
      sessionStorage.setItem("firstSession", "true");
      tracker(clientId).track("web_new_session", {});
    }
  }, []);

  return (
    <>
      <Header />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      <Footer />
      {isCookieBlock && <CookiesBlock />}
    </>
  );
};

export default DefaultLayout;
