import "../utils/sentry";

import * as Sentry from "@sentry/react";

import HelpshiftWidget from "../components/HelpshiftWidget";
import { Routing } from "../pages";
import { withProviders } from "./providers";
const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
      <Routing />
      <HelpshiftWidget />
    </Sentry.ErrorBoundary>
  );
};

export default withProviders(App);
