import { FC, ReactNode } from "react";
import Modal from "react-modal";

import styles from "./ModalPromptTemplate.module.sass";
Modal.setAppElement("#wrapper");
type Props = {
  children: ReactNode;
  title: string | ReactNode;
  className?: string;
  classNameTopPosition?: string;
};
export const ModalPromptTemplate: FC<Props> = ({
  children,
  title,
  className,
  classNameTopPosition,
}) => {
  return (
    <div
      className={`modal ${className ? className : ""} ${
        classNameTopPosition ? classNameTopPosition : ""
      }`}
    >
      <div className="modal-wrapper">
        <h2 className={`modal-title ${styles.modalPromptTitle}`}>{title}</h2>
        {children}
      </div>
    </div>
  );
};
