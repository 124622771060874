import "../../pages/global.sass";

import { FC, useMemo } from "react";

import finishDayCarImg from "../../assets/images/finish-day-car.png";
import bgLeftImg from "../../assets/images/hot-offer-left-fon.png";
import manCarImg from "../../assets/images/hot-offer-man-car.png";
import bgRightImg from "../../assets/images/hot-offer-right-fon.png";
import hardIconImg from "../../assets/images/icons/hard-icon-day.png";
import { ReactComponent as TimerIcon } from "../../assets/images/icons/timer.svg";
import warningIconImg from "../../assets/images/icons/warning-icon.png";
import { useTranslate } from "../../hooks/useTranslate";
import { OfferRewardsData, ProductData } from "../../types/products";
import { dayJsInstance } from "../../utils/dayJsInstance";
import i18n from "../../utils/i18n";
import { BuyModal } from "../BuyModal";
import CountdownTimerEvent from "../CountdownTimerEvent";
import styles from "./HotOffer.module.sass";
type Props = {
  hotOffer: ProductData;
  rewards: OfferRewardsData;
};

const HotOffer: FC<Props> = ({ hotOffer, rewards }) => {
  const { data: rewardsData } = rewards || {};
  const { translate } = useTranslate();
  const isArLang = useMemo(() => i18n.language === "ar", [i18n.language]);
  const sumCoinsWithoutFirstDay = useMemo(() => {
    let sum = 0;
    rewardsData?.rewards.slice(1).map((reward) => (sum += reward[0].amount));
    return sum;
  }, [rewardsData]);

  const prizeName = rewardsData?.rewards[
    rewardsData?.rewards.length - 1
  ][1].item_name
    .split("_")
    .join(" ")
    .toUpperCase();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.timer}>
            <div className={styles.timerIcon}>
              <TimerIcon className="image" />
            </div>
            <div className={styles.timerText}>
              {translate("$WEB_EVENT_END")}
              <span className={styles.timerTextSpan}>
                {rewardsData?.end_time && (
                  <CountdownTimerEvent
                    initialTime={
                      rewardsData?.end_time
                        ? (rewardsData?.end_time ||
                            dayJsInstance().utc().unix()) -
                          dayJsInstance().utc().unix()
                        : 0
                    }
                  />
                )}
              </span>
            </div>
          </div>
          <div className={`${styles.title} ${isArLang ? "rtl" : ""}`}>
            {translate("$WEB_STATEGOLD_HEADER1")}{" "}
            <span className={styles.titleSpan}>
              <span>150%</span> <br />
              {translate("$WEB_STATEGOLD_HEADER2")}
            </span>
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.descriptionIcon}>
            <img src={warningIconImg} alt="warning icon" className="image" />
          </div>
          <div className={`${styles.descriptionText} ${isArLang ? "rtl" : ""}`}>
            {translate("$WEB_EVENT_SUBTITLE1")}
            <span className="color-highlighter">
              600 {translate("$WEB_EVENT_SUBTITLE2")}
            </span>
            {translate("$WEB_EVENT_SUBTITLE3")}
            <span className="color-highlighter">
              {sumCoinsWithoutFirstDay} {translate("$WEB_EVENT_SUBTITLE4")}
            </span>
            {translate("$WEB_EVENT_SUBTITLE5")}
            <span className="color-highlighter">{prizeName}</span>
            {translate("$WEB_EVENT_SUBTITLE6")}
          </div>
        </div>
        <div className={styles.days}>
          {rewardsData?.rewards.map((reward, index) => {
            return (
              <div className={styles.day} key={index}>
                <div className={styles.dayTitle}>
                  {translate("$WEB_EVENT_DAY")} {index + 1}
                </div>
                <div className={styles.dayIcon}>
                  <img src={hardIconImg} alt="hard icon" className="image" />
                </div>
                <div className={styles.dayValue}>X{reward[0].amount}</div>
              </div>
            );
          })}
          <div className={styles.day + " " + styles.special}>
            <div className={styles.dayTitle + " " + styles.special}>
              {translate("$WEB_EVENT_DAY")} {rewardsData?.rewards.length + 1}
            </div>
            <div className={styles.dayIcon + " " + styles.special}>
              <img
                src={finishDayCarImg}
                alt="finish day car"
                className="image"
              />
            </div>
          </div>
        </div>
        <div className={styles.manCarImg}>
          <img src={manCarImg} alt="man car" className="image" />
        </div>
        <div className={styles.bgLeftImg}>
          <img src={bgLeftImg} alt="bg left" className="image" />
        </div>
        <div className={styles.bgRightImg}>
          <img src={bgRightImg} alt="bg right" className="image" />
        </div>
      </div>
      <div className={styles.footer}>
        <BuyModal
          product={hotOffer}
          btnClass={styles.btn}
          isForHotOffer={true}
          productImage={hotOffer?.image_url || ""}
        />
      </div>
    </div>
  );
};

export default HotOffer;
