import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ProductData } from "../../types/products";

type ProductState = {
  selectedProduct?: ProductData;
  isShowBuyProductModal?: boolean;
  productType: "all" | "packs" | "event" | "coins";
};

const initialState: ProductState = {
  isShowBuyProductModal: false,
  productType: "all",
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    selectProduct: (
      state,
      {
        payload: { product },
      }: PayloadAction<{ product: ProductState["selectedProduct"] }>,
    ) => {
      state.selectedProduct = product;
      state.isShowBuyProductModal = true;
    },
    hideBuyProductModal: (state) => {
      state.selectedProduct = undefined;
      state.isShowBuyProductModal = false;
    },
    selectedProductType: (state, { payload }) => {
      state.productType = payload;
    },
  },
});

export const { selectProduct, hideBuyProductModal, selectedProductType } =
  productSlice.actions;

export default productSlice.reducer;
