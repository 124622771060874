export const ru = {
  $WEB_AUTHMENU_DISCRD_BTN: "Войти через Discord",
  $WEB_AUTHMENU_GOOGLE_BTN: "Войти через Google Play",
  $WEB_AUTHMENU_VK_BTN: "Войти через Вконтакте",
  $WEB_AUTHMENU_FB_BTN: "Войти через Facebook",
  $WEB_AUTHMENU_APPLE_BTN: "Войти через Apple",
  $WEB_AUTHMENU_EMAIL_BTN: "Войти с помощью Email",
  $WEB_PRIVACY_ID_12: "Войти через ClientID",
  $WEB_PRIVACY_ID_14: "Ты можешь найти их в Настройках игры",
  $WEB_AUTHMENU_SEPARATOR: "ИЛИ",
  $WEB_SIGNIN_PAGENAME: "Авторизация",
  $WEB_SIGNUP_EMAIL_HOLDER: "Введи свою почту",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "Введи свой пароль",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "Запомнить меня",
  $WEB_SIGNUP_ENTER_BTN: "Войти",
  $WEB_SALE_HEADER: "ХИТ ПРОДАЖ",
  $WEB_SALE_HEADER_DESCR: "Лучший набор для старта",
  $WEB_SALE_GET: "Ты получишь:",
  $WEB_EXIT_ACCOUNT: "Выйти из аккаунта",
  $WEB_SALE_NOTIF:
    "Для того, чтобы избежать проблем с начислением купленных товаров, перед пополнением аккаунта необходимо выйти из игры. Спасибо!",
  $CONFIRM_WEB_BUY_HEADER: "Выбери сервер пополнения",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "Выбери сервер",
  $CONFIRM_WEB_BUY_CHARACTER: "Персонаж на сервере",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "Персонаж не выбран",
  $NOTIF_WEB_BUY_SUCCESS: "Оплата прошла успешно!",
  $NOTIF_WEB_BUY_FAIL: "Что-то пошло не так :( Оплата не прошла.",
  $WEB_AUTHMENU_INFO: "Залогинься для доступа к покупкам",
  $WEB_BTN_OK: "OK",
  $WEB_ERROR_HEADER: "Страница не найдена :(",
  $WEB_ERROR_BACK_BTN: "Вернуться на главную",
  $WEB_PROMOCODE_BTN: "ПРОМОКОДЫ",
  $WEB_PROMOCODE_HEADER: "Введи промокод и выбери сервер",
  $WEB_PROMOCODE_HEADER_DESCR: "Введи промокод",
  $WEB_PROMOCODE_ACTIVATE_BTN: "Активировать",
  $NOTIF_WEB_PROMO_SUCCESS: "Промокод зачислен, зайди в игру!",
  $WEB_PROMO_FAIL_NOTIF: "Неверный промокод, попробуй ещё раз.",
  $WEB_PROMO_RECEIVED_NOTIF:
    "Промокод уже зачислен. Зайди в игру, чтобы забрать награду!",
  $WEB_PROMO_BAD_NOTIF: "Эту группу промокодов нельзя активировать.",
  $WEB_PROMOCODE_HEADER_INFO: "Промокод",
  $WEB_EVENT_AUTHORIZATION_NOTIF:
    "Залогинься и проверь наличие персональных акций",
  $WEB_EVENT_ACCESS_NOTIF: "Тебе доступна акция на пополнение",
  $WEB_BUY_EVENT_HEADER:
    "Пополнение счета этого персонажа произойдет без бонуса",
  $WEB_BUY_EVENT_DESCR: "Ты получишь: ",
  $WEB_BUY_EVENT_INFO:
    "Некоторым твоим персонажам доступно пополнение с бонусом",
  $WEB_BUY_EVENT_CHOICE_BTN: "Выбрать другого персонажа",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "Пополнить без бонуса",
  $WEB_BUY_EVENT_TIMER: "До конца акции осталось:",
  $WEB_SUCCESS_PAYMENT:
    "Оплата прошла успешно. Информация о покупке отправлена на ваш почтовый ящик.",
  $WEB_FAILED_PAYMENT: "Операция отклонена.",
  $WEB_NO_GOODS_IN_SECTION: "В этом разделе сейчас нет доступных товаров",
  $WEB_GOOGLEPLAY: "Доступно в Google Play",
  $WEB_APPLESTORE: "Загрузите в App Store",
  $WEB_EVENT_END: "До конца события: ",
  $WEB_STATEGOLD_HEADER1: "ПОЛУЧИ НА",
  $WEB_STATEGOLD_HEADER2: "БОЛЬШЕ ВЫГОДЫ",
  $WEB_EVENT_SUBTITLE1: "Получи ",
  $WEB_EVENT_SUBTITLE2: " Стэйт-коинов ",
  $WEB_EVENT_SUBTITLE3: "сразу, а также ",
  $WEB_EVENT_SUBTITLE4: " Стэйт-коинов ",
  $WEB_EVENT_SUBTITLE5: "и ",
  $WEB_EVENT_SUBTITLE6: " заходя в игру",
  $WEB_EVENT_SUBTITLE7: "дней",
  $WEB_EVENT_DAY: "День",
  $WEB_PRIVACY_ID_15: "Удаление аккаунта",
  $WEB_PRIVACY_ID_17: "УДАЛИТЬ МОИ ЛИЧНЫЕ ДАННЫЕ",
  $WEB_PRIVACY_ID_28:
    "Данная страница предназначена для удаления персональных данных пользователей OneState RolePlay.",
  $WEB_PRIVACY_ID_29:
    "Ты можешь оставить запрос на удаление своего аккаунта и всей персональной информации, связанной с аккаунтом, следуя инструкциям ниже.",
  $WEB_PRIVACY_ID_30_1:
    "Политика конфиденциальности компании CHILLGAMING доступна ",
  $WEB_PRIVACY_ID_30_2: "по ссылке",
  $WEB_PRIVACY_ID_30_3:
    ". Пожалуйста, ознакомься с документом, прежде чем оставлять какие-либо запросы.",
  $WEB_PRIVACY_ID_31:
    "*Пожалуйста, обрати внимание: Твой запрос окончательно удалит все персональные данные и информацию, связанную с учетной записью(-ями), используя которую(-ые), ты авторизовался на данном сайте. Это означает удаление всех персональных данных, включая покупки в приложении и на сайте, весь игровой прогресс и любые другие данные, относящиеся к игре. Это действие не может быть отменено.",
  $WEB_PRIVACY_ID_32:
    "Пожалуйста, имей в виду, что мы можем сохранить некоторые данные. Например, если мы не сможем идентифицировать твою учетную запись по причине непредставления идентификационных данных, указанных ниже. Мы также можем сохранить информацию, которую мы обязаны или имеем право сохранить в соответствии с применимым законодательством.",
  $WEB_PRIVACY_ID_33:
    "Для того, чтобы мы могли корректно идентифицировать учетную запись, тебе необходимо ввести в соответствующие поля данные о своем игровом аккаунте: ClientID, PrivacyID. Чтобы ввести эти данные и оставить заявку на удаление аккаунта, воспользуйся кнопкой ниже.",
  $WEB_PRIVACY_ID_34:
    "ТЫ УВЕРЕН(А), ЧТО ХОЧЕШЬ ОКОНЧАЛЬНО УДАЛИТЬ СВОЙ АККАУНТ И СВЯЗАННЫЕ С НИМ ДАННЫЕ?",
  $WEB_PRIVACY_ID_35_1: "ВНИМАНИЕ:",
  $WEB_PRIVACY_ID_35_2:
    "Оставляя заявку на данной странице, твой игровой аккаунт, а также вся персональная информация, связанная с учетной записью(-ями), с помощью которых ты авторизовался на данном сайте будет навсегда и безвозвратно удалена.",
  $WEB_PRIVACY_ID_36:
    "ЭТО ВКЛЮЧАЕТ: игровую учетную запись, все персональные данные и информацию, связанную с учетной записью(-ями) и весь игровой прогресс, включая все внутриигровые предметы, виртуальную валюту и другие игровые данные.",
  $WEB_PRIVACY_ID_37:
    "ДАННОЕ ДЕЙСТВИЕ НЕОБРАТИМО. ПОСЛЕ ТОГО, КАК ПРОЦЕСС УДАЛЕНИЯ БУДЕТ ЗАВЕРШЕН, МЫ НЕ СМОЖЕМ ВОССТАНОВИТЬ КАКУЮ-ЛИБО ИНФОРМАЦИЮ.",
  $WEB_PRIVACY_ID_38:
    "Если ты запросишь удаление личной информации и решишь снова играть в OneState RolePlay, ты не сможешь восстановить свой игровой прогресс, внутриигровые предметы и виртуальную валюту.",
  $WEB_PRIVACY_ID_39:
    "ПОСЛЕ ПОДТВЕРЖДЕНИЯ ЗАПРОСА ИГРОВОЙ АККАУНТ И ЛИЧНАЯ ИНФОРМАЦИЯ НЕ МОГУТ БЫТЬ ВОССТАНОВЛЕНЫ НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ. ",
  $WEB_PRIVACY_ID_40:
    "*Ты можешь отменить удаление игрового аккаунта только в течение 7 дней с момента оставления запроса на удаление. Для отмены удаления авторизируйся на сайте и нажми кнопку “Отменить”, расположенную на красной панели вверху главной страницы. После истечения соответствующего периода компания CHILLGAMING не сможет восстановить какую-либо информацию.",
  $WEB_PRIVACY_ID_41:
    "Я осознаю, что я оставляю запрос на удаление своего игрового аккаунта в OneState RolePlay и всей связанной с ним персональной информации и данных. Я ОСОЗНАЮ И СОГЛАШАЮСЬ С ТЕМ, ЧТО НИ КОМПАНИЯ CHILLGAMING, НИ Я НЕ СМОГУТ ВОССТАНОВИТЬ ЭТУ ИНФОРМАЦИЮ.",
  $WEB_PRIVACY_ID_42_1: "Я выражаю согласие с ",
  $WEB_PRIVACY_ID_42_2: "Лицензионным соглашением",
  $WEB_PRIVACY_ID_42_3: " и ",
  $WEB_PRIVACY_ID_42_4: "Политикой конфиденциальности",
  $WEB_PRIVACY_ID_42_5: " компании Chillgaming.",
  $WEB_PRIVACY_ID_43: "Запрос обрабатывается.",
  $WEB_PRIVACY_ID_44_1:
    "Игровой аккаунт и персональные данные, связанные с ним, будут удалены автоматически в течение ",
  $WEB_PRIVACY_ID_44_2: " дней.",
  $WEB_PRIVACY_ID_45:
    "Если ты хочешь отменить запрос, ты можешь воспользоваться кнопкой “Отменить”, расположенной ниже. Данная опция также присутствует на главной странице Сайта. Войди в аккаунт и нажми кнопку “Отменить”, расположенную на красной панели вверху страницы. Ты можешь отменить удаление аккаунта только в течение 7 дней с момента оставления запроса. После истечения соответствующего периода компания CHILLGAMING НЕ СМОЖЕТ ВОССТАНОВИТЬ КАКУЮ-ЛИБО ИНФОРМАЦИЮ.",
  $WEB_PRIVACY_ID_47: "Проверить статус",
  $WEB_PRIVACY_ID_48: "Отменить",
  $WEB_PRIVACY_POLICY: "Политика Конфиденциальности",
  $WEB_FORMAT_DAYS: "д",
  $WEB_FORMAT_HOURS: "ч",
  $WEB_FORMAT_MINUTES: "м",
  $WEB_COOKIE_BTN_ACCEPT: "Принять",
  $WEB_COOKIE_BTN_CANCEL: "Нет, только необходимые cookies",
  $WEB_COOKIE_BTN_RETURN: "Вернуться",
  $WEB_COOKIE_BTN_RETURN_TO_CHOICE: "Вернуться к выбору",
  $WEB_COOKIE_STEP1_TITLE:
    "Как Chillgaming LTD использует cookie и подобные технологии?",
  $WEB_COOKIE_STEP1_TEXT_1:
    "Когда ты посещаешь наш веб-сайт, с твоего предварительного согласия мы бы хотели собирать ",
  $WEB_COOKIE_STEP1_TEXT_2:
    "некоторую информацию о действиях на нашем сайте и использовать эту информацию для анализа пользовательской активности",
  $WEB_COOKIE_STEP1_TEXT_3:
    ". Это позволит обеспечить улучшенный пользовательский опыт и повысить функциональность на нашем веб-сайте. Если ты не захочешь, мы будем использовать только необходимые файлы cookie.",
  $WEB_COOKIE_STEP1_TEXT_4: "Нажимая “Принять”, ты выражаешь согласие на сбор ",
  $WEB_COOKIE_STEP1_TEXT_5:
    "твоих данных, а также их последующее использование для целей аналитики",
  $WEB_COOKIE_STEP1_TEXT_6: ". Ты  всегда можешь ",
  $WEB_COOKIE_STEP1_TEXT_7: "отказаться (или отозвать) свое согласие",
  $WEB_COOKIE_STEP1_TEXT_8: ". Больше информации в ",
  $WEB_COOKIE_STEP1_TEXT_9: "разделе файлы cookie",
  $WEB_COOKIE_STEP1_TEXT_10: " и подобные технологии в нашей ",
  $WEB_COOKIE_STEP1_TEXT_11: "политике конфиденциальности",
  $WEB_COOKIE_STEP2_TEXT:
    "Аналитические цели. Мы хотели бы собирать информацию о твоих действиях на нашем сайте запуская разработанные нами автоматические скрипты. Мы не используем сторонние инструменты отслеживания и аналитики. Мы также хотим использовать эту информацию в целях подготовки статистических отчетов о пользовательской активности на веб-сайте для анализа пользовательского опыта, улучшения функциональности и удобства пользования сайтом. Эта информация может включать метод авторизации, способ оплаты, валюту платежа, данные о приобретенных товарах, записи транзакций, использованных промокодах и уникальных идентификаторах пользователя/игрока, адрес интернет протокола, информацию об устройстве (модель, тип) и типе браузера.",
  $WEB_COOKIE_STEP3_TEXT_1:
    "Ты можешь отказаться (или отозвать) согласие в любое время через ",
  $WEB_COOKIE_STEP3_TEXT_2: "настройки cookie",
  $WEB_COOKIE_STEP3_TEXT_3:
    ", нажав кнопку “Нет, только необходимые cookies”. Отказ не повлияет на основную функциональность веб-сайта, но может ограничить некоторые его функции. Если ты откажешься (или отзовешь) свое согласие, мы будем уважать твой выбор и пользовательский опыт на нашем сайте не будет отслеживаться. Если ты не выберешь ни одну из двух опций, баннер будет появляться вновь при каждом посещении веб-сайта.",
  $WEB_TERMS_OF_SERVICE: "Условия предоставления услуг",
  $WEB_FOOTER_PRIVACY_POLICY: "Политика конфиденциальности",
  $WEB_INFO_TITLE: "*Информация о пополнении",
  $WEB_INFO_1: "Игра One State RP поддерживает покупки через сайт (Web-Shop).",
  $WEB_INFO_2: "Чаще всего покупки на сайте выгоднее, чем в игре.",
  $WEB_INFO_3:
    "Стэйт-коины и виртуальные предметы, купленные на сайте, получит персонаж, которого ты выберешь при оформлении покупки. (Стэйт-коины — это игровая валюта, её можно обменять на виртуальные предметы в игре).",
  $WEB_INFO_4:
    "Твои покупки появятся на игровом аккаунте сразу после оплаты, и ты получишь уведомление о получении при следующем входе в игру.",
  $WEB_INFO_5:
    "В редких случаях, во время проведения технических работ или неполадок на сервере, игровая валюта и предметы могут быть отправлены с задержкой. Если ты не получил покупку, попробуй выйти и снова зайти в игру. Если это не помогло, обратись в Службу Поддержки, указав время совершения покупки, её тип и название аккаунта.",
  $WEB_FOOTER_TAB_POLICY: "Политика конфиденциальности",
  $WEB_FOOTER_TAB_TERMS: "Условия предоставления услуг",
  $WEB_FOOTER_TAB_DELETE_ACC: "Удалить аккаунт",
  $WEB_FOOTER_TAB_COOKIE: "Настройки куки",
  $WEB_FOOTER_TAB_SUPPORT: "Поддержка",
  $WEB_SOCIALS_TITLE: "Подпишитесь на нас:",
  $WEB_SOCIALS_US: "Наш ",
  $WEB_SOCIALS_DESCR_1: "У нас несколько аккаунтов в ",
  $WEB_SOCIALS_DESCR_2: " для разных стран. Выбери подходящий",
  $WEB_BANNER1_1: "Подарки за первую",
  $WEB_BANNER1_2: "покупку на сайте!",
  $WEB_BANNER1_BONUS: "+ бонус",
  $WEB_BANNER2_1: "твой подарок уже в игре, на сервере ",
  $WEB_BANNER2_2: "!",
};
