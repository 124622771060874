import { QRCodeSVG } from "qrcode.react";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import appStoreImgEN from "../../assets/images/icons/appstore-en.svg";
import googleStoreImgEN from "../../assets/images/icons/google-store-en.svg";
import { storeImgs } from "../../constants/stores";
import { useRuHost } from "../../hooks/useRuHost";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { tracker } from "../../utils/tracker";
import FooterTabs from "../FooterTabs";
import Socials from "../Socials";
import styles from "./Footer.module.sass";

const Footer = () => {
  const clientId = useTypedSelector((state) => state.auth.client_id);
  const isRuHost = useRuHost();
  const { i18n } = useTranslate();

  const currentAppStoreImg = useMemo(
    () => storeImgs.appStore[i18n.language] || appStoreImgEN,
    [i18n.language],
  );
  const currentGoogleStoreImg = useMemo(
    () => storeImgs.googleStore[i18n.language] || googleStoreImgEN,
    [i18n.language],
  );

  const copyright = useMemo(() => {
    // If host is `onestateshop.ru` using new copy
    // otherwise using old copy
    if (isRuHost) {
      return (
        <p>
          Copyright © 2022-2024 Chillgaming LTD. All rights reserved. <br />
          Onestate® is registered trademark of <br /> Chillgaming LTD and may
          not be used without permission.
        </p>
      );
    }

    return (
      <p>
        Copyright © 2022-2024 Chillgaming LTD. All rights reserved. <br />
        Onestate® is registered trademark of <br /> Chillgaming LTD and may not
        be used without permission.
      </p>
    );
  }, [window.location.host]);

  const organisation = useMemo(() => {
    // If host is `onestateshop.ru` using new copy
    // otherwise using old copy
    if (isRuHost) {
      return (
        <p>
          ООО «ЧИЛЛГЕЙМИНГ» <br />
          ОГРН 1197746522797 / ИНН 9715359267
        </p>
      );
    }

    return (
      <>
        <p>
          Chillgaming LTD <br /> Elenis Loizidou 14, Off 201c, Nicosia 2042,
          Cyprus <br />
          Registered number: HE 408704
        </p>
      </>
    );
  }, [i18n.language]);

  const linkAppleStore = useMemo(() => {
    //TODO: сделать хук проверки
    if (isRuHost) {
      return "https://apps.apple.com/ua/app/one-state-rp-%D1%81%D0%B8%D0%BC%D1%83%D0%BB%D1%8F%D1%82%D0%BE%D1%80-%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8/id1597760047?l=ru";
    }

    return "https://apps.apple.com/ua/app/one-state-rp-online/id1597760047";
  }, [window.location.host]);

  const linkGoogleStore = useMemo(() => {
    if (isRuHost) {
      return "https://play.google.com/store/apps/details?id=com.Chillgaming.oneState&hl=ru";
    }

    return "https://play.google.com/store/apps/details?id=com.Chillgaming.oneState";
  }, [window.location.host]);

  const onClickStoreBtn = useCallback(
    (store: string) => () => {
      tracker(clientId).track("web_click_store_link", { params: store });
    },
    [tracker, clientId],
  );

  const qrLink = window.location.href + "app";

  return (
    <footer className={styles.wrapper}>
      <div className={styles.footer}>
        <div className={styles.tabsIconsWrapper}>
          <div className={styles.tabs}>
            <FooterTabs />
          </div>
          <div className={styles.upperIcons}>
            <div className={styles.socials}>
              <Socials />
            </div>
            <div className={styles.storesQR}>
              <div className={styles.stores}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to={linkAppleStore}
                  className={styles.store}
                  onClick={onClickStoreBtn("apple")}
                >
                  <img
                    src={currentAppStoreImg}
                    alt="app store"
                    width="auto"
                    height="100%"
                  />
                </Link>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to={linkGoogleStore}
                  className={styles.store}
                  onClick={onClickStoreBtn("google")}
                >
                  <img
                    src={currentGoogleStoreImg}
                    alt="google store"
                    width="auto"
                    height="100%"
                  />
                </Link>
              </div>
              <QRCodeSVG value={qrLink} className={styles.qr} />
            </div>
          </div>
        </div>
        <div className={styles.legalData}>
          <div className={styles.left}>{copyright}</div>
          <div className={styles.right}>{organisation}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
