export const it = {
  $WEB_AUTHMENU_DISCRD_BTN: "Accedi con Discord",
  $WEB_AUTHMENU_GOOGLE_BTN: "Accedi con Google Play Giochi",
  $WEB_AUTHMENU_VK_BTN: "Accedi con VKontakte",
  $WEB_AUTHMENU_FB_BTN: "Accedi con Facebook",
  $WEB_AUTHMENU_APPLE_BTN: "Accedi con Apple",
  $WEB_AUTHMENU_EMAIL_BTN: "Accedi con l'email",
  $WEB_PRIVACY_ID_12: "Accedi con ClientID",
  $WEB_PRIVACY_ID_14:
    "Puoi trovare sia il ClientID che il PrivacyID nelle impostazioni del gioco",
  $WEB_AUTHMENU_SEPARATOR: "OPPURE",
  $WEB_SIGNIN_PAGENAME: "Autorizzazione",
  $WEB_SIGNUP_EMAIL_HOLDER: "Inserisci il tuo indirizzo email",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "Inserisci la tua password",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "Ricordami",
  $WEB_SIGNUP_ENTER_BTN: "Accedi",
  $WEB_SALE_HEADER: "PREFERITO",
  $WEB_SALE_HEADER_DESCR: "Il miglior set per i novizi",
  $WEB_SALE_GET: "Otterrai:",
  $WEB_EXIT_ACCOUNT: "Disconnetti",
  $WEB_AUTHMENU_INFO: "Accedi per vedere gli acquisti",
  $WEB_SALE_NOTIF:
    "Per evitare problemi con ciò che acquisti, disconnetti l'account nel gioco prima di effettuare un deposito. Grazie!",
  $CONFIRM_WEB_BUY_HEADER: "Seleziona server di deposito",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "Seleziona il server",
  $CONFIRM_WEB_BUY_CHARACTER: "Personaggio sul server",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "Personaggio non selezionato",
  $NOTIF_WEB_BUY_SUCCESS: "Pagamento effettuato!",
  $NOTIF_WEB_BUY_FAIL: "Si è verificato un problema :( Pagamento non riuscito.",
  $WEB_BTN_OK: "OK",
  $WEB_ERROR_HEADER: "Pagina non trovata :(",
  $WEB_ERROR_BACK_BTN: "Torna pagina princ.",
  $WEB_PROMOCODE_BTN: "COD. PROMO",
  $WEB_PROMOCODE_HEADER: "Inserisci codice promo e seleziona server",
  $WEB_PROMOCODE_HEADER_DESCR: "Inserisci codice promo",
  $WEB_PROMOCODE_ACTIVATE_BTN: "Attiva",
  $NOTIF_WEB_PROMO_SUCCESS: "Hai un codice promo, vieni a giocare!",
  $WEB_PROMO_FAIL_NOTIF: "Codice promo non valido, riprova.",
  $WEB_PROMO_RECEIVED_NOTIF:
    "Hai un codice promo. Vieni a giocare e ricevi una ricompensa!",
  $WEB_PROMO_BAD_NOTIF:
    "Questo gruppo di codici promo non può essere attivato.",
  $WEB_PROMOCODE_HEADER_INFO: "Codice promo",
  $WEB_EVENT_AUTHORIZATION_NOTIF: "Accedi e controlla promo personali",
  $WEB_EVENT_ACCESS_NOTIF: "Evento di ricarica disponibile",
  $WEB_BUY_EVENT_HEADER:
    "Nessun bonus per la ricarica dell'account di questo personaggio",
  $WEB_BUY_EVENT_DESCR: "Ottieni:",
  $WEB_BUY_EVENT_INFO: "Bonus di ricarica disponibili per alcuni personaggi",
  $WEB_BUY_EVENT_CHOICE_BTN: "Seleziona altro personaggio",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "Ricarica senza bonus",
  $WEB_BUY_EVENT_TIMER: "Fine promozione tra:",
  $WEB_SUCCESS_PAYMENT:
    "Pagamento effettuato. Le informazioni sull'acquisto sono state inviate alla tua casella di posta.",
  $WEB_FAILED_PAYMENT: "Operazione rifiutata",
  $WEB_NO_GOODS_IN_SECTION: "Nessuna offerta disponibile",
  $WEB_GOOGLEPLAY: "Disponibile su Google Play",
  $WEB_APPLESTORE: "Scarica nell'App Store",
  $WEB_EVENT_END: "Fine evento tra:",
  $WEB_STATEGOLD_HEADER1: "OTTIENI FINO AL",
  $WEB_STATEGOLD_HEADER2: "DI VALORE IN PIÙ",
  $WEB_EVENT_SUBTITLE1: "Ottieni ",
  $WEB_EVENT_SUBTITLE2: " State Coins ",
  $WEB_EVENT_SUBTITLE3: "ora, oltre a ",
  $WEB_EVENT_SUBTITLE4: " State Coins ",
  $WEB_EVENT_SUBTITLE5: "e ",
  $WEB_EVENT_SUBTITLE6: " dopo l'accesso per altri",
  $WEB_EVENT_SUBTITLE7: "giorni",
  $WEB_EVENT_DAY: "Giorno",
  $WEB_PRIVACY_ID_15: "Cancella Account",
  $WEB_PRIVACY_ID_17: "CANCELLA I MIEI DATI PERSONALI",
  $WEB_PRIVACY_ID_28:
    "Utilizzando questa pagina puoi richiedere la cancellazione del tuo account di gioco e di tutte le informazioni e i dati personali associati.",
  $WEB_PRIVACY_ID_29:
    "Puoi inviare una richiesta per cancellare il tuo account e tutte le informazioni personali associate seguendo le istruzioni riportate di seguito.",
  $WEB_PRIVACY_ID_30_1:
    "L'Informativa sulla privacy della società CHILLGAMING è disponibile ",
  $WEB_PRIVACY_ID_30_2: "tramite il link",
  $WEB_PRIVACY_ID_30_3:
    ". Leggi il documento prima di effettuare qualsiasi richiesta.",
  $WEB_PRIVACY_ID_31:
    "Nota: la tua richiesta eliminerà permanentemente tutti i dati personali e le informazioni associate agli account utilizzati per accedere a questo sito. Ciò include la cancellazione di tutti i dati personali, degli acquisti in-app e sul sito web, di tutti i progressi di gioco e di qualsiasi altro dato relativo al gioco. Quest'azione non può essere annullata.",
  $WEB_PRIVACY_ID_32:
    "Tieni presente che potremmo conservare alcuni dati, ad esempio nel caso in cui non riuscissimo a identificare il tuo account a causa della mancanza dei dettagli identificativi forniti di seguito. Potremmo anche conservare le informazioni di cui abbiamo l'obbligo o il diritto in conformità con le leggi vigenti.",
  $WEB_PRIVACY_ID_33:
    "Per consentirci di identificare con precisione il tuo account, inserisci i dati del tuo account di gioco nei rispettivi campi: ClientID, PrivacyID. Per inserire queste informazioni e inviare una richiesta di cancellazione dell'account, utilizza il pulsante in basso.",
  $WEB_PRIVACY_ID_34:
    "CONFERMI DI VOLER CANCELLARE PERMANENTEMENTE IL TUO ACCOUNT E I DATI ASSOCIATI?",
  $WEB_PRIVACY_ID_35_1: "ATTENZIONE:",
  $WEB_PRIVACY_ID_35_2:
    "inviando una richiesta in questa pagina, il tuo account di gioco e tutte le informazioni personali associate a uno o più account utilizzati per accedere a questo sito verranno cancellati in modo permanente e irreversibile.",
  $WEB_PRIVACY_ID_36:
    "QUESTO COMPRENDE: account di gioco, tutti i dati personali e le informazioni associate a uno o più account e tutti i progressi di gioco, inclusi oggetti di gioco, valuta virtuale e altri dati di gioco.",
  $WEB_PRIVACY_ID_37:
    "QUEST'AZIONE È IRREVERSIBILE. UNA VOLTA COMPLETATA LA PROCEDURA DI CANCELLAZIONE, NON SAREMO IN GRADO DI RECUPERARE NESSUNA INFORMAZIONE.",
  $WEB_PRIVACY_ID_38:
    "Se richiedi la cancellazione delle informazioni personali e decidi di giocare nuovamente a OneState RolePlay, non sarai in grado di recuperare i tuoi progressi, gli oggetti di gioco e la valuta virtuale.",
  $WEB_PRIVACY_ID_39:
    "DOPO LA CONFERMA DELLA RICHIESTA, L'ACCOUNT DI GIOCO E LE INFORMAZIONI PERSONALI NON POTRANNO ESSERE RECUPERATE IN NESSUN CASO.",
  $WEB_PRIVACY_ID_40: `Puoi annullare la cancellazione del tuo account di gioco solo entro 7 giorni dall'invio della richiesta di cancellazione. Per annullare la cancellazione, accedi al sito web e fai clic sul pulsante "Annulla" situato nel pannello rosso nella parte superiore della pagina principale. Trascorso il periodo specificato, CHILLGAMING non sarà in grado di recuperare alcuna informazione.`,
  $WEB_PRIVACY_ID_41:
    "Sono consapevole che sto inviando la richiesta di cancellazione del mio account di gioco in OneState RolePlay e di tutte le informazioni e i dati personali associati. COMPRENDO E ACCETTO CHE NÉ CHILLGAMING NÉ IO SAREMO IN GRADO DI RECUPERARE QUESTE INFORMAZIONI.",
  $WEB_PRIVACY_ID_42_1: "Accetto ",
  $WEB_PRIVACY_ID_42_2: "l'EULA",
  $WEB_PRIVACY_ID_42_3: " e ",
  $WEB_PRIVACY_ID_42_4: "l'Informativa sulla privacy",
  $WEB_PRIVACY_ID_42_5: " di Chillgaming",
  $WEB_PRIVACY_ID_43: "La tua richiesta è in fase di elaborazione.",
  $WEB_PRIVACY_ID_44_1:
    "Il tuo account di gioco e i dati personali associati verranno cancellati automaticamente entro ",
  $WEB_PRIVACY_ID_44_2: " giorni.",
  $WEB_PRIVACY_ID_45: `Se desideri annullare la richiesta puoi utilizzare il pulsante "Annulla" qui sotto. Quest'opzione è disponibile anche nella pagina principale del sito. Accedi al tuo account e fai clic sul pulsante "Annulla" situato nel pannello rosso nella parte superiore della pagina. Puoi annullare la cancellazione dell'account solo entro 7 giorni dall'invio della richiesta. Trascorso il periodo specificato, CHILLGAMING NON POTRÀ RECUPERARE NESSUNA INFORMAZIONE.`,
  $WEB_PRIVACY_ID_47: "Visualizza lo stato",
  $WEB_PRIVACY_ID_48: "Annulla",
  $WEB_PRIVACY_POLICY: "l'Informativa sulla privacy",
  $WEB_FORMAT_DAYS: "g",
  $WEB_FORMAT_HOURS: "h",
  $WEB_FORMAT_MINUTES: "min",
  $WEB_INFO_TITLE: "*Informazioni sulla ricarica",
  $WEB_INFO_1:
    "Il gioco One State RP supporta gli acquisti tramite sito web (Web-Shop).",
  $WEB_INFO_2:
    "Gli acquisti sul sito web sono spesso più convenienti rispetto a quelli effettuati all'interno del gioco.",
  $WEB_INFO_3:
    "Le State Сoins e tutti gli articoli virtuali* acquistati su tale sito web sono consegnati al personaggio selezionato al momento dell'acquisto. (Le State Сoins sono una valuta di gioco che può essere scambiata per articoli virtuali mentre giochi).",
  $WEB_INFO_4:
    "Al pagamento, la valuta di gioco e gli articoli virtuali acquistati sul sito web compariranno immediatamente sul tuo account. Riceverai una notifica appena accederai al gioco.",
  $WEB_INFO_5:
    "In caso di interventi tecnici o problemi al server, il recapito sul tuo account di gioco della valuta di gioco e degli articoli virtuali acquistati potrebbe subire ritardi. Se non ricevi quello che hai acquistato, prova a disconnetterti e accedere da capo al gioco. Se il problema persiste, contatta l'assistenza comunicando la data e l'ora della transazione, il tipo di acquisto e il nome dell'account.",
  $WEB_FOOTER_TAB_POLICY: "Politica in materia di privacy",
  $WEB_FOOTER_TAB_TERMS: "Condizioni di servizio",
  $WEB_FOOTER_TAB_DELETE_ACC: "Elimina account",
  $WEB_FOOTER_TAB_COOKIE: "Impostazioni relative ai cookie",
  $WEB_FOOTER_TAB_SUPPORT: "SUPPORTO",
  $WEB_SOCIALS_TITLE: "Abbonati:",
  $WEB_SOCIALS_US: "Il nostro ",
  $WEB_SOCIALS_DESCR_1: "Abbiamo account ",
  $WEB_SOCIALS_DESCR_2: " separati per ciascun Paese. Seleziona quello giusto!",
  $WEB_BANNER1_1: "Omaggi per il tuo primo",
  $WEB_BANNER1_2: "acquisto sul sito web!",
  $WEB_BANNER1_BONUS: "+bonus",
  $WEB_BANNER2_1: "il tuo omaggio è già disponibile in gioco sul server ",
  $WEB_BANNER2_2: "!",
};
