import { useTypedSelector } from "../../hooks/useTypedSelector";
import { schemaPlayer } from "../../utils/schema";
import { Form } from "../Form";
import SelectPlayer from "../SelectPlayer";
import styles from "./Players.module.sass";

const Players = () => {
  const { playersSelect } = useTypedSelector((state) => state.player);
  return (
    <div className={styles.wrapper}>
      <Form schema={schemaPlayer}>
        <SelectPlayer
          name="player_id"
          isMiniVersion={true}
          players={playersSelect}
        />
      </Form>
    </div>
  );
};

export default Players;
