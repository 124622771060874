import { createSlice } from "@reduxjs/toolkit";

type PromptsState = {
  auth: {
    active: boolean;
    position?: 1 | 2;
    img?: string;
  };
};

const initialState: PromptsState = {
  auth: {
    active: false,
    position: 1,
    img: "",
  },
};

export const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {
    setAuthPromptParams: (state, { payload }) => {
      state.auth = payload;
    },
  },
});

export const { setAuthPromptParams } = promptsSlice.actions;

export default promptsSlice.reducer;
