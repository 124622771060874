import { createApi } from "@reduxjs/toolkit/query/react";

import { RTKQuery } from "../../types/auth";
const { baseQueryWithRefresh } = useRequest();

import { useRequest } from "../../hooks/useRequest";
import { User, UserPlayer } from "../../types/user";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithRefresh("user"),
  endpoints: (build) => ({
    user: build.query<RTKQuery<User>, unknown>({
      query: () => ({
        url: `/users/@me`,
      }),
    }),
    getPlayersUser: build.query<RTKQuery<UserPlayer[]>, unknown>({
      query: () => ({
        url: "/users/@me/players",
      }),
    }),
    deleteUser: build.mutation<RTKQuery<User>, unknown>({
      query: () => ({
        url: "/users/@me",
        method: "DELETE",
      }),
    }),
    cancelDeleteUser: build.mutation<RTKQuery<User>, Partial<User>>({
      query: (userData) => ({
        url: "/users/@me",
        method: "POST",
        body: userData,
      }),
    }),
  }),
});

export const {
  useUserQuery,
  useGetPlayersUserQuery,
  useDeleteUserMutation,
  useCancelDeleteUserMutation,
} = userApi;
