import { FC, SVGProps } from "react";

import { ReactComponent as AppleIcon } from "../../assets/images/icons/return-apple.svg";
import { ReactComponent as DiscordIcon } from "../../assets/images/icons/return-discrod.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/icons/return-fb.svg";
import { ReactComponent as GoogleIcon } from "../../assets/images/icons/return-google.svg";
import { ReactComponent as GooglePlayIcon } from "../../assets/images/icons/return-googleplay.svg";
import { ReactComponent as LeftIcon } from "../../assets/images/icons/return-left.svg";
import { ReactComponent as RightIcon } from "../../assets/images/icons/return-right.svg";
import { ReactComponent as VkIcon } from "../../assets/images/icons/return-vk.svg";
import { ReactComponent as StubIcon } from "../../assets/images/logo-mob.svg";
import Button from "../../components/Button";
import { useQuery } from "../../hooks/useQuery";
import styles from "./ReturnToGamePage.module.sass";

const icons = {
  vk: VkIcon,
  discord: DiscordIcon,
  appleid: AppleIcon,
  google: GoogleIcon,
  playgames: GooglePlayIcon,
  facebook: FacebookIcon,
  stub: StubIcon,
} as Record<string, FC<SVGProps<SVGSVGElement>>>;

const ReturnToGamePage = () => {
  const query = useQuery();
  const provider = query.get("provider") || "";
  const token = query.get("token") || "";

  const CurrentIcon = icons[provider] ? icons[provider] : icons["stub"];
  const currentStyle = styles[provider] ? styles[provider] : styles["stub"];

  const navigateHandler = () =>
    (window.location.href = `onestate://authorization?token=${token}`);

  return (
    <div className={`${styles.wrapper} ${currentStyle}`}>
      <div className={styles.light}></div>
      <div className={styles.box}>
        <div className={styles.icons}>
          <div className={styles.iconLeft}>
            <LeftIcon />
          </div>
          <div className={styles.iconCenter}>
            <CurrentIcon />
          </div>
          <div className={styles.iconRight}>
            <RightIcon />
          </div>
        </div>
        <Button className={styles.btn} onClick={navigateHandler}>
          Return to game
        </Button>
      </div>
    </div>
  );
};

export default ReturnToGamePage;
