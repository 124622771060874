import { FC } from "react";

import { ReactComponent as PromptIcon } from "../../assets/images/icons/prompt.svg";
import styles from "./PromptAuth.module.sass";
type Props = {
  onMouseEnterForPrompt?: () => void;
  onMouseLeaveForPrompt?: () => void;
};
const PromptAuth: FC<Props> = ({
  onMouseEnterForPrompt,
  onMouseLeaveForPrompt,
}) => {
  return (
    <div
      className={styles.wrapper}
      onMouseEnter={onMouseEnterForPrompt}
      onMouseLeave={onMouseLeaveForPrompt}
    >
      <div className={styles.icon}>
        <PromptIcon width="100%" height="100%" className={styles.prompt} />
      </div>
    </div>
  );
};
export default PromptAuth;
