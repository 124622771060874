import { FC, SVGProps, useCallback, useMemo, useRef, useState } from "react";

import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow-icon.svg";
import { ReactComponent as PtFlag } from "../../assets/images/icons/br_flag.svg";
import { ReactComponent as DeFlag } from "../../assets/images/icons/de_flag.svg";
import { ReactComponent as EnFlag } from "../../assets/images/icons/en_flag.svg";
import { ReactComponent as EsFlag } from "../../assets/images/icons/es_flag.svg";
import { ReactComponent as FrFlag } from "../../assets/images/icons/fr_flag.svg";
import { ReactComponent as ItFlag } from "../../assets/images/icons/it_flag.svg";
import { ReactComponent as JpFlag } from "../../assets/images/icons/jp_flag.svg";
import { ReactComponent as ArFlag } from "../../assets/images/icons/me_flag.svg";
import { ReactComponent as RuFlag } from "../../assets/images/icons/ru_flag.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useTranslate } from "../../hooks/useTranslate";
import Button from "../Button";
import styles from "./LangSwitcher.module.sass";

const Icons = {
  ru: RuFlag,
  pt: PtFlag,
  en: EnFlag,
  de: DeFlag,
  fr: FrFlag,
  jp: JpFlag,
  it: ItFlag,
  es: EsFlag,
  ar: ArFlag,
} as Record<string, FC<SVGProps<SVGSVGElement>>>;

const LangSwitcher = () => {
  const { i18n } = useTranslate();
  const [isOpen, setOpen] = useState<boolean>(false);

  const ref = useRef(null);

  const handleChangeLanguage = (lang: string) => () => {
    i18n.changeLanguage(lang);
    setOpen(!isOpen);
  };

  const languagesWithoutCurrent = useMemo(() => {
    return Object.keys(Icons)
      .filter((keyLang) => keyLang !== i18n.language)
      .sort();
  }, [i18n.language]);

  const CurrentLanguageIcon = Icons[i18n.language || "en"];

  const handleToggleOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div ref={ref} className={styles.wrapper}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <Button
        onClick={handleToggleOpen}
        onKeyDown={handleToggleOpen}
        className={
          `${isOpen ? styles.blur : null}` +
          " " +
          styles.switcher +
          " " +
          styles.radiusTop
        }
      >
        <CurrentLanguageIcon className={styles.langIcon} />
        <span className={styles.lang} key={i18n.language}>
          {i18n.language.toUpperCase()}
        </span>
        <ArrowIcon
          className={
            isOpen ? styles.arrowDown + " " + styles.arrow : styles.arrow
          }
          width={10}
          height="9px"
        />
      </Button>
      {isOpen && (
        <ul className={styles.list}>
          {languagesWithoutCurrent.map((langKey) => {
            const LanguageIcon = Icons[langKey];

            return (
              <Button
                key={langKey}
                onClick={handleChangeLanguage(langKey)}
                className={
                  `${isOpen ? styles.blur : null}` +
                  " " +
                  styles.switcher +
                  " " +
                  styles.radiusBottom
                }
              >
                <LanguageIcon className={styles.langIcon} />
                <span className={styles.lang}>{langKey.toUpperCase()}</span>
              </Button>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default LangSwitcher;
