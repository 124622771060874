import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import hardIcon from "../../assets/images/icons/hard_icon1.png";
import { selectProduct } from "../../features/products/productsSlice";
import { useRuHost } from "../../hooks/useRuHost";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  OfferDataResponse,
  useGetOffersQuery,
} from "../../services/api/apiOfferSlice";
import { useBuyProductMutation } from "../../services/api/apiProductsSlice";
import { useUserQuery } from "../../services/api/apiUserSlice";
import { BuyModalProps } from "../../types/modals";
import { ProductData } from "../../types/products";
import { currencyMark } from "../../utils/currency";
import { schemaPlayer } from "../../utils/schema";
import { tracker } from "../../utils/tracker";
import { Alert } from "../Alert";
import Button from "../Button";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Form } from "../Form";
import Input from "../Input";
import { ModalDoubleWindowTemplate } from "../ModalTemplate/ModalDoubleWindowTemplate";
import OfferPrice from "../OfferPrice";
import RuPayment from "../RuPayment";
import SelectPlayer from "../SelectPlayer";
import styles from "./BuyModal.module.sass";

Modal.setAppElement("#wrapper");
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Symb = (symb: any) => () => <span>{symb}</span>;

export const BuyModal: FC<BuyModalProps> = ({
  product,
  btnClass,
  offerProps,
  isForHotOffer,
  productImage,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [modalTypePayment, setModalTypePayment] = useState<
    "sbp" | "card" | null
  >(null);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id: clientId, refresh_token: refreshToken } = useTypedSelector(
    (state) => state.auth,
  );

  const { player: selectedPlayer } = useTypedSelector((state) => state.player);
  const [buyProduct] = useBuyProductMutation();
  const isRuHost = useRuHost();

  const { data: user, refetch } = useUserQuery("", {
    skip: !refreshToken,
  });

  const isRuCountry = user?.data.country === "RU";
  const [userReceiptEmail, setUserReceiptEmail] = useState<string | null>(null);
  useEffect(
    () => setUserReceiptEmail(user?.data.receipt_email || null),
    [user],
  );

  const isUserReceiptEmailValid = useMemo(() => {
    if (!userReceiptEmail) return false;
    if (!userReceiptEmail.includes("@")) return false;

    // Check if the email is valid by creating an input element
    const input = document.createElement("input");
    input.type = "email";
    input.value = userReceiptEmail;
    return input.validity.valid;
  }, [userReceiptEmail]);

  const purchaseThrough = useMemo(
    () => (isRuHost ? "yookassa" : null),
    [user, clientId],
  );

  const isYookassa = useMemo(
    () => purchaseThrough === "yookassa",
    [purchaseThrough],
  );

  const isBuyDisabled = useMemo(() => {
    if (isYookassa) {
      return !selectedPlayer || !isUserReceiptEmailValid;
    }

    return !selectedPlayer;
  }, [isYookassa, userReceiptEmail, isUserReceiptEmailValid, selectedPlayer]);

  const appendProperties = useMemo(() => {
    const properties = {} as {
      purchase_through?: string;
      receipt_email?: string | null;
    };

    if (purchaseThrough) {
      properties["purchase_through"] = purchaseThrough;
    }

    if (userReceiptEmail) {
      properties["receipt_email"] = userReceiptEmail;
    }

    return properties;
  }, [purchaseThrough, userReceiptEmail]);

  useEffect(() => {
    if (refreshToken) {
      refetch();
    }
  }, [refreshToken, refetch]);

  const {
    price: { amount, currency },
    name,
  } = product;

  const { data: offerResultData, refetch: refetchOfferData } =
    useGetOffersQuery("", {
      skip: !refreshToken,
    });

  useEffect(() => {
    if (refreshToken) {
      refetchOfferData();
    }
  }, [refreshToken, refetchOfferData]);
  const { items } = offerResultData?.data || {};
  // TODO: FIX COSTYL

  const handleSelectProduct = useCallback(
    (product: ProductData) => {
      if (!refreshToken) return navigate("/auth");
      dispatch(selectProduct({ product }));
      setOpen(true);
    },
    [refreshToken, dispatch, selectProduct, setOpen],
  );
  const CurrencyIcon =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currencyMark[currency || "RUB"] || Symb((product.price as any).symbol);

  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const onCloseConfirmModal = useCallback(
    () => setOpenConfirmModal(false),
    [setOpenConfirmModal],
  );

  const welcomeOffers = useMemo<OfferDataResponse | null>(() => {
    if (!items) {
      return null;
    }

    const foundOffer = items.find((activeOffer) =>
      activeOffer.data.packs
        ?.filter((packItem) => packItem.available > 0)
        .some((packItem) => packItem.id === product?.product_id),
    );

    return foundOffer || null;
  }, [items, selectedPlayer]);

  //TODO: перекинуть handleSubmitForm в ConfirmModal, если они одинаковые тут и там
  const handleSubmitForm = async (typePayment: "sbp" | "card" | null) => {
    const currentOffer = welcomeOffers?.server_id === selectedPlayer?.server_id;

    if (!currentOffer) {
      const response = await buyProduct({
        player_id: selectedPlayer?.player_id || "",
        product_id: product?.product_id || "",
        // locale: i18n.language,
        // country: user?.data.country || "",
        // currence: product?.price?.currency || "USD",
        client_id: clientId,
        payment_method: typePayment,
        ...appendProperties,
      });
      if ("data" in response) {
        onClickBuyBtn(typePayment);
        setTimeout(
          () => (window.location.href = response.data.data.paystation_url),
          1000,
        );
      }
    } else {
      setModalTypePayment(typePayment);
      setOpenConfirmModal(true);
    }
  };

  const btnContent = (
    <>
      <CurrencyIcon height="50%" />
      <span className={styles.btnPrice + " ml10 mob_m0"}>{amount}</span>
    </>
  );

  const onClickBuyBtn = useCallback(
    (typePayment: "sbp" | "card" | null) => {
      tracker(clientId).track("web_pre_purchase", {
        source_class_type: typePayment || null,
      });
    },
    [tracker, clientId],
  );

  return (
    <>
      <Button className={btnClass} onClick={() => handleSelectProduct(product)}>
        {btnContent}
      </Button>
      <ModalDoubleWindowTemplate
        leftTitle={
          isForHotOffer ? (
            <div className={styles.price}>
              <img src={hardIcon} alt="hard icon" className={styles.hardIcon} />
              <span className="ml5">{name}</span>
            </div>
          ) : (
            <OfferPrice offerProps={offerProps} className={styles.modalPrice} />
          )
        }
        rightTitle={translate("$CONFIRM_WEB_BUY_HEADER")}
        modalLeftImage={productImage}
        btnTitle={
          <>
            <CurrencyIcon width="0.8em" height="0.8em" />
            <span className="ml10">{amount}</span>
          </>
        }
        isOpen={isOpen}
        onClose={onClose}
        data={product}
        alertBlock={
          <Alert type="warning">{translate("$WEB_SALE_NOTIF")}</Alert>
        }
      >
        <Form schema={schemaPlayer} onSubmit={() => handleSubmitForm(null)}>
          <SelectPlayer name="player_id" />
          {isYookassa && (
            <div>
              <div className={styles.emailLabel}>Почта для получения чека:</div>
              <Input
                name="receipt_email"
                type="email"
                value={userReceiptEmail || ""}
                placeholder="Ваш email"
                defaultValue={userReceiptEmail || ""}
                onChange={(e) => setUserReceiptEmail(e.target.value)}
                errorMessage={
                  userReceiptEmail && !isUserReceiptEmailValid
                    ? "Неверный email"
                    : undefined
                }
              />
              <div className={styles.agreement} style={{ fontSize: 12 }}>
                Нажимая кнопку с суммой платежа, расположенную ниже, <br /> вы
                предоставляете{" "}
                <a
                  href="https://onestateshop.ru/static/onestateru-agreement.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  согласие на обработку персональных данных
                </a>
                . Подробнее в{" "}
                <a
                  target="_blank"
                  href="https://onestateshop.ru/static/onestateru-privacy-policy.html"
                  rel="noreferrer"
                >
                  Политике конфиденциальности
                </a>
                .
              </div>
            </div>
          )}

          {isRuCountry ? (
            <RuPayment
              currencyIcon={<CurrencyIcon />}
              disabled={isBuyDisabled}
              price={amount}
              paymentSubmit={handleSubmitForm}
            />
          ) : (
            <Button
              type="submit"
              disabled={isBuyDisabled}
              className={styles.btn}
            >
              {btnContent}
            </Button>
          )}
          <ConfirmModal
            onClickBuyBtn={onClickBuyBtn}
            product={product}
            playerId={selectedPlayer?.player_id || ""}
            isOpen={isOpenConfirmModal}
            onClose={onCloseConfirmModal}
            appendProperties={appendProperties}
            modalTypePayment={modalTypePayment}
          />
        </Form>
      </ModalDoubleWindowTemplate>
    </>
  );
};
