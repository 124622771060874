import "./global.sass";

import * as Sentry from "@sentry/react";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Layouts } from "../layouts";
import DashboardPage from "./DashboardPage";
import ReturnToGamePage from "./ReturnToGamePage";
const AuthModal = lazy(() => import("../components/AuthModal"));
const PromocodeModal = lazy(() => import("../components/PromocodeModal"));
const Loader = lazy(() => import("../components/Loader"));
const NotFoundPage = lazy(() => import("./NotFoundPage"));
const RejectPaymentPage = lazy(() => import("./RejectPaymentPage"));
const SuccessPaymentPage = lazy(() => import("./SuccessPaymentPage"));
const DeleteMyDataPage = lazy(() => import("./DeleteMyDataPage"));
const AppRedirectPage = lazy(() => import("./AppRedirectPage"));

export const Routing = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Suspense fallback={<Loader />}>
      <SentryRoutes>
        <Route path="/" element={<Layouts.DefaultLayout />}>
          <Route path="/" element={<DashboardPage />}>
            <Route path="promocode" element={<PromocodeModal />} />
            <Route path="auth/*" element={<AuthModal />} />
          </Route>
          <Route path="delete-my-data" element={<DeleteMyDataPage />}>
            <Route path="auth/*" element={<AuthModal />} />
          </Route>
        </Route>
        <Route path="return" element={<SuccessPaymentPage />} />
        <Route path="reject" element={<RejectPaymentPage />} />
        <Route path="return-to-game" element={<ReturnToGamePage />} />

        <Route path="/app" element={<AppRedirectPage />} />

        <Route path="/not-available" element={<NotFoundPage isUnavailable />} />
        <Route path="*" element={<NotFoundPage />} />
      </SentryRoutes>
    </Suspense>
  );
};
