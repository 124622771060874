import * as dayJs from "dayjs";
import { FC, useEffect, useMemo } from "react";
import { useTimer } from "use-timer";

import { useTranslate } from "../../hooks/useTranslate";
import i18n from "../../utils/i18n";

type Props = {
  initialTime: number;
};

export const CountdownTimerWelcome: FC<Props> = ({ initialTime }) => {
  const { translate } = useTranslate();
  const isArLang = useMemo(() => i18n.language === "ar", [i18n.language]);

  const { time, start, reset, pause } = useTimer({
    initialTime: initialTime,
    timerType: "DECREMENTAL",
  });

  useEffect(() => {
    start();

    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    reset();
  }, [initialTime]);

  useEffect(() => {
    if (time <= 0) pause();
  }, [time]);

  const timeDays = useMemo(
    () => dayJs.duration(time, "seconds").days(),
    [time, initialTime],
  );
  const timeHours = useMemo(
    () => dayJs.duration(time, "seconds").hours(),
    [time, initialTime],
  );
  const timeMinutes = useMemo(
    () => dayJs.duration(time, "seconds").minutes(),
    [time, initialTime],
  );

  return (
    <p className={`${isArLang ? "text-left rtl" : ""}`}>
      <>{timeDays ? `${timeDays} ${translate("$WEB_FORMAT_DAYS")} ` : ""}</>
      <>
        {timeHours
          ? `${timeHours} ${translate("$WEB_FORMAT_HOURS")} `
          : `0 ${translate("$WEB_FORMAT_HOURS")} `}
      </>
      <>
        {timeMinutes
          ? `${timeMinutes} ${translate("$WEB_FORMAT_MINUTES")}`
          : `0 ${translate("$WEB_FORMAT_MINUTES")}`}
      </>
    </p>
  );
};

export default CountdownTimerWelcome;
