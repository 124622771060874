import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import i18nInstance from "../utils/i18n";
import { resources } from "../utils/lang";

export type TranslateKeys = keyof (typeof resources)["en"]["translation"];

export const useTranslate = () => {
  const { t, i18n } = useTranslation("translation", { i18n: i18nInstance });

  const translate = useCallback(
    (key: keyof (typeof resources)["en"]["translation"]) => {
      return t(key as unknown as string);
    },
    [],
  );

  return { translate, i18n };
};
