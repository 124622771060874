export const getDeviceType = () => {
  const ua = navigator.userAgent;

  if (
    /tablet|ipad|playbook|silk|kindle|nexus 7|nexus 10|pixel c/i.test(ua) ||
    (navigator.userAgent.indexOf("Mac") > -1 && "ontouchend" in document)
  ) {
    return "tablet";
  }

  if (
    /Mobile|Android|iP(ad|od|hone)|Opera Mini|IEMobile|Silk|BlackBerry|PlayBook|BB10|Windows Phone|ZuneWP7/i.test(
      ua,
    )
  ) {
    return "mobile";
  }

  return "desktop";
};
