import { yupResolver } from "@hookform/resolvers/yup";
import { once } from "lodash";
import {
  createContext,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useContext,
} from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { AnyObjectSchema } from "yup";

import styles from "./Form.module.sass";
type Schema = {
  name: string;
};

type Props<T> = {
  schema: AnyObjectSchema;
  onSubmit?: (values: T) => void;
  children: ReactNode;
  autoComplete?: HTMLFormElement["autocomplete"];
};

export type ValidationProviderProps<T> = Partial<Pick<Props<T>, "schema">>;

const createValidationContext = once(<T,>() =>
  createContext<ValidationProviderProps<T>>({}),
);

export const useValidationContext = <T,>() =>
  useContext(createValidationContext<T>());

const FormWithoutRef = <T extends FieldValues>(
  { schema, onSubmit, children }: Props<T>,
  ref?: Ref<HTMLFormElement>,
): ReactElement => {
  const methods = useForm<T>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const ValidationContext = createValidationContext<Schema>();

  const { handleSubmit } = methods;

  const handleSubmitWithPrevent = (e: React.BaseSyntheticEvent<object>) => {
    onSubmit &&
      handleSubmit(onSubmit)(e).catch((e) => {
        console.log(e);
        console.log("error");
      });
  };

  return (
    // TODO: подумать как расширить тип FormProviderProps и прокинуть туда тип схемы
    <FormProvider {...methods}>
      <ValidationContext.Provider value={{ schema }}>
        <form
          ref={ref}
          className={styles.form}
          onSubmit={handleSubmitWithPrevent}
        >
          {children}
        </form>
      </ValidationContext.Provider>
    </FormProvider>
  );
};

const Form = forwardRef(FormWithoutRef) as <T extends FieldValues>(
  p: Props<T> & { ref?: Ref<HTMLFormElement> },
) => ReactElement;

export { Form };
