export const fr = {
  $WEB_AUTHMENU_DISCRD_BTN: "Connexion avec Discord",
  $WEB_AUTHMENU_GOOGLE_BTN: "Connexion avec Google Play Games",
  $WEB_AUTHMENU_VK_BTN: "Connexion avec VKontakte",
  $WEB_AUTHMENU_FB_BTN: "Connexion avec Facebook",
  $WEB_AUTHMENU_APPLE_BTN: "Se connecter avec Apple",
  $WEB_AUTHMENU_EMAIL_BTN: "Connexion avec un e-mail",
  $WEB_PRIVACY_ID_12: "Connexion avec un ClientID",
  $WEB_PRIVACY_ID_14:
    "Vous trouverez votre ClientID et votre PrivacyID dans les paramètres du jeu.",
  $WEB_AUTHMENU_SEPARATOR: "OU",
  $WEB_SIGNIN_PAGENAME: "Autorisation",
  $WEB_SIGNUP_EMAIL_HOLDER: "Saisissez votre e-mail",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "Saisissez votre mot de passe",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "Se souvenir de moi",
  $WEB_SIGNUP_ENTER_BTN: "Connexion",
  $WEB_SALE_HEADER: "COUP DE CŒUR",
  $WEB_SALE_HEADER_DESCR: "Le préféré des nouveaux !",
  $WEB_SALE_GET: "Vous obtiendrez :",
  $WEB_EXIT_ACCOUNT: "Déconnexion",
  $WEB_SALE_NOTIF:
    "Pour éviter les problèmes, déconnectez-vous dans le jeu avant de faire un dépôt. Merci !",
  $CONFIRM_WEB_BUY_HEADER: "Sélectionnez un serveur de dépôt",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "Sélectionner un serveur",
  $CONFIRM_WEB_BUY_CHARACTER: "Personnage sur le serveur",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "Aucun personnage sélectionné",
  $NOTIF_WEB_BUY_SUCCESS: "Paiement effectué !",
  $NOTIF_WEB_BUY_FAIL: "Une erreur s'est produite :( Le paiement a échoué.",
  $WEB_BTN_OK: "OK",
  $WEB_ERROR_HEADER: "Page introuvable :(",
  $WEB_ERROR_BACK_BTN: "Retour",
  $WEB_PROMOCODE_BTN: "CODES PROMO",
  $WEB_PROMOCODE_HEADER: "Saisir le code promo et sélectionner le serveur",
  $WEB_PROMOCODE_HEADER_DESCR: "Saisir le code promo",
  $WEB_PROMOCODE_ACTIVATE_BTN: "Activer",
  $NOTIF_WEB_PROMO_SUCCESS: "Vous avez reçu un code promo, venez jouer !",
  $WEB_PROMO_FAIL_NOTIF: "Code promo invalide, veuillez réessayer.",
  $WEB_PROMO_RECEIVED_NOTIF:
    "Vous avez reçu un code promo. Venez jouer pour récupérer votre récompense !",
  $WEB_PROMO_BAD_NOTIF: "Impossible d'activer ces codes promo.",
  $WEB_PROMOCODE_HEADER_INFO: "Code promo",

  $WEB_EVENT_AUTHORIZATION_NOTIF: "Connectez-vous pour voir les promos",
  $WEB_EVENT_ACCESS_NOTIF: "Événement de recharge disponible",
  $WEB_BUY_EVENT_HEADER:
    "Aucun bonus si vous rechargez le compte de ce personnage",
  $WEB_BUY_EVENT_DESCR: "Vous obtenez :",
  $WEB_BUY_EVENT_INFO:
    "Bonus de recharge disponibles pour certains personnages",
  $WEB_BUY_EVENT_CHOICE_BTN: "Sélectionnez un autre personnage",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "Recharger sans bonus",
  $WEB_BUY_EVENT_TIMER: "Fin de la promo dans :",
  $WEB_AUTHMENU_INFO: "Connectez-vous pour accéder aux achats",
  $WEB_STATEGOLD_HEADER1: "VALEUR AJOUTÉE :",
  $WEB_STATEGOLD_HEADER2: "MAX",
  $WEB_SUCCESS_PAYMENT:
    "Paiement réussi. Les informations d'achat ont été envoyées dans votre boîte de réception",
  $WEB_FAILED_PAYMENT: "Opération rejetée",
  $WEB_NO_GOODS_IN_SECTION: "Aucune offre disponible",
  $WEB_GOOGLEPLAY: "Disponible sur Google Play",
  $WEB_APPLESTORE: "Téléchargez sur l'App Store",
  $WEB_EVENT_END: "Fin de l'événement : ",
  $WEB_EVENT_SUBTITLE1: "Recevez ",
  $WEB_EVENT_SUBTITLE2: " State Coins ",
  $WEB_EVENT_SUBTITLE3: "maintenant, puis ",
  $WEB_EVENT_SUBTITLE4: " State Coins ",
  $WEB_EVENT_SUBTITLE5: "et ",
  $WEB_EVENT_SUBTITLE6: " si vous vous connectez",
  $WEB_EVENT_SUBTITLE7: "jours de plus",
  $WEB_EVENT_DAY: "Jour",
  $WEB_PRIVACY_ID_15: "Supprimer Le Compte",
  $WEB_PRIVACY_ID_17: "SUPPRIMER MES DONNÉES PERSONNELLES",
  $WEB_PRIVACY_ID_28:
    "Sur cette page, vous pouvez demander la suppression de votre compte et de toutes les données personnelles associées.",
  $WEB_PRIVACY_ID_29:
    "Suivez les instructions ci-dessous pour demander la suppression de votre compte et des données personnelles associées.",
  $WEB_PRIVACY_ID_30_1:
    "La politique de confidentialité de CHILLGAMING est accessible ",
  $WEB_PRIVACY_ID_30_2: "depuis le lien",
  $WEB_PRIVACY_ID_30_3: ". Consultez-la avant de faire une demande.",
  $WEB_PRIVACY_ID_31:
    "Remarque : cette demande supprimera définitivement toutes les données personnelles associées au(x) compte(s) utilisé(s) pour vous connecter au site. Par conséquent, vos données personnelles, vos achats intégrés, votre progression et toutes vos autres données de jeu seront supprimés. Cette action est irréversible.",
  $WEB_PRIVACY_ID_32:
    "Sachez que nous pourrions conserver certaines données. Par exemple, si nous n'arrivons pas à identifier votre compte, car vous n'avez pas fourni suffisamment de détails. Nous pourrions également conserver des informations conformément aux lois en vigueur.",
  $WEB_PRIVACY_ID_33:
    "Aidez-nous à identifier correctement votre compte ! Veuillez saisir vos données de compte dans les champs respectifs : ClientID et PrivacyID. Utilisez le bouton ci-dessous pour saisir les informations requises et envoyer une demande de suppression de compte.",
  $WEB_PRIVACY_ID_34:
    "VOULEZ-VOUS SUPPRIMER DÉFINITIVEMENT VOTRE COMPTE AINSI QUE LES DONNÉES ASSOCIÉES ?",
  $WEB_PRIVACY_ID_35_1: "Attention :",
  $WEB_PRIVACY_ID_35_2:
    "si vous envoyez une demande depuis cette page, votre compte et toutes les données personnelles associées au(x) compte(s) utilisé(s) pour vous connecter au site seront définitivement et irréversiblement supprimés.",
  $WEB_PRIVACY_ID_36:
    "Ce qui comprend : le compte ainsi que les données personnelles associées, la progression (les objets, la monnaie virtuelle...) et toutes les autres données de jeu.",
  $WEB_PRIVACY_ID_37:
    "CETTE ACTION EST IRRÉVERSIBLE. À LA FIN DU PROCESSUS, VOUS NE POURREZ RÉCUPÉRER AUCUNE INFORMATION.",
  $WEB_PRIVACY_ID_38:
    "Si vous faites une demande de suppression de compte et que vous rejouez un jour à OneState, vous ne pourrez récupérer ni votre progression, ni vos objets, ni votre monnaie en jeu.",
  $WEB_PRIVACY_ID_39:
    "APRÈS CONFIRMATION DE LA DEMANDE, VOUS NE POURREZ EN AUCUN CAS RÉCUPÉRER LE COMPTE ET LES DONNÉES PERSONNELLES.",
  $WEB_PRIVACY_ID_40:
    "Vous pouvez annuler la suppression de votre compte seulement dans les sept jours qui suivent la demande. Pour ce faire, connectez-vous au site et cliquez sur Annuler, en haut de la page d'accueil. Une fois les sept jours écoulés, CHILLGAMING ne pourra récupérer aucune information.",
  $WEB_PRIVACY_ID_41:
    "J'ai conscience que je demande à supprimer mon compte OneState et toutes les données personnelles associées. JE COMPRENDS ET J'ACCEPTE LE FAIT QU'IL ME SERA IMPOSSIBLE DE RÉCUPÉRER CES INFORMATIONS, ET QU'IL EN IRA DE MÊME POUR CHILLGAMING.",
  $WEB_PRIVACY_ID_42_1: "J'accepte le ",
  $WEB_PRIVACY_ID_42_2: "CLUF",
  $WEB_PRIVACY_ID_42_3: " et ",
  $WEB_PRIVACY_ID_42_4: "la politique de confidentialité",
  $WEB_PRIVACY_ID_42_5: " de Chillgaming",
  $WEB_PRIVACY_ID_43: "Votre demande est en cours de traitement.",
  $WEB_PRIVACY_ID_44_1:
    "Votre compte ainsi que les données personnelles associées seront automatiquement supprimés d'ici ",
  $WEB_PRIVACY_ID_44_2: " jours.",
  $WEB_PRIVACY_ID_45:
    "Si vous souhaitez annuler votre demande, utilisez le bouton Annuler ci-dessous. Cette option est également disponible sur la page d'accueil du site. Connectez-vous à votre compte et cliquez sur Annuler, en haut de la page. Vous pouvez annuler la suppression de votre compte dans les sept jours qui suivent la demande. Une fois ces sept jours écoulés, CHILLGAMING NE POURRA RÉCUPÉRER AUCUNE INFORMATION.",
  $WEB_PRIVACY_ID_47: "Voir le statut",
  $WEB_PRIVACY_ID_48: "Annuler",
  $WEB_PRIVACY_POLICY: "la politique de confidentialité",
  $WEB_FORMAT_DAYS: "j et",
  $WEB_FORMAT_HOURS: "h et",
  $WEB_FORMAT_MINUTES: "min",
  $WEB_INFO_TITLE: "*Informations sur la recharge",
  $WEB_INFO_1:
    "Dans le jeu One State RP, il est possible de faire des achats via un site internet (boutique en ligne).",
  $WEB_INFO_2:
    "La plupart du temps, ces achats sont plus rentables que les achats en jeu.",
  $WEB_INFO_3:
    "Les State Coins et objets virtuels* achetés sur le site sont octroyés au personnage sélectionné lors de l'achat. (Les State Coins sont une monnaie intégrée qui s'échange contre des objets virtuels dans le jeu.)",
  $WEB_INFO_4:
    "La monnaie intégrée et les objets virtuels achetés sur le site apparaîtront sur votre compte directement après le paiement. Une notification apparaîtra alors dès que vous ouvrirez le jeu.",
  $WEB_INFO_5:
    "En cas de problème technique ou d'incident sur le serveur, vous pourriez recevoir votre monnaie intégrée et vos objets virtuels un peu plus tard. Si vous ne voyez pas vos achats sur votre compte, déconnectez-vous avant de vous reconnecter. Si le problème persiste, contactez l'assistance et communiquez-lui la date et l'heure de la transaction, le type d'achat et le nom du compte.",
  $WEB_FOOTER_TAB_POLICY: "Politique de confidentialité",
  $WEB_FOOTER_TAB_TERMS: "Conditions de service",
  $WEB_FOOTER_TAB_DELETE_ACC: "Supprimer le compte",
  $WEB_FOOTER_TAB_COOKIE: "Paramètres des cookies",
  $WEB_FOOTER_TAB_SUPPORT: "Assistance",
  $WEB_SOCIALS_TITLE: "Abonnez-vous :",
  $WEB_SOCIALS_US: "Notre ",
  $WEB_SOCIALS_DESCR_1: "Nous avons plusieurs comptes ",
  $WEB_SOCIALS_DESCR_2:
    " selon le pays. Veuillez choisir celui qui vous convient.",
  $WEB_BANNER1_1: "Des cadeaux pour votre",
  $WEB_BANNER1_2: "premier achat sur le site !",
  $WEB_BANNER1_BONUS: "+ bonus",
  $WEB_BANNER2_1: "votre cadeau vous attend sur le serveur ",
  $WEB_BANNER2_2: " du jeu !",
};
