import { FC } from "react";

import { useTranslate } from "../../hooks/useTranslate";
import styles from "./SelectInfo.module.sass";

type Props = {
  value?: string;
};

export const SelectInfo: FC<Props> = ({ value }) => {
  const { translate } = useTranslate();

  return (
    <div className={`${value ? styles.isSelected : ""} ${styles.wrapper}`}>
      <div className={styles.info}>
        {value ? value : translate("$CONFIRM_WEB_BUY_CHARACTER_DESCR")}
      </div>
    </div>
  );
};
