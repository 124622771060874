import * as dayJs from "dayjs";
import { FC, useEffect, useMemo } from "react";
import { useTimer } from "use-timer";

import timerIcon from "../../assets/images/icons/timer_icon.svg";
import styles from "./CountdownTimerWelcome.module.sass";
type Props = {
  initialTime: number;
};

export const CountdownTimerWelcome: FC<Props> = ({ initialTime }) => {
  const { time, start, reset, pause } = useTimer({
    initialTime: initialTime,
    timerType: "DECREMENTAL",
  });

  useEffect(() => {
    start();

    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (time <= 0) pause();
  }, [time]);

  const timeFormat = useMemo(
    () => dayJs.duration(time, "seconds").format("HH:mm"),
    [time],
  );

  return (
    <div className={styles.wrapper}>
      <img src={timerIcon} alt="timer icon" className={styles.icon} />
      <span className={styles.label}>{timeFormat}</span>
    </div>
  );
};

export default CountdownTimerWelcome;
