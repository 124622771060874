import { FC } from "react";

import { OfferDataResponse } from "../../services/api/apiOfferSlice";
import { ProductData } from "../../types/products";
import ProductListItem from "../ProductListItem";
import styles from "./ProductsList.module.sass";

type Props = {
  products: ProductData[];
  getWelcomeOfferOfProduct?: (
    productId: string | undefined,
  ) => OfferDataResponse | null | undefined;
};
const ProductsList: FC<Props> = ({ products, getWelcomeOfferOfProduct }) => {
  return (
    <div className={styles.wrapper}>
      {[...products].reverse()?.map((product) => {
        const welcomeOffer = getWelcomeOfferOfProduct
          ? getWelcomeOfferOfProduct(product.product_id)
          : null;
        return <ProductListItem key={product.name} product={product} />;
      })}
    </div>
  );
};

export default ProductsList;
