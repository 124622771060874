import { Link } from "react-router-dom";

import { flags } from "../../constants/flags";
import { useTranslate } from "../../hooks/useTranslate";
import { SocialsData } from "../../types/socials";
import { ModalSingleTemplate } from "../ModalTemplate";
import styles from "./SocialsModal.module.sass";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  social: SocialsData;
}
const SocialsModal = ({ isOpen, onClose, social }: Props) => {
  const { icon: Icon, label, links } = social;
  const { translate } = useTranslate();

  return (
    <ModalSingleTemplate
      title={
        <>
          <Icon className={styles.titleIcon} /> {translate("$WEB_SOCIALS_US")}{" "}
          {label}
        </>
      }
      onClose={onClose}
      isOpen={isOpen}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <p className={styles.text}>
            {translate("$WEB_SOCIALS_DESCR_1")} {label}{" "}
            {translate("$WEB_SOCIALS_DESCR_2")}
          </p>
          <div className={styles.socials}>
            {links.map(({ link, country, language }, idx) => (
              <Link
                to={link}
                className={styles.social}
                target="_blank"
                key={idx}
              >
                <img
                  src={
                    flags[
                      country
                        .toLowerCase()
                        .split(" ")
                        .join("_") as keyof typeof flags
                    ]
                  }
                  alt={`flag ${country}`}
                  className={styles.flag}
                />
                <p className={styles.name}>
                  <span className={styles.country}>{country}</span>
                  <span className={styles.language}>{language}</span>
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </ModalSingleTemplate>
  );
};

export default SocialsModal;
