import { createSlice } from "@reduxjs/toolkit";

type TabsState = {
  delAccPageNumTab: number;
};

const initialState: TabsState = {
  delAccPageNumTab: 1,
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setDelAccPageNumTab: (state, { payload }) => {
      state.delAccPageNumTab = payload;
    },
  },
});

export const { setDelAccPageNumTab } = tabsSlice.actions;

export default tabsSlice.reducer;
