import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "../../types/user";

type UserState = {
  user: User | null;
};

const initialState: UserState = {
  user: null,
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload: { user } }: PayloadAction<UserState>) => {
      state.user = user;
    },
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;
