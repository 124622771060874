import { createApi } from "@reduxjs/toolkit/query/react";

import { useRequest } from "../../hooks/useRequest";
import { useRuHost } from "../../hooks/useRuHost";
import { RTKQuery } from "../../types/auth";
import {
  BuyDataDto,
  BuyDataResponse,
  OfferRewardsData,
  ProductData,
} from "../../types/products";

const { baseQueryWithRefresh } = useRequest();
const isRuHost = useRuHost();

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: baseQueryWithRefresh(),
  endpoints: (build) => ({
    getProducts: build.query<RTKQuery<ProductData[]>, { language: string }>({
      query: ({ language }) => ({
        url: isRuHost
          ? `/rustore/items?language=${language}`
          : `/xsolla/items?language=${language}`,
      }),

      transformResponse: (response: RTKQuery<ProductData[]>) => {
        const items = response.data
          .filter((item) =>
            item.groups?.some((group) => group.external_id === "store_34"),
          )
          .filter((item) => item.product_id?.startsWith("shop_hard_"))
          .sort((a, b) => +a.price.amount - +b.price.amount);

        const data = { ...response, data: items };

        return data;
      },
    }),
    getPacks: build.query<RTKQuery<ProductData[]>, { language: string }>({
      query: ({ language }) => ({
        url: isRuHost
          ? `/rustore/web_items?language=${language}`
          : `/web_items?language=${language}`,
      }),

      // transformResponse: (response: RTKQuery<ProductData[]>) => {
      //   const items = response.data
      //     .filter((item) =>
      //       item.groups?.some((group) => group.external_id === "store_34"),
      //     )
      //     .filter((item) => item.product_id?.startsWith("shop_hard_"))
      //     .sort((a, b) => +a.price.amount - +b.price.amount);

      //   const data = { ...response, data: items };

      //   return data;
      // },
    }),
    getHotOffer: build.query<RTKQuery<ProductData>, { language: string }>({
      query: ({ language }) => ({
        url: isRuHost
          ? `/rustore/items?language=${language}`
          : `/xsolla/items?language=${language}`,
      }),
      transformResponse: (response: RTKQuery<ProductData[]>) => {
        const item = response.data.find(
          (itemData) => itemData.product_id === "os_coins_calendar_036",
        );

        const data: RTKQuery<ProductData> = {
          ...response,
          data: item || ({} as ProductData),
        };

        return data;
      },
    }),
    getHotOfferRewards: build.query<
      RTKQuery<OfferRewardsData[]>,
      { language: string }
    >({
      query: () => ({
        url: `/xsolla/rewards`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    buyProduct: build.mutation<RTKQuery<BuyDataResponse>, BuyDataDto>({
      query: (body) => ({
        url: `/ecommerce/invoice`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetHotOfferQuery,
  useGetHotOfferRewardsQuery,
  useGetProductsQuery,
  useGetPacksQuery,
  useBuyProductMutation,
} = productsApi;
