import "../global.sass";

import { lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import AuthBlock from "../../components/AuthBlock";
import BannerFirstBuy from "../../components/BannerFirstBuy";
import FilterButtons from "../../components/FilterButtons";
import InfoBlock from "../../components/InfoBlock";
import ProductsBlock from "../../components/ProductsBlock";
import WelcomeBlock from "../../components/WelcomeBlock/WelcomeBlock";
import { logOut } from "../../features/auth/authSlice";
import { useDaysAmountUntillDelAcc } from "../../hooks/useDaysAmountUntillDelAcc";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useUserQuery } from "../../services/api/apiUserSlice";
import styles from "./DashboardPage.module.sass";
const NoticeAboutDelAcc = lazy(
  () => import("../../components/NoticeAboutDelAcc"),
);

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { refresh_token } = useTypedSelector((state) => state.auth);
  const { data: userData, refetch: refetchUserQuery } = useUserQuery("");
  const { deletion_scheduled_at: deletionDate, is_deleted: isDeletedAcc } =
    userData?.data || {};

  const [daysAmount, setDaysAmount] = useState<number | null>(null);
  useEffect(() => {
    if (deletionDate) {
      setDaysAmount(useDaysAmountUntillDelAcc(deletionDate));
    }
  }, [deletionDate]);

  const calcTimeUntilDel = () => {
    if (deletionDate) {
      const targetDate = new Date(deletionDate);
      const currentDate = new Date();
      const secUntilDel = Math.floor(
        targetDate.getTime() - currentDate.getTime(),
      );
      return secUntilDel;
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (deletionDate) {
      timer = setTimeout(() => {
        refetchUserQuery();
      }, calcTimeUntilDel());
    }

    return () => {
      clearTimeout(timer);
    };
  }, [deletionDate]);

  useEffect(() => {
    if (isDeletedAcc) dispatch(logOut());
  }, [isDeletedAcc]);

  const navigateHandler = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );

  const isAuth = useMemo(() => {
    return !!refresh_token;
  }, [refresh_token]);

  return (
    <main className={styles.wrapper}>
      <WelcomeBlock />
      <div className="container">
        <AuthBlock
          navigateHandler={() => navigateHandler("./auth")}
          isAuth={isAuth}
        />
        {isAuth && deletionDate && (
          <NoticeAboutDelAcc
            refetchUserQuery={refetchUserQuery}
            daysAmount={daysAmount}
          />
        )}
        <BannerFirstBuy isAuth={isAuth} />
        <FilterButtons
          navigateHandler={() => navigateHandler("./promocode")}
          isAuth={isAuth}
        />
        <ProductsBlock isAuth={isAuth} />
        <InfoBlock />
      </div>

      <Outlet />
    </main>
  );
};

export default DashboardPage;
