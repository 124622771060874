export const jp = {
  $WEB_AUTHMENU_DISCRD_BTN: "Discordでログイン",
  $WEB_AUTHMENU_GOOGLE_BTN: "Google Play Gamesでログイン",
  $WEB_AUTHMENU_VK_BTN: "VKontakteでログイン",
  $WEB_AUTHMENU_FB_BTN: "Facebookでログイン",
  $WEB_AUTHMENU_APPLE_BTN: "Appleでログイン",
  $WEB_AUTHMENU_EMAIL_BTN: "メールアドレスでログイン",
  $WEB_PRIVACY_ID_12: "ClientIDでログイン",
  $WEB_PRIVACY_ID_14:
    "ClientIDとPrivacyIDは両方ともゲーム設定内で見ることができます",
  $WEB_AUTHMENU_SEPARATOR: "または",
  $WEB_SIGNIN_PAGENAME: "認証",
  $WEB_SIGNUP_EMAIL_HOLDER: "メールアドレスを入力",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "パスワードを入力",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "次回から自動ログイン",
  $WEB_SIGNUP_ENTER_BTN: "ログイン",
  $WEB_SALE_HEADER: "プレイヤーの一番人気",
  $WEB_SALE_HEADER_DESCR: "新規プレイヤーに最適のセット",
  $WEB_SALE_GET: "獲得：",
  $WEB_EXIT_ACCOUNT: "ログアウト",
  $WEB_SALE_NOTIF:
    "購入内容の取得に関する問題を避けるため、入金する前にゲームのアカウントからログアウトしてください。ありがとうございます！",
  $CONFIRM_WEB_BUY_HEADER: "入金サーバーを選択",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "サーバーを選択",
  $CONFIRM_WEB_BUY_CHARACTER: "サーバーのキャラクター",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "キャラクターが選択されていません",
  $NOTIF_WEB_BUY_SUCCESS: "支払いに成功しました！",
  $NOTIF_WEB_BUY_FAIL: "問題が発生し、支払いに失敗しました。",
  $WEB_BTN_OK: "OK",
  $WEB_ERROR_HEADER: "ページが見つかりませんでした",
  $WEB_ERROR_BACK_BTN: "メインページに戻る",
  $WEB_PROMOCODE_BTN: "プロモコード",
  $WEB_PROMOCODE_HEADER: "プロモコードを入力してサーバーを選択してください",
  $WEB_PROMOCODE_HEADER_DESCR: "プロモコードを入力する",
  $WEB_PROMOCODE_ACTIVATE_BTN: "有効にする",
  $NOTIF_WEB_PROMO_SUCCESS: "プロモコードを入手しました。プレイしましょう！",
  $WEB_PROMO_FAIL_NOTIF: "無効のプロモコード。再試行してください。",
  $WEB_PROMO_RECEIVED_NOTIF:
    "プロモコードを入手しました。プレイして報酬を獲得しましょう！",
  $WEB_PROMO_BAD_NOTIF: "このプロモコードのグループは有効にできません",
  $WEB_PROMOCODE_HEADER_INFO: "プロモコード",
  $WEB_EVENT_AUTHORIZATION_NOTIF: "ログインして個人プロモをチェックしましょう",
  $WEB_EVENT_ACCESS_NOTIF: "補充イベント開催中",
  $WEB_BUY_EVENT_HEADER: "このキャラクターのアカウント補充にはボーナスなし",
  $WEB_BUY_EVENT_DESCR: "入手可能：",
  $WEB_BUY_EVENT_INFO:
    "持っている一部のキャラクターに補充ボーナスが利用できます",
  $WEB_BUY_EVENT_CHOICE_BTN: "他のキャラクターを選択",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "ボーナスなしで補充する",
  $WEB_BUY_EVENT_TIMER: "プロも終了まで：",
  $WEB_AUTHMENU_INFO: "ログインして購入にアクセス",
  $WEB_STATEGOLD_HEADER1: "最大",
  $WEB_STATEGOLD_HEADER2: "多いバリューを入手",
  $WEB_SUCCESS_PAYMENT:
    "支払いが成功しました。購入情報はお手元の受信トレイに送信されました。",
  $WEB_FAILED_PAYMENT: "操作が拒否されました。",
  $WEB_NO_GOODS_IN_SECTION: "利用可能なオファーなし",
  $WEB_GOOGLEPLAY: "Google Playで利用可能",
  $WEB_APPLESTORE: "AppStoreでダウンロード",
  $WEB_EVENT_END: "イベント終了まで：",
  $WEB_EVENT_SUBTITLE1: "即時に ",
  $WEB_EVENT_SUBTITLE2: " State Coins ",
  $WEB_EVENT_SUBTITLE3: "を入手し、さらに ",
  $WEB_EVENT_SUBTITLE4: " State Coins ",
  $WEB_EVENT_SUBTITLE5: "と ",
  $WEB_EVENT_SUBTITLE6: " を追加で",
  $WEB_EVENT_SUBTITLE7: "日ログインすることで獲得する",
  $WEB_EVENT_DAY: "日",
  $WEB_PRIVACY_ID_15: "アカウントを削除",
  $WEB_PRIVACY_ID_17: "個人情報を削除",
  $WEB_PRIVACY_ID_28:
    "このページを使用して、お客様はゲームアカウントおよび関連する全ての個人情報とデータの削除を依頼することができます。",
  $WEB_PRIVACY_ID_29:
    "下の手順に従うことで、お客様はアカウントおよび関連する全ての個人情報の削除依頼を送信できます。",
  $WEB_PRIVACY_ID_30_1: "CHILLGAMING社のプライバシポリシーは、",
  $WEB_PRIVACY_ID_30_2: "リンク",
  $WEB_PRIVACY_ID_30_3:
    "先でご覧いただけます。依頼を行う前に、文書をよくお読みくださるようお願いします。",
  $WEB_PRIVACY_ID_31:
    "注意：お客様の依頼は、このサイトにログインするのに使用したアカウントと関連する全ての個人情報とデータを恒久的に削除します。これには、全ての個人情報、アプリ内とウェブサイトでの購入、ゲームの全ての進捗、および他のあらゆるゲーム関連のデータが含まれます。この操作を取り消すことはできません。",
  $WEB_PRIVACY_ID_32:
    "弊社は一部のデータを維持する可能性があることをご理解ください。例えば、下で提供された身元に関する情報が不足していることで、アカウントを特定できない場合などです。また、適用法への準拠を続けるために維持が必要、または維持する権利がある場合も、情報を維持する可能性があります。",
  $WEB_PRIVACY_ID_33:
    "弊社がお客様のアカウントを正確に特定できるようにするため、ゲームアカウントのデータを該当する欄に入力してください：ClientID、PrivacyID.。この情報を入力して依頼を送信するには、下のボタンをお使いください。",
  $WEB_PRIVACY_ID_34:
    "本当にお使いのアカウントと関連データを恒久的に削除してもよろしいですか？",
  $WEB_PRIVACY_ID_35_1: "注意:",
  $WEB_PRIVACY_ID_35_2:
    "このページ依頼を送信することで、このサイトにログインするのに使用したアカウントのゲームアカウントと関連する全ての個人情報が取り消しのできない形で恒久的に削除されます。",
  $WEB_PRIVACY_ID_36:
    "これに含まれる内容：ゲームアカウント、アカウントと関連する全ての個人情報とデータ、およびゲーム内アイテム、ゲーム通貨、および他のゲームデータを含むゲームの全ての進捗。",
  $WEB_PRIVACY_ID_37:
    "この操作は取り消し不可能です。削除プロセスが完了すると、弊社はもうどのような情報も復旧させることができません。",
  $WEB_PRIVACY_ID_38:
    "個人情報の削除を依頼してから、再び『OneState RolePlay』をプレイすることにした場合、ゲームの進捗、ゲーム内のアイテム、ゲーム通貨を取り戻すことはできません。",
  $WEB_PRIVACY_ID_39:
    "依頼の確認後、ゲームアカウントと個人情報はどのような状況においても復旧不可能となります。",
  $WEB_PRIVACY_ID_40:
    "アカウント削除を送信してから7日以内のみ、ゲームアカウントの削除をキャンセルできます。削除をキャンセルするには、ウェブサイトにログインしてメインページの上部の赤いパネル内にある「キャンセル」ボタンをクリックしてください。指定された期間の経過後、CHILLGAMINGはいかなる情報も復旧できなくなります。",
  $WEB_PRIVACY_ID_41:
    "私は、『OneState RolePlay』の私のゲームアカウントと関連する全ての個人情報とデータを削除する依頼を送信していることを認識しています。私は、CHILLGAMINGにも、私にもこの情報を復旧できなくなることを理解してい、これに同意しています。",
  $WEB_PRIVACY_ID_42_1: "Chillgamingの",
  $WEB_PRIVACY_ID_42_2: "EULA",
  $WEB_PRIVACY_ID_42_3: "と",
  $WEB_PRIVACY_ID_42_4: "プライバシポリシ",
  $WEB_PRIVACY_ID_42_5: "ーに同意します",
  $WEB_PRIVACY_ID_43: "お客様の依頼は現在処理中です。",
  $WEB_PRIVACY_ID_44_1: "お客様のゲームアカウントと関連する個人情報は、",
  $WEB_PRIVACY_ID_44_2: "日以内に自動的に削除されます。",
  $WEB_PRIVACY_ID_45:
    "依頼のキャンセルは、下の「キャンセル」ボタンを使用することで行えます。このオプションは、ウェブサイトのメインページで利用可能です。アカウントにログインして、ページ上部の赤いパネル内にある「キャンセル」ボタンをクリックしてください。アカウント削除を送信してから7日以内のみ、ゲームアカウントの削除をキャンセルできます。指定された期間の経過後、CHILLGAMINGはいかなる情報も復旧できなくなります。",
  $WEB_PRIVACY_ID_47: "ステータスを表示",
  $WEB_PRIVACY_ID_48: "キャンセル",
  $WEB_PRIVACY_POLICY: "プライバシーポリシー",
  $WEB_FORMAT_DAYS: "日",
  $WEB_FORMAT_HOURS: "時間",
  $WEB_FORMAT_MINUTES: "分",
  $WEB_INFO_TITLE: "*補充に関する情報",
  $WEB_INFO_1:
    "『One State RP』はウェブサイト（ウェブショップ）を介しての購入をサポートしています。",
  $WEB_INFO_2:
    "多くの場合、ウェブサイトでの購入はゲーム内購入よりもお得になっています。",
  $WEB_INFO_3:
    "このサイトで購入されたState Сoinsおよび他の全てのバーチャルアイテム*は、 購入時に選択したキャラクターが受け取ります（State Coinsはゲーム内でバーチャルアイテムを交換できるゲーム内通貨です）。",
  $WEB_INFO_4:
    "このサイトで購入されたゲーム内通貨とバーチャルアイテムは、支払い後すぐにゲームに出現します。これに関しては、ゲームに入った際に通知が届きます。",
  $WEB_INFO_5:
    "サーバーでの技術的作業や技術的問題が発生している場合、購入したゲーム内通貨とバーチャルアイテムは、多少の遅延後にアカウントに届く可能性があります。購入内容が届かなかった場合、ゲームからログアウトしてから再ログインしてみてください。これでも問題が解決しない場合、サポートに連絡し、購入時間、購入の種類、アカウント名をご提供ください。",
  $WEB_FOOTER_TAB_POLICY: "プライバシーポリシー",
  $WEB_FOOTER_TAB_TERMS: "利用規約",
  $WEB_FOOTER_TAB_DELETE_ACC: "アカウントを削除",
  $WEB_FOOTER_TAB_COOKIE: "クッキー設定",
  $WEB_FOOTER_TAB_SUPPORT: "サポート",
  $WEB_SOCIALS_TITLE: "ゲームをフォロー",
  $WEB_SOCIALS_US: "ゲームの",
  $WEB_SOCIALS_DESCR_1: "様々な国の複数の",
  $WEB_SOCIALS_DESCR_2:
    "アカウントがあります。適切なアカウントを選択してください。",
  $WEB_BANNER1_1: "ウェブサイトでの初購入へのギフト！",
  $WEB_BANNER1_2: "",
  $WEB_BANNER1_BONUS: "+ボーナス",
  $WEB_BANNER2_1: "さん、ギフトは既にサーバー ",
  $WEB_BANNER2_2: "のゲームに追加されています！",
};
