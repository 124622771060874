import { FC } from "react";

import { ReactComponent as LoaderLogo } from "../../assets/images/loader_logo.svg";
import styles from "./Loader.module.sass";

type Props = {
  size?: "small" | "medium";
  className?: string;
};
const Loader: FC<Props> = ({ size, className }) => {
  let sizeLoaderClass = "";
  if (size === "small") {
    sizeLoaderClass = styles.small;
  }
  return (
    <div>
      <div
        className={`${size ? sizeLoaderClass : ""} ${
          styles.loader
        } ${className}`}
      >
        <div className={styles.loaderLogo}>
          <LoaderLogo className={styles.loaderIcon} />
        </div>
      </div>
    </div>
  );
};
export default Loader;
