import { FC, ReactNode } from "react";

import { ReactComponent as AlertWarningIcon } from "../../assets/images/icons/alert_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close_icon.svg";
import Button from "../Button";
import styles from "./Alert.module.sass";
type Props = {
  children: ReactNode;
  type: "error" | "warning";
  onClose?: () => void;
  withoutIcon?: boolean;
};

export const Alert: FC<Props> = ({
  children,
  type = "warning",
  withoutIcon,
  onClose,
}) => {
  return (
    <div
      className={`${styles.wrapper} ${type === "error" ? styles.isError : ""}`}
    >
      {!withoutIcon && (
        <div className={styles.icon}>
          <AlertWarningIcon width="54px" height="54px" />
        </div>
      )}
      <div
        className={
          withoutIcon ? styles.info + " " + styles.withoutIcon : styles.info
        }
      >
        {children}
      </div>
      {onClose && (
        <Button className={styles.icon} onClick={onClose}>
          <CloseIcon width="22px" height="22px" />
        </Button>
      )}
    </div>
  );
};
