import { Link } from "react-router-dom";

import logoImg from "../../assets/images/logo_R.svg";
import logoMobImg from "../../assets/images/logo-mob.svg";
import { useRuHost } from "../../hooks/useRuHost";
import LangSwitcher from "../LangSwitcher";
import styles from "./Header.module.sass";

const Header = () => {
  const isRuHost = useRuHost();
  return (
    <header className={styles.header}>
      <div className={"container " + styles.container}>
        <div className={styles.logo}>
          <Link to="/">
            <img
              src={logoImg}
              width="100%"
              height="100%"
              className={styles.logoIcon}
              alt="logo"
            />
            <img
              src={logoMobImg}
              width="100%"
              height="100%"
              className={styles.logoIconMob}
              alt="logo mob"
            />
          </Link>
        </div>
        {!isRuHost && <LangSwitcher />}
      </div>
    </header>
  );
};

export default Header;
