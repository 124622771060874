export const de = {
  $WEB_AUTHMENU_DISCRD_BTN: "Mit Discord anmelden",
  $WEB_AUTHMENU_GOOGLE_BTN: "Mit Google Play Games anmelden",
  $WEB_AUTHMENU_VK_BTN: "Mit VKontakte anmelden",
  $WEB_AUTHMENU_FB_BTN: "Mit Facebook anmelden",
  $WEB_AUTHMENU_APPLE_BTN: "Mit Apple anmelden",
  $WEB_AUTHMENU_EMAIL_BTN: "Mit E-Mail anmelden",
  $WEB_PRIVACY_ID_12: "Mit ClientID anmelden",
  $WEB_PRIVACY_ID_14:
    "Du findest sowohl deine ClientID als auch deine PrivacyID in den Spieleinstellungen",
  $WEB_AUTHMENU_SEPARATOR: "ODER",
  $WEB_SIGNIN_PAGENAME: "Autorisierung",
  $WEB_SIGNUP_EMAIL_HOLDER: "Gib deine E-Mail-Adresse ein",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "Gib dein Passwort ein",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "An Anmeldung Erinnern",
  $WEB_SIGNUP_ENTER_BTN: "Anmelden",
  $WEB_SALE_HEADER: "DER FAVORIT",
  $WEB_SALE_HEADER_DESCR: "Bestes Set für Neulinge",
  $WEB_SALE_GET: "Du bekommst:",
  $WEB_EXIT_ACCOUNT: "Abmelden",
  $WEB_AUTHMENU_INFO: "Anmelden, um auf Käufe zuzugreifen",
  $WEB_SALE_NOTIF:
    "Um Auszahlungsprobleme zu vermeiden, melde dich vor einer Einzahlung bitte aus deinem Spielkonto ab. Vielen Dank!",
  $CONFIRM_WEB_BUY_HEADER: "Einzahlungsserver auswählen",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "Server auswählen",
  $CONFIRM_WEB_BUY_CHARACTER: "Charakter auf dem Server",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "Charakter nicht ausgewählt",
  $NOTIF_WEB_BUY_SUCCESS: "Zahlung erfolgreich!",
  $NOTIF_WEB_BUY_FAIL:
    "Irgendetwas ist schief gelaufen :( Die Zahlung ist fehlgeschlagen.",
  $WEB_BTN_OK: "GUT",
  $WEB_ERROR_HEADER: "Seite nicht gefunden :(",
  $WEB_ERROR_BACK_BTN: "Zurück zur Hauptseite",
  $WEB_PROMOCODE_BTN: "PROMO-CODES",
  $WEB_PROMOCODE_HEADER: "Code eingeben und Server wählen",
  $WEB_PROMOCODE_HEADER_DESCR: "Promo-Code eingeben",
  $WEB_PROMOCODE_ACTIVATE_BTN: "Aktivieren",
  $NOTIF_WEB_PROMO_SUCCESS: "Du hast einen Promo-Code, komm und schau rein!",
  $WEB_PROMO_FAIL_NOTIF: "Ungültiger Promo-Code, bitte versuche es erneut",
  $WEB_PROMO_RECEIVED_NOTIF:
    "Du hast einen Promo-Code. Komm und erhalte deine Belohnung!",
  $WEB_PROMO_BAD_NOTIF:
    "Diese Gruppe von Promo-Codes kann nicht aktiviert werden.",
  $WEB_PROMOCODE_HEADER_INFO: "Promo-Code",
  $WEB_EVENT_AUTHORIZATION_NOTIF: "Anmelden und nach Promos schauen",
  $WEB_EVENT_ACCESS_NOTIF: "Auflade-Event jetzt verfügbar",
  $WEB_BUY_EVENT_HEADER:
    "Keine Boni für das Aufladen des Kontos dieses Charakters",
  $WEB_BUY_EVENT_DESCR: "Du erhältst: ",
  $WEB_BUY_EVENT_INFO:
    "Auflade-Boni sind für einige deiner Charaktere verfügbar",
  $WEB_BUY_EVENT_CHOICE_BTN: "Anderen Charakter wählen",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "Ohne Bonus aufladen",
  $WEB_BUY_EVENT_TIMER: "Promo endet in:",
  $WEB_SUCCESS_PAYMENT:
    "Payment successful. Purchase information has been sent to your inbox.",
  $WEB_FAILED_PAYMENT: "Operation rejected.",
  $WEB_NO_GOODS_IN_SECTION: "Keine Angebote verfügbar",
  $WEB_GOOGLEPLAY: "Verfügbar im Google Play",
  $WEB_APPLESTORE: "Herunterladen im App Store",
  $WEB_EVENT_END: "Event endet in: ",
  $WEB_STATEGOLD_HEADER1: "ERHALTE BIS ZU",
  $WEB_STATEGOLD_HEADER2: "MEHR WERT",
  $WEB_EVENT_SUBTITLE1: "Hol dir jetzt ",
  $WEB_EVENT_SUBTITLE2: " State-Münzen ",
  $WEB_EVENT_SUBTITLE3: "zusammen mit ",
  $WEB_EVENT_SUBTITLE4: " State-Münzen ",
  $WEB_EVENT_SUBTITLE5: "und ",
  $WEB_EVENT_SUBTITLE6: " zu erhalten, nachdem du dich",
  $WEB_EVENT_SUBTITLE7: "zusätzliche Tage am Stück angemeldet hast.",
  $WEB_EVENT_DAY: "Tag:",
  $WEB_PRIVACY_ID_15: "Konto Löschen",
  $WEB_PRIVACY_ID_17: "MEINE PERSÖNLICHEN DATEN LÖSCHEN",
  $WEB_PRIVACY_ID_28:
    "Auf dieser Seite kannst du die Löschung deines Spielkontos und aller damit verbundenen persönlichen Informationen und Daten beantragen.",
  $WEB_PRIVACY_ID_29:
    "Du kannst einen Antrag auf Löschung deines Kontos und aller damit verbundenen persönlichen Daten stellen, indem du die folgenden Anweisungen befolgst.",
  $WEB_PRIVACY_ID_30_1:
    "Die Datenschutzbestimmungen der Firma CHILLGAMING sind ",
  $WEB_PRIVACY_ID_30_2: "über den Link",
  $WEB_PRIVACY_ID_30_3:
    " verfügbar. Bitte lies das Dokument durch, bevor du eine Anfrage stellst.",
  $WEB_PRIVACY_ID_31:
    "Bitte beachte: Durch deine Anfrage werden alle persönlichen Daten und Informationen, die mit dem/den Konto/Konten verbunden sind, mit denen du dich auf dieser Seite angemeldet hast, dauerhaft gelöscht. Dies beinhaltet die Löschung aller persönlichen Daten, In-App- und Website-Käufe, aller Spielfortschritte und aller anderen spielbezogenen Daten. Diese Aktion kann nicht rückgängig gemacht werden.",
  $WEB_PRIVACY_ID_32:
    "Bitte beachte, dass wir einige Daten aufbewahren können. Zum Beispiel, wenn wir dein Konto nicht identifizieren können, weil die unten angegebenen Identifikationsdaten fehlen. Wir können auch Daten aufbewahren, zu deren Aufbewahrung wir gemäß den geltenden Gesetzen verpflichtet sind oder das Recht haben.",
  $WEB_PRIVACY_ID_33:
    "Damit wir dein Konto genau identifizieren können, gib bitte deine Spielkontodaten in die entsprechenden Felder ein: ClientID, PrivacyID. Um diese Informationen einzugeben und einen Antrag auf Löschung deines Kontos zu stellen, benutze die Schaltfläche unten.",
  $WEB_PRIVACY_ID_34:
    "BIST DU SICHER, DASS DU DEIN KONTO UND DIE DAMIT VERBUNDENEN DATEN DAUERHAFT LÖSCHEN WILLST?",
  $WEB_PRIVACY_ID_35_1: "ACHTUNG:",
  $WEB_PRIVACY_ID_35_2:
    "Wenn du auf dieser Seite eine Anfrage stellst, werden dein Spielkonto und alle persönlichen Daten, die mit dem/den Konto/Konten verbunden sind, mit denen du dich auf dieser Seite eingeloggt hast, dauerhaft und unwiderruflich gelöscht.",
  $WEB_PRIVACY_ID_36:
    "DAZU GEHÖREN: das Spielkonto, alle persönlichen Daten und Informationen, die mit dem/den Konto/Konten verbunden sind, sowie alle Spielfortschritte, einschließlich der Gegenstände im Spiel, der virtuellen Währung und anderer Spieldaten.",
  $WEB_PRIVACY_ID_37:
    "DIESE AKTION IST UNUMKEHRBAR. SOBALD DER LÖSCHVORGANG ABGESCHLOSSEN IST, KÖNNEN WIR DIE INFORMATIONEN NICHT MEHR WIEDERHERSTELLEN.",
  $WEB_PRIVACY_ID_38:
    "Wenn du die Löschung deiner persönlichen Daten beantragst und dich entscheidest, OneState RolePlay erneut zu spielen, kannst du deinen Spielfortschritt, deine Spielgegenstände und deine virtuelle Währung nicht wiederherstellen.",
  $WEB_PRIVACY_ID_39:
    "NACH DER BESTÄTIGUNG DER ANFRAGE KÖNNEN DAS SPIELKONTO UND DIE PERSÖNLICHEN DATEN UNTER KEINEN UMSTÄNDEN WIEDERHERGESTELLT WERDEN.",
  $WEB_PRIVACY_ID_40: `Du kannst die Löschung deines Spielkontos nur innerhalb von 7 Tagen nach Einreichen des Löschungsantrags rückgängig machen. Um die Löschung rückgängig zu machen, logge dich auf der Website ein und klicke auf die Schaltfläche "Abbrechen", die sich im roten Feld oben auf der Hauptseite befindet. Nach Ablauf dieser Frist kann CHILLGAMING die Daten nicht mehr wiederherstellen.`,
  $WEB_PRIVACY_ID_41:
    "Ich bin mir bewusst, dass ich einen Antrag auf Löschung meines Spielkontos im OneState RolePlay und aller damit verbundenen persönlichen Informationen und Daten stelle. ICH VERSTEHE UND STIMME ZU, DASS WEDER CHILLGAMING NOCH ICH IN DER LAGE SEIN WERDEN, DIESE INFORMATIONEN WIEDERHERZUSTELLEN.",
  $WEB_PRIVACY_ID_42_1: "Ich akzeptiere die ",
  $WEB_PRIVACY_ID_42_2: "EULA",
  $WEB_PRIVACY_ID_42_3: " und die ",
  $WEB_PRIVACY_ID_42_4: "Datenschutzbestimmungen",
  $WEB_PRIVACY_ID_42_5: " von Chillgaming",
  $WEB_PRIVACY_ID_43: "Deine Anfrage wird bearbeitet.",
  $WEB_PRIVACY_ID_44_1:
    "Dein Spielkonto und die damit verbundenen persönlichen Daten werden innerhalb von ",
  $WEB_PRIVACY_ID_44_2: " Tagen automatisch gelöscht.",
  $WEB_PRIVACY_ID_45: `Wenn du die Anfrage abbrechen möchtest, kannst du die Schaltfläche "Abbrechen" unten verwenden. Diese Option ist auch auf der Hauptseite der Website verfügbar. Logge dich in dein Konto ein und klicke auf die Schaltfläche "Abbrechen" im roten Feld oben auf der Seite. Du kannst die Kontolöschung nur innerhalb von 7 Tagen nach dem Absenden des Antrags stornieren. Nach Ablauf dieser Frist ist CHILLGAMING NICHT mehr in der Lage, die Daten wiederherzustellen.`,
  $WEB_PRIVACY_ID_47: "Status ansehen",
  $WEB_PRIVACY_ID_48: "Abbrechen",
  $WEB_PRIVACY_POLICY: "Datenschutzrichtlinie",
  $WEB_FORMAT_DAYS: "d",
  $WEB_FORMAT_HOURS: "h",
  $WEB_FORMAT_MINUTES: "min",
  $WEB_INFO_TITLE: "Informationen zum Aufladen",
  $WEB_INFO_1:
    "Das Spiel One State RP unterstützt Einkäufe über eine Website (Web-Shop).",
  $WEB_INFO_2:
    "In den meisten Fällen sind Einkäufe auf der Website rentabler als Einkäufe im Spiel.",
  $WEB_INFO_3:
    "State-Münzen und alle virtuellen Gegenstände*, die auf dieser Website gekauft werden, erhält der Charakter, den du beim Kauf ausgewählt hast. (State-Münzen sind eine Spielwährung, die im Spiel gegen virtuelle Gegenstände eingetauscht werden kann).",
  $WEB_INFO_4:
    "Die Spielwährung und die auf der Website gekauften virtuellen Gegenstände werden sofort nach der Zahlung in deinem Spielkonto angezeigt. Du erhältst eine Benachrichtigung, sobald du das Spiel startest.",
  $WEB_INFO_5:
    "Bei technischen Arbeiten oder Problemen auf dem Server kann es zu einer verzögerten Gutschrift der gekauften Spielwährung und virtuellen Gegenstände auf deinem Spielkonto kommen. Wenn du deinen Kauf nicht erhältst, versuche, dich auszuloggen und dann wieder ins Spiel einzuloggen. Wenn dies nicht hilft, wende dich bitte an den Support und gib den Zeitpunkt der Transaktion, die Art des Kaufs und den Kontonamen an.",
  $WEB_FOOTER_TAB_POLICY: "Datenschutzerklärung",
  $WEB_FOOTER_TAB_TERMS: "Nutzungsbedingungen",
  $WEB_FOOTER_TAB_DELETE_ACC: "Konto löschen",
  $WEB_FOOTER_TAB_COOKIE: "Cookie-Einstellungen",
  $WEB_FOOTER_TAB_SUPPORT: "Hilfe",
  $WEB_SOCIALS_TITLE: "Abonniere uns:",
  $WEB_SOCIALS_US: "Unser ",
  $WEB_SOCIALS_DESCR_1: "Wir haben mehrere ",
  $WEB_SOCIALS_DESCR_2:
    "-Konten für verschiedene Länder. Bitte wähle das passende aus.",
  $WEB_BANNER1_1: "Geschenke für deinen ersten",
  $WEB_BANNER1_2: "Einkauf auf der Website!",
  $WEB_BANNER1_BONUS: "+ bonus",
  $WEB_BANNER2_1: "dein Geschenk ist bereits im Spiel auf dem Server – ",
  $WEB_BANNER2_2: "!",
};
