import { ReactNode } from "react";

import { ReactComponent as MasterCardIcon } from "../../assets/images/icons/mastercard.svg";
import { ReactComponent as MirIcon } from "../../assets/images/icons/mir.svg";
import { ReactComponent as SbpIcon } from "../../assets/images/icons/sbp.svg";
import { ReactComponent as VisaIcon } from "../../assets/images/icons/visa.svg";
import Button from "../Button";
import styles from "./RuPayment.module.sass";
interface Props {
  currencyIcon: ReactNode;
  disabled: boolean;
  price: string;
  paymentSubmit: (type: "sbp" | "card") => void;
}
export default function RuPayment({
  currencyIcon,
  disabled,
  price,
  paymentSubmit,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.priceWrapper}>
        <p className={styles.price}>
          {currencyIcon} {price}
        </p>
      </div>
      <div className={styles.label}>Выбери способ оплаты</div>
      <div className={styles.btns}>
        <Button
          className={styles.btn}
          disabled={disabled}
          onClick={() => paymentSubmit("sbp")}
          type="button"
        >
          <SbpIcon />
        </Button>
        <Button
          className={styles.btn}
          disabled={disabled}
          onClick={() => paymentSubmit("card")}
          type="button"
        >
          <MirIcon />
          <VisaIcon />
          <MasterCardIcon />
        </Button>
      </div>
    </div>
  );
}
