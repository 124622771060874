export const pt = {
  $WEB_AUTHMENU_DISCRD_BTN: "Entrar com Discord",
  $WEB_AUTHMENU_GOOGLE_BTN: "Entrar com Google Play",
  $WEB_AUTHMENU_VK_BTN: "Entrar com VKontakte",
  $WEB_AUTHMENU_FB_BTN: "Entrar com Facebook",
  $WEB_AUTHMENU_APPLE_BTN: "Entrar com Apple",
  $WEB_AUTHMENU_EMAIL_BTN: "Entrar com E-mail",
  $WEB_PRIVACY_ID_12: "Entrar com ClientID",
  $WEB_PRIVACY_ID_14:
    "Você pode encontrar seu ClientID e PrivacyID nas configurações de jogo.",
  $WEB_AUTHMENU_SEPARATOR: "OU",
  $WEB_SIGNIN_PAGENAME: "Autorização",
  $WEB_SIGNUP_EMAIL_HOLDER: "Insira seu e-mail",
  $WEB_SIGNUP_CONFPASSWORD_HOLDER: "Insira sua senha",
  $WEB_SIGNUP_REMEMBER_CHECKBOX: "Lembrar-me",
  $WEB_SIGNUP_ENTER_BTN: "Entrar",
  $WEB_SALE_HEADER: "FAVORITO DO JOGADOR",
  $WEB_SALE_HEADER_DESCR: "O melhor para jogadores novos",
  $WEB_SALE_GET: "Você receberá:",
  $WEB_EXIT_ACCOUNT: "Sair",
  $WEB_AUTHMENU_INFO: "Faça login para acessar as compras",
  $WEB_SALE_NOTIF:
    "Para evitar problemas ao receber compras, saia com sua conta no jogo antes de fazer algum depósito. Valeu!",
  $CONFIRM_WEB_BUY_HEADER: "Selecionar servidor de depósito",
  $CONFIRM_WEB_BUY_HEADER_DESCR: "Selecionar servidor",
  $CONFIRM_WEB_BUY_CHARACTER: "Personagem no servidor",
  $CONFIRM_WEB_BUY_CHARACTER_DESCR: "Personagem não selecionado",
  $NOTIF_WEB_BUY_SUCCESS: "Pagamento realizado!",
  $NOTIF_WEB_BUY_FAIL: "Algo deu errado :( Falha no pagamento.",
  $WEB_BTN_OK: "OK",
  $WEB_ERROR_HEADER: "Página não encontrada :(",
  $WEB_ERROR_BACK_BTN: "Voltar ao início",
  $WEB_PROMOCODE_BTN: "CÓD. PROMOCIONAIS",
  $WEB_PROMOCODE_HEADER: "Insira o cód. promocional e selecione o servidor",
  $WEB_PROMOCODE_HEADER_DESCR: "Insira cód. promocional",
  $WEB_PROMOCODE_ACTIVATE_BTN: "Ativar",
  $NOTIF_WEB_PROMO_SUCCESS: "Você recebeu um código promocional, venha jogar!",
  $WEB_PROMO_FAIL_NOTIF: "Código promocional inválido",
  $WEB_PROMO_RECEIVED_NOTIF:
    "Você recebeu um código promocional. Venha jogar e receba sua recompensa!",
  $WEB_PROMO_BAD_NOTIF:
    "Este grupo de códigos promocionais não pode ser ativado.",
  $WEB_PROMOCODE_HEADER_INFO: "Código promocional",

  $WEB_EVENT_AUTHORIZATION_NOTIF: "Faça login e confira promoções pessoais",
  $WEB_EVENT_ACCESS_NOTIF: "Evento de recarga disponível agora",
  $WEB_BUY_EVENT_HEADER: "Nenhum bônus para recarregar a conta do personagem",
  $WEB_BUY_EVENT_DESCR: "Você receberá:",
  $WEB_BUY_EVENT_INFO:
    "Bônus de recargas disponíveis para alguns dos seus personagens",
  $WEB_BUY_EVENT_CHOICE_BTN: "Selecione outros personagens",
  $WEB_BUY_EVENT_DEPOSIT_BTN: "Recarga sem bônus",
  $WEB_BUY_EVENT_TIMER: "A promoção termina em:",
  $WEB_SUCCESS_PAYMENT:
    "Payment successful. Purchase information has been sent to your inbox.",
  $WEB_FAILED_PAYMENT: "Operation rejected.",
  $WEB_NO_GOODS_IN_SECTION: "Sem ofertas disponíveis",
  $WEB_GOOGLEPLAY: "Disponível no Google Play",
  $WEB_APPLESTORE: "Baixar na App Store",
  $WEB_EVENT_END: "O evento termina em:",
  $WEB_STATEGOLD_HEADER1: "OBTENHA ATÉ",
  $WEB_STATEGOLD_HEADER2: "MAIS VALOR",
  $WEB_EVENT_SUBTITLE1: "Obtenha ",
  $WEB_EVENT_SUBTITLE2: " State Coins ",
  $WEB_EVENT_SUBTITLE3: "agora mesmo, além de ",
  $WEB_EVENT_SUBTITLE4: " State Coins ",
  $WEB_EVENT_SUBTITLE5: "e ",
  $WEB_EVENT_SUBTITLE6: " depois de jogar mais",
  $WEB_EVENT_SUBTITLE7: "dias+",
  $WEB_EVENT_DAY: "Dia:",
  $WEB_PRIVACY_ID_15: "Excluir Conta",
  $WEB_PRIVACY_ID_17: "EXCLUIR MINHAS INFORMAÇÕES PESSOAIS",
  $WEB_PRIVACY_ID_28:
    "Utilizando esta página você pode solicitar a exclusão da sua conta de jogo e todas as informações e dados pessoais.",
  $WEB_PRIVACY_ID_29:
    "Você pode enviar a solicitação para exclusão de sua conta e todas as informações pessoais presentes nela seguindo as informações abaixo.",
  $WEB_PRIVACY_ID_30_1:
    "A política de privacidade da empresa CHILLGAMING está disponível ",
  $WEB_PRIVACY_ID_30_2: "pelo link",
  $WEB_PRIVACY_ID_30_3: ". Revise o documento antes de perguntar.",
  $WEB_PRIVACY_ID_31:
    "Observação: Sua solicitação excluirá permanentemente todas os dados pessoais e informações associadas à conta que você utilizava para entrar no site. Isso inclui a exclusão de dados pessoais, compras no aplicativo e da página do jogo, todo progresso de jogo e quaisquer outras informações relacionadas ao jogo. Essa ação não pode ser desfeita.",
  $WEB_PRIVACY_ID_32:
    "Esteja ciente que podemos manter algumas informações. Por exemplo, caso não seja possível identificar sua conta devido à falta de informações de identificação fornecidos abaixo. Também poderemos manter informações que nos são exigidas ou temos o direito de manter conforme a legislação.",
  $WEB_PRIVACY_ID_33:
    "Para que possamos identificar sua conta corretamente, insira os dados de sua conta do jogo nos campos respectivos: ClientID, PrivacyID. Para inserir as informações e enviar a solicitação de exclusão da conta, utilize o botão abaixo.",
  $WEB_PRIVACY_ID_34:
    "VOCÊ TEM CERTEZA DE QUE QUER EXCLUIR PERMANENTEMENTE SUA CONTA E DADOS ASSOCIADOS?",
  $WEB_PRIVACY_ID_35_1: "ATENÇÃO:",
  $WEB_PRIVACY_ID_35_2:
    "ao enviar a solicitação nesta página, sua conta do jogo e todas as informações pessoais associadas com a(s) conta(s) utilizada(s) para acessar o site serão excluídas de modo irreversível e permanente.",
  $WEB_PRIVACY_ID_36:
    "ISSO INCLUI: conta do jogo, todos dados pessoais e informações associadas com a(s) conta(s), todo progresso do jogo, itens do jogo, moeda virtual e outras informações de jogo.",
  $WEB_PRIVACY_ID_37:
    "ESSA AÇÃO É IRREVERSÍVEL. QUANDO A EXCLUSÃO FOR CONCLUÍDA, NÃO SERÁ POSSÍVEL RECUPERAR QUALQUER INFORMAÇÃO.",
  $WEB_PRIVACY_ID_38:
    "Se você solicitar a exclusão das informações pessoais e decidir jogar OneState no modo RolePlay novamente, não será possível recuperar seu progresso no jogo, itens do jogo e moeda virtual.",
  $WEB_PRIVACY_ID_39:
    "APÓS A CONFIRMAÇÃO DA SOLICITAÇÃO, NÃO SERÁ POSSÍVEL RECUPERAR A CONTA DO JOGO E AS INFORMAÇÕES DE MODO ALGUM.",
  $WEB_PRIVACY_ID_40: `Você pode cancelar a exclusão da conta do jogo em até 7 dias após o envio desta solicitação. Para cancelar a exclusão, acesse a página e clique no botão "Cancelar", localizado no painel vermelho no topo da página inicial. Após o período especificado, a CHILLGAMING não poderá recuperar qualquer informação.`,
  $WEB_PRIVACY_ID_41:
    "Estou ciente de que estou enviando uma solicitação de exclusão da minha conta do jogo OneState RolePlay e todas as informações e dados pessoais associados a ela. EU ENTENDO E ACEITO QUE NEM A CHILLGAMING NEM EU PODEREMOS RECUPERAR AS INFORMAÇÕES.",
  $WEB_PRIVACY_ID_42_1: "Aceito a ",
  $WEB_PRIVACY_ID_42_2: "EULA",
  $WEB_PRIVACY_ID_42_3: " e a ",
  $WEB_PRIVACY_ID_42_4: "Política de Privacidade",
  $WEB_PRIVACY_ID_42_5: " da ChillGaming.",
  $WEB_PRIVACY_ID_43: "Sua solicitação está em processamento.",
  $WEB_PRIVACY_ID_44_1:
    "Sua conta do jogo e informações pessoais serão excluídas automaticamente em ",
  $WEB_PRIVACY_ID_44_2: " dias.",
  $WEB_PRIVACY_ID_45: `Se você quiser solicitar o cancelamento desta solicitação, você pode utilizar o botão "Cancelar" abaixo. Esta opção também está disponível na página inicial do jogo. Acesse sua conta e clique no botão "Cancelar" localizado no painel vermelho no topo da página. Você pode cancelar a exclusão da conta em até 7 dias após o envio da solicitação. Após o período especificado, A CHILLGAMING NÃO PODERÁ RECUPERAR QUAISQUER INFORMAÇÕES.`,
  $WEB_PRIVACY_I_46:
    "Sua conta do jogo e informações pessoais associadas serão automaticamente excluídas em 7 dias.",
  $WEB_PRIVACY_ID_47: "Ver status",
  $WEB_PRIVACY_ID_48: "Cancelar",
  $WEB_PRIVACY_POLICY: "Política de Privacidade",
  $WEB_FORMAT_DAYS: "d",
  $WEB_FORMAT_HOURS: "h",
  $WEB_FORMAT_MINUTES: "min",
  $WEB_INFO_TITLE: "Informações sobre as recargas",
  $WEB_INFO_1:
    "O One State RP é compatível com compras através do site (Web-Shop).",
  $WEB_INFO_2: "Muitas vezes, comprar no site é mais lucrativo do que no jogo.",
  $WEB_INFO_3:
    "As State Сoins e todos os itens* virtuais adquiridos serão enviados para o personagem selecionado durante a compra. (State Сoins são uma moeda do jogo que pode ser trocada por itens virtuais).",
  $WEB_INFO_4:
    "A moeda do jogo e os itens virtuais adquiridos no site serão exibidos na sua conta após o pagamento. Você receberá uma notificação ao entrar no jogo.",
  $WEB_INFO_5:
    "Em caso de manutenção ou problemas técnicos no servidor, a moeda do jogo e os itens virtuais adquiridos poderão demorar um pouco para serem enviados para sua conta. Se você não receber a compra, tente sair com a conta e entrar novamente com ela no jogo. Se isso não ajudar, entre em contato com o suporte e informe a hora da transação, o tipo de compra e o nome da conta.",
  $WEB_FOOTER_TAB_POLICY: "Política de Privacidade",
  $WEB_FOOTER_TAB_TERMS: "Termos de Serviço",
  $WEB_FOOTER_TAB_DELETE_ACC: "Excluir conta",
  $WEB_FOOTER_TAB_COOKIE: "Configurações de Cookies",
  $WEB_FOOTER_TAB_SUPPORT: "Suporte",
  $WEB_SOCIALS_TITLE: "Inscreva-se:",
  $WEB_SOCIALS_US: "Nosso ",
  $WEB_SOCIALS_DESCR_1: "Temos várias contas no ",
  $WEB_SOCIALS_DESCR_2: " para diversos países. Escolhe a conta apropriada.",
  $WEB_BANNER1_1: "Presentes pela sua",
  $WEB_BANNER1_2: "primeira compra no site!",
  $WEB_BANNER1_BONUS: "+ bônus",
  $WEB_BANNER2_1: "seu presente já está no jogo, no servidor ",
  $WEB_BANNER2_2: "!",
};
