import { FC, useCallback, useEffect, useMemo } from "react";

import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useGetOffersQuery } from "../../services/api/apiOfferSlice";
import {
  useGetHotOfferQuery,
  useGetHotOfferRewardsQuery,
  useGetPacksQuery,
} from "../../services/api/apiProductsSlice";
import { OfferRewardsData } from "../../types/products";
import i18n from "../../utils/i18n";
import HotOffer from "../HotOffer";
import Loader from "../Loader";
import ProductsList from "../ProductsList";
import styles from "./ProductsBlock.module.sass";

type Props = {
  isAuth: boolean;
};

const ProductsBlock: FC<Props> = ({ isAuth }) => {
  const { translate } = useTranslate();
  const { productType } = useTypedSelector((state) => state.products);
  const { player: selectedPlayer } = useTypedSelector((state) => state.player);

  const { data: packsData, isLoading: isLoadingPacks } = useGetPacksQuery({
    language: i18n.language,
  });

  const { data: hotOfferData, isLoading: isLoadingHotOffer } =
    useGetHotOfferQuery({
      language: i18n.language,
    });

  const { data: dataOfferRewards, refetch } = useGetHotOfferRewardsQuery(
    { language: i18n.language },
    {
      skip: !isAuth,
    },
  );

  useEffect(() => {
    if (isAuth) {
      refetch();
    }
  }, [isAuth, refetch]);

  const { data: dataOffers, refetch: refetchOfferData } = useGetOffersQuery(
    "",
    {
      skip: !isAuth,
    },
  );

  useEffect(() => {
    if (isAuth) {
      refetchOfferData();
    }
  }, [isAuth, refetchOfferData]);

  const { items: activeOffers } = dataOffers?.data || {};
  const packs = packsData?.data || [];
  const hotOffer = isAuth ? hotOfferData?.data : null;

  const getWelcomeOfferOfProduct = useCallback(
    (productId: string | undefined) => {
      return activeOffers
        ? activeOffers.find(
            (activeOffer) =>
              activeOffer.server_id === selectedPlayer?.server_id &&
              activeOffer.data.packs
                ?.filter((packItem) => packItem.available > 0)
                .some((packItem) => packItem.id === productId),
          )
        : null;
    },
    [activeOffers, selectedPlayer],
  );

  const currentOfferRewards = useMemo(() => {
    if (Array.isArray(dataOfferRewards)) {
      return dataOfferRewards?.find((itemReward: OfferRewardsData) => {
        if (selectedPlayer) {
          return (
            selectedPlayer?.server_id === itemReward?.server_id &&
            itemReward?.data.status
          );
        } else {
          return itemReward?.data.status;
        }
      });
    } else {
      return null;
    }
  }, [dataOfferRewards, selectedPlayer]);

  //TODO: ОПТИМИЗИРОВАТЬ ЭТОТ КОСТЫЛЬ!

  return (
    <section className={styles.wrapper}>
      {isLoadingPacks || isLoadingHotOffer ? (
        <Loader />
      ) : (
        <>
          {(productType === "event" || productType === "all") &&
            hotOffer &&
            Object.keys(hotOffer).length > 0 &&
            (selectedPlayer && !currentOfferRewards ? (
              productType === "event" && (
                <div>
                  <center>{translate("$WEB_NO_GOODS_IN_SECTION")}</center>
                </div>
              )
            ) : !selectedPlayer && productType === "event" ? (
              <div>
                <center>{translate("$CONFIRM_WEB_BUY_HEADER_DESCR")}</center>
              </div>
            ) : !selectedPlayer && productType !== "event" ? (
              ""
            ) : (
              <HotOffer hotOffer={hotOffer} rewards={currentOfferRewards} />
            ))}
          {(productType === "coins" || productType === "all") && (
            <ProductsList
              products={packs}
              getWelcomeOfferOfProduct={getWelcomeOfferOfProduct}
            />
          )}
          {/* 
         // * временно пока не появятся паки 
          */}
          {productType === "packs" && (
            <div>
              <center>{translate("$WEB_NO_GOODS_IN_SECTION")}</center>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default ProductsBlock;
