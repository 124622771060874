import appStoreImgRU from "../assets/images/icons/appstore.svg";
import appStoreImgAR from "../assets/images/icons/appstore-ar.svg";
import appStoreImgBR from "../assets/images/icons/appstore-br.svg";
import appStoreImgDE from "../assets/images/icons/appstore-de.svg";
import appStoreImgEN from "../assets/images/icons/appstore-en.svg";
import appStoreImgES from "../assets/images/icons/appstore-es.svg";
import appStoreImgFR from "../assets/images/icons/appstore-fr.svg";
import appStoreImgIT from "../assets/images/icons/appstore-it.svg";
import appStoreImgJP from "../assets/images/icons/appstore-jp.svg";
import googleStoreImgRU from "../assets/images/icons/google-store.svg";
import googleStoreImgAR from "../assets/images/icons/google-store-ar.svg";
import googleStoreImgBR from "../assets/images/icons/google-store-br.svg";
import googleStoreImgDE from "../assets/images/icons/google-store-de.svg";
import googleStoreImgEN from "../assets/images/icons/google-store-en.svg";
import googleStoreImgES from "../assets/images/icons/google-store-es.svg";
import googleStoreImgFR from "../assets/images/icons/google-store-fr.svg";
import googleStoreImgIT from "../assets/images/icons/google-store-it.svg";
import googleStoreImgJP from "../assets/images/icons/google-store-jp.svg";

export const storeImgs: {
  appStore: Record<string, string>;
  googleStore: Record<string, string>;
} = {
  appStore: {
    br: appStoreImgBR,
    ru: appStoreImgRU,
    de: appStoreImgDE,
    fr: appStoreImgFR,
    jp: appStoreImgJP,
    es: appStoreImgES,
    it: appStoreImgIT,
    en: appStoreImgEN,
    ar: appStoreImgAR,
  },
  googleStore: {
    br: googleStoreImgBR,
    ru: googleStoreImgRU,
    de: googleStoreImgDE,
    fr: googleStoreImgFR,
    jp: googleStoreImgJP,
    es: googleStoreImgES,
    it: googleStoreImgIT,
    en: googleStoreImgEN,
    ar: googleStoreImgAR,
  },
};
