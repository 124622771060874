import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { useRuHost } from "../hooks/useRuHost";
import { resources } from "./lang";
const isRuHost = useRuHost();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: isRuHost ? "ru" : "en",
    supportedLngs: isRuHost
      ? ["ru"]
      : ["ru", "en", "pt", "de", "fr", "jp", "es", "it", "ar"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
