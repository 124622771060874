import { useMemo } from "react";

import FirstPurchaseBannerAfterImg from "../../assets/images/first-purchase-banner-after.jpg";
import { ReactComponent as OkIcon } from "../../assets/images/icons/ok_icon.svg";
import { useTranslate } from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import styles from "./BannerFirstBuyAfter.module.sass";

const BannerFirstBuyAfter = () => {
  const { player } = useTypedSelector((state) => state.player);
  const { translate, i18n } = useTranslate();
  const isArLang = useMemo(() => i18n.language === "ar", [i18n.language]);

  return (
    <div className={styles.wrapper}>
      <img
        src={FirstPurchaseBannerAfterImg}
        alt="banner after"
        className={styles.imageMain}
      />
      <div className={styles.content}>
        <OkIcon className={styles.icon} />
        <div className={isArLang ? styles.textWrapWithArab : ""}>
          <p className={styles.name}>
            {player?.nickname}
            <span className={isArLang ? styles.dNoneWithArab : ""}>,</span>
          </p>
          <p className={styles.text}>
            {translate("$WEB_BANNER2_1")}
            <span className={styles.server}>{player?.server_name}</span>
            {translate("$WEB_BANNER2_2")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BannerFirstBuyAfter;
