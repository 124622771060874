import { FC } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CloseIcon } from "../../assets/images/icons/x-icon.svg";
import { ModalFullScreenTemplateProps } from "../../types/modals";
import Button from "../Button";
import styles from "./ModalFullScreenTemplate.module.sass";

Modal.setAppElement("#wrapper");

export const ModalFullScreenTemplate: FC<ModalFullScreenTemplateProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();

  const goBackHandler = () => {
    onClose ? onClose() : navigate(`../`);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={goBackHandler}
      className={styles.modal}
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "888" },
      }}
    >
      <div className={styles.wrapper}>
        <Button className={styles.btn} onClick={goBackHandler}>
          <CloseIcon width={28} height={28} />
        </Button>
        {children}
      </div>
    </Modal>
  );
};
